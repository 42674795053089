import { InfoOutlined } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Snackbar,
  Table,
  Typography,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropZone from "../DropZone/DropZone";
import { useTranslation } from "react-i18next";

const CreateCarAssetForm = ({ formData, formDataErrors, handleOnChange }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const JWT = process.env.REACT_APP_PINATA_TOKEN;

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );

  const [files, setFiles] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [shareholderModal, setShareholderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromUserData, setFromUserData] = React.useState();
  const [currency, setCurrency] = React.useState("nationalId");
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);

  useEffect(() => {
    handleOnChange("shareholders", shareholders);
  }, [shareholders]);

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialShareholderFormData = {
    user: {},
    totalShares: "",
  };

  const [shareholderFormData, setShareholderFormData] = useState({
    ...initialShareholderFormData,
  });

  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  // HANDLE UPDATE NEW IMAGE VIA DROP ZONE
  const handleChangeDropZone = (files) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles((prevState) => [...prevState.concat(files)]);

    handleOnChange("files", files);
  };

  const addShareholder = () => {
    setShareholderModal(false);
    console.log(shareholderFormData);
    if (
      shareholders.find(
        (e) => e.shareholderWallet === shareholderFormData?.user?.userWallet
      )
    ) {
      setOpenSnackbarWarning(true);
    } else {
      setShareholders((oldArray) => [
        ...oldArray,
        {
          shareholderFirstname: shareholderFormData?.user?.firstName,
          shareholderLastname: shareholderFormData?.user?.lastName,
          shareholderWallet: shareholderFormData?.user?.userWallet,
          userHash: shareholderFormData?.user?.userHash,
          totalShares: shareholderFormData?.totalShares,
        },
      ]);
      setOpenSnackbarSuccess(true);
    }
    setShareholderFormData(initialShareholderFormData);
    setFromUserData();
    setSearchFormData(initialSearchFormData);
  };
  const handleOnShareholderChange = (name, value) => {
    console.log(shareholderFormData);

    setShareholderFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const search = (e) => {
    e.preventDefault();
    setFromUserData();
    userContract
      .call("searchUser", [
        searchFormData.nationalId,
        searchFormData.passportId,
        searchFormData.civilRegistryDocumentId,
      ])
      .then((result) => {
        if (
          result.data[1] ==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          setFromUserData("error");
        } else {
          let objs = [result.data].map((item, index) => ({
            id: index,
            firstName: item[0][0],
            lastName: item[0][1],
            fathersName: item[0][2],
            mothersName: item[0][3],
            dateOfBirth: item[0][4],
            placeOfBirth: item[0][5],
            gender: item[0][6],
            nationality: item[0][7],
            registryPlace: item[0][8],
            number: item[0][9],
            files: item[0][10],
            userHash: item[0],
            nationalId: item[2],
            passportId: item[3],
            civilRegistryDocumentId: item[4],
            userWallet: item[5],
          }));
          handleOnShareholderChange("user", objs[0]);
          setFromUserData(objs[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnSearchChange = (name, value) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.registrationNumber}
        >
          <FormLabel>Registration Number</FormLabel>
          <Input
            placeholder="Registration Number"
            onChange={(event) =>
              handleOnChange("registrationNumber", event.target.value)
            }
          />
          {formDataErrors.registrationNumber && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.engineNumber}
        >
          <FormLabel>Engine Number</FormLabel>
          <Input
            placeholder="Engine Number"
            onChange={(event) =>
              handleOnChange("engineNumber", event.target.value)
            }
          />
          {formDataErrors.engineNumber && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.vin}
        >
          <FormLabel>Vehicle Identification Number (VIN)</FormLabel>
          <Input
            placeholder="VIN"
            onChange={(event) => handleOnChange("vin", event.target.value)}
          />
          {formDataErrors.vin && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.color}
        >
          <FormLabel>Color</FormLabel>
          <Input
            placeholder="Color"
            onChange={(event) => handleOnChange("color", event.target.value)}
          />
          {formDataErrors.color && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.modelYear}
        >
          <FormLabel>Model Year</FormLabel>
          <Input
            id="filters-start-date"
            placeholder="Model Year"
            type="number"
            onChange={(event) =>
              handleOnChange("modelYear", event.target.value)
            }
          />
          {formDataErrors.modelYear && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.modelType}
        >
          <FormLabel>Model Type</FormLabel>
          <Input
            placeholder="Model Type"
            onChange={(event) =>
              handleOnChange("modelType", event.target.value)
            }
          />
          {formDataErrors.modelType && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.carBrand}
        >
          <FormLabel id="carBrand">Car Brand</FormLabel>
          <Autocomplete
            placeholder="Choose a brand"
            slotProps={{
              input: {
                autoComplete: "new-password", // disable autocomplete and autofill
              },
            }}
            options={brands}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleOnChange("carBrand", value?.name)}
            renderOption={(props, option) => (
              <AutocompleteOption {...props}>
                <ListItemDecorator>
                  <img
                    loading="lazy"
                    width="30"
                    src={option.image.optimized}
                    alt=""
                  />
                </ListItemDecorator>
                <ListItemContent sx={{ fontSize: "sm" }}>
                  {option.name}
                </ListItemContent>
              </AutocompleteOption>
            )}
          />
          {formDataErrors.carBrand && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.totalShares}
        >
          <FormLabel>Total Shares</FormLabel>
          <Input
            type="number"
            placeholder="Total Shares"
            onChange={(event) =>
              handleOnChange("totalShares", event.target.value)
            }
          />
          {formDataErrors.totalShares && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Typography level="h4" sx={{ mt: 2 }}>
        Shareholders
      </Typography>
      <Grid xs={12} sm={12} md={12}>
        {shareholders.length != 0 ? (
          <>
            <Table
              aria-label="basic table"
              borderAxis="bothBetween"
              color="neutral"
              size="md"
              stickyFooter={false}
              stickyHeader={false}
              variant="soft"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th style={{ width: "50%" }}>Wallet</th>
                  <th>Shares</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {shareholders.map((row) => (
                  <tr>
                    <td>
                      <Typography level="body-xs">
                        {row?.shareholderFirstname +
                          " " +
                          row?.shareholderLastname}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {row?.shareholderWallet}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {row?.totalShares}
                      </Typography>
                    </td>
                    <td>
                      <IconButton
                        color="danger"
                        size="xs"
                        variant="plain"
                        onClick={() => {
                          console.log(row?.shareholderWallet);
                          setShareholders(
                            shareholders.filter(function (item) {
                              return item !== row;
                            })
                          );
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card
              invertedColors={false}
              orientation="horizontal"
              size="md"
              variant="plain"
              color="neutral"
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography level="title-md">
                  You can add more shareholders here.
                </Typography>
                <IconButton
                  color="primary"
                  size="lg"
                  variant="plain"
                  onClick={() => setShareholderModal(true)}
                >
                  <AddCircleIcon />
                </IconButton>
              </CardContent>
            </Card>
          </>
        ) : (
          <Card
            invertedColors={false}
            orientation="horizontal"
            size="md"
            variant="plain"
            color="neutral"
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography level="title-md">
                You can add shareholders here.
              </Typography>
              <IconButton
                color="primary"
                size="lg"
                variant="plain"
                onClick={() => setShareholderModal(true)}
              >
                <AddCircleIcon />
              </IconButton>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.files}
        >
          <FormLabel>File</FormLabel>
          <DropZone
            onChange={(files) => {
              handleChangeDropZone(files);
            }}
            error={formDataErrors.files}
          />
          {files?.length > 0 && files.map((item) => <div>{item.path}</div>)}
          {formDataErrors.files && (
            <FormHelperText>
              <InfoOutlined />
              {t("pleaseFill")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Modal open={shareholderModal} onClose={() => setShareholderModal(false)}>
        <ModalDialog color="primary" layout="center" size="lg" variant="soft">
          <ModalClose />
          <DialogTitle>{t("addShareholderShort")}</DialogTitle>
          <DialogContent>{t("addShareholderSearch")}</DialogContent>
          <FormControl>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <form onSubmit={search}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <Input
                      placeholder="Search"
                      value={
                        currency == "nationalId"
                          ? searchFormData.nationalId
                          : currency == "passportId"
                          ? searchFormData.passportId
                          : searchFormData.civilRegistryDocumentId
                      }
                      onChange={(event) => {
                        handleOnSearchChange(currency, event.target.value);
                      }}
                      endDecorator={
                        <Button
                          variant="soft"
                          color="primary"
                          startDecorator={<SearchIcon />}
                          type="submit"
                        >
                          {t("search")}
                        </Button>
                      }
                      startDecorator={
                        <React.Fragment>
                          <Select
                            variant="plain"
                            value={currency}
                            onChange={(_, value) => {
                              setCurrency(value);
                              handleOnSearchChange(value, "");
                            }}
                            slotProps={{
                              listbox: {
                                variant: "outlined",
                              },
                            }}
                          >
                            <Option value="nationalId">
                              Search by National ID
                            </Option>
                            <Option value="passportId">
                              Search by Passport ID
                            </Option>
                            <Option value="civilRegistryDocumentId">
                              Search by Civil Registry Document ID
                            </Option>
                          </Select>
                          <Divider orientation="vertical" />
                        </React.Fragment>
                      }
                    />
                  </FormControl>
                </form>
              </Grid>
              {fromUserData &&
                (fromUserData == "error" ? (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            {t("noResultsFound")}
                          </Typography>
                          <Typography>
                            Click{" "}
                            <Link
                              component="button"
                              underline="none"
                              color="primary"
                              onClick={() => navigate("/users/create")}
                            >
                              here
                            </Link>{" "}
                            to create a user.
                          </Typography>
                        </CardContent>
                      </Card>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            {fromUserData?.firstName +
                              " " +
                              fromUserData?.lastName}
                          </Typography>
                          <Typography>
                            Date of Birth: {fromUserData?.dateOfBirth}
                          </Typography>
                          <Typography>
                            Place of Birth: {fromUserData?.placeOfBirth}
                          </Typography>
                        </CardContent>
                        <Divider orientation="vertical" />
                        <Input
                          type="number"
                          size="sm"
                          placeholder="Share"
                          onChange={(event) =>
                            handleOnShareholderChange(
                              "totalShares",
                              event.target.value
                            )
                          }
                        />
                      </Card>
                    </FormControl>
                  </Grid>
                ))}
            </Grid>
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              size="sm"
              onClick={() => addShareholder()}
              disabled={shareholderFormData?.totalShares == ""}
            >
              Add Shareholder
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        variant="soft"
        color="warning"
        open={openSnackbarWarning}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarWarning(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarWarning(false)}
            size="sm"
            variant="soft"
            color="warning"
          >
            Dismiss
          </Button>
        }
      >
        User already added.
      </Snackbar>

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbarSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarSuccess(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        User added.
      </Snackbar>
    </Grid>
  );
};

const brands = [
  {
    name: "Abbott-Detroit",
    slug: "abbott-detroit",
    image: {
      source: "https://www.carlogos.org/logo/Hupmobile-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/abbott-detroit.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/abbott-detroit.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/abbott-detroit.png",
      localThumb: "./thumb/abbott-detroit.png",
      localOptimized: "./optimized/abbott-detroit.png",
      localOriginal: "./original/abbott-detroit.png",
    },
  },
  {
    name: "ABT",
    slug: "abt",
    image: {
      source:
        "https://www.carlogos.org/logo/ABT-Sportsline-logo-silver-640x250.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/abt.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/abt.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/abt.jpg",
      localThumb: "./thumb/abt.png",
      localOptimized: "./optimized/abt.png",
      localOriginal: "./original/abt.jpg",
    },
  },
  {
    name: "AC",
    slug: "ac",
    image: {
      source: "https://www.carlogos.org/logo/AC-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ac.jpg",
      localThumb: "./thumb/ac.png",
      localOptimized: "./optimized/ac.png",
      localOriginal: "./original/ac.jpg",
    },
  },
  {
    name: "Abarth",
    slug: "abarth",
    image: {
      source: "https://www.carlogos.org/logo/Abarth-logo-640x540.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/abarth.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/abarth.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/abarth.jpg",
      localThumb: "./thumb/abarth.png",
      localOptimized: "./optimized/abarth.png",
      localOriginal: "./original/abarth.jpg",
    },
  },
  {
    name: "Abadal",
    slug: "abadal",
    image: {
      source: "https://www.carlogos.org/logo/Abadal-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/abadal.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/abadal.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/abadal.jpg",
      localThumb: "./thumb/abadal.png",
      localOptimized: "./optimized/abadal.png",
      localOriginal: "./original/abadal.jpg",
    },
  },
  {
    name: "Acura",
    slug: "acura",
    image: {
      source: "https://www.carlogos.org/logo/Acura-logo-1990-640x406.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/acura.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/acura.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/acura.jpg",
      localThumb: "./thumb/acura.png",
      localOptimized: "./optimized/acura.png",
      localOriginal: "./original/acura.jpg",
    },
  },
  {
    name: "Aixam",
    slug: "aixam",
    image: {
      source: "https://www.carlogos.org/logo/Aixam-logo-2010-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/aixam.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/aixam.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/aixam.jpg",
      localThumb: "./thumb/aixam.png",
      localOptimized: "./optimized/aixam.png",
      localOriginal: "./original/aixam.jpg",
    },
  },
  {
    name: "Alpine",
    slug: "alpine",
    image: {
      source: "https://www.carlogos.org/logo/Alpine-logo-640x341.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/alpine.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/alpine.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/alpine.jpg",
      localThumb: "./thumb/alpine.png",
      localOptimized: "./optimized/alpine.png",
      localOriginal: "./original/alpine.jpg",
    },
  },
  {
    name: "Alpina",
    slug: "alpina",
    image: {
      source: "https://www.carlogos.org/logo/Alpina-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/alpina.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/alpina.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/alpina.jpg",
      localThumb: "./thumb/alpina.png",
      localOptimized: "./optimized/alpina.png",
      localOriginal: "./original/alpina.jpg",
    },
  },
  {
    name: "Alfa Romeo",
    slug: "alfa-romeo",
    image: {
      source: "https://www.carlogos.org/logo/Alfa-Romeo-logo-2015-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/alfa-romeo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/alfa-romeo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/alfa-romeo.jpg",
      localThumb: "./thumb/alfa-romeo.png",
      localOptimized: "./optimized/alfa-romeo.png",
      localOriginal: "./original/alfa-romeo.jpg",
    },
  },
  {
    name: "Alta",
    slug: "alta",
    image: {
      source: "https://www.carlogos.org/logo/Hillman-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/alta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/alta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/alta.png",
      localThumb: "./thumb/alta.png",
      localOptimized: "./optimized/alta.png",
      localOriginal: "./original/alta.png",
    },
  },
  {
    name: "Alvis",
    slug: "alvis",
    image: {
      source: "https://www.carlogos.org/logo/Alvis-logo-640x327.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/alvis.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/alvis.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/alvis.jpg",
      localThumb: "./thumb/alvis.png",
      localOptimized: "./optimized/alvis.png",
      localOriginal: "./original/alvis.jpg",
    },
  },
  {
    name: "AMC",
    slug: "amc",
    image: {
      source: "https://www.carlogos.org/logo/American-Motors-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/amc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/amc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/amc.jpg",
      localThumb: "./thumb/amc.png",
      localOptimized: "./optimized/amc.png",
      localOriginal: "./original/amc.jpg",
    },
  },
  {
    name: "Apollo",
    slug: "apollo",
    image: {
      source: "https://www.carlogos.org/logo/Apollo-Automobil-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/apollo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/apollo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/apollo.jpg",
      localThumb: "./thumb/apollo.png",
      localOptimized: "./optimized/apollo.png",
      localOriginal: "./original/apollo.jpg",
    },
  },
  {
    name: "Arcfox",
    slug: "arcfox",
    image: {
      source:
        "https://www.carlogos.org/car-logos/arcfox-logo-1250x220-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/arcfox.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/arcfox.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/arcfox.png",
      localThumb: "./thumb/arcfox.png",
      localOptimized: "./optimized/arcfox.png",
      localOriginal: "./original/arcfox.png",
    },
  },
  {
    name: "Arash",
    slug: "arash",
    image: {
      source: "https://www.carlogos.org/logo/Arash-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/arash.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/arash.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/arash.jpg",
      localThumb: "./thumb/arash.png",
      localOptimized: "./optimized/arash.png",
      localOriginal: "./original/arash.jpg",
    },
  },
  {
    name: "ARO",
    slug: "aro",
    image: {
      source: "https://www.carlogos.org/logo/Gilbern-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/aro.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/aro.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/aro.png",
      localThumb: "./thumb/aro.png",
      localOptimized: "./optimized/aro.png",
      localOriginal: "./original/aro.png",
    },
  },
  {
    name: "Ariel",
    slug: "ariel",
    image: {
      source: "https://www.carlogos.org/logo/Ariel-logo-2000-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ariel.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ariel.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ariel.jpg",
      localThumb: "./thumb/ariel.png",
      localOptimized: "./optimized/ariel.png",
      localOriginal: "./original/ariel.jpg",
    },
  },
  {
    name: "Arrival",
    slug: "arrival",
    image: {
      source:
        "https://www.carlogos.org/car-logos/arrival-logo-2600x350-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/arrival.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/arrival.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/arrival.png",
      localThumb: "./thumb/arrival.png",
      localOptimized: "./optimized/arrival.png",
      localOriginal: "./original/arrival.png",
    },
  },
  {
    name: "Arrinera",
    slug: "arrinera",
    image: {
      source: "https://www.carlogos.org/logo/Arrinera-logo-640x486.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/arrinera.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/arrinera.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/arrinera.jpg",
      localThumb: "./thumb/arrinera.png",
      localOptimized: "./optimized/arrinera.png",
      localOriginal: "./original/arrinera.jpg",
    },
  },
  {
    name: "Ascari",
    slug: "ascari",
    image: {
      source: "https://www.carlogos.org/logo/Ascari-logo-1995-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ascari.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ascari.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ascari.jpg",
      localThumb: "./thumb/ascari.png",
      localOptimized: "./optimized/ascari.png",
      localOriginal: "./original/ascari.jpg",
    },
  },
  {
    name: "Artega",
    slug: "artega",
    image: {
      source: "https://www.carlogos.org/logo/Artega-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/artega.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/artega.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/artega.jpg",
      localThumb: "./thumb/artega.png",
      localOptimized: "./optimized/artega.png",
      localOriginal: "./original/artega.jpg",
    },
  },
  {
    name: "Askam",
    slug: "askam",
    image: {
      source: "https://www.carlogos.org/logo/Askam-logo-640x124.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/askam.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/askam.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/askam.jpg",
      localThumb: "./thumb/askam.png",
      localOptimized: "./optimized/askam.png",
      localOriginal: "./original/askam.jpg",
    },
  },
  {
    name: "Aspark",
    slug: "aspark",
    image: {
      source:
        "https://www.carlogos.org/car-logos/aspark-logo-1000x1000-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/aspark.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/aspark.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/aspark.png",
      localThumb: "./thumb/aspark.png",
      localOptimized: "./optimized/aspark.png",
      localOriginal: "./original/aspark.png",
    },
  },
  {
    name: "Aston Martin",
    slug: "aston-martin",
    image: {
      source:
        "https://www.carlogos.org/logo/Aston-Martin-logo-2003-640x286.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/aston-martin.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/aston-martin.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/aston-martin.jpg",
      localThumb: "./thumb/aston-martin.png",
      localOptimized: "./optimized/aston-martin.png",
      localOriginal: "./original/aston-martin.jpg",
    },
  },
  {
    name: "Atalanta",
    slug: "atalanta",
    image: {
      source: "https://www.carlogos.org/logo/Atalanta-Motors-logo-640x127.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/atalanta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/atalanta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/atalanta.jpg",
      localThumb: "./thumb/atalanta.png",
      localOptimized: "./optimized/atalanta.png",
      localOriginal: "./original/atalanta.jpg",
    },
  },
  {
    name: "Auburn",
    slug: "auburn",
    image: {
      source: "https://www.carlogos.org/logo/Pierce-Arrow-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/auburn.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/auburn.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/auburn.png",
      localThumb: "./thumb/auburn.png",
      localOptimized: "./optimized/auburn.png",
      localOriginal: "./original/auburn.png",
    },
  },
  {
    name: "Audi Sport",
    slug: "audi-sport",
    image: {
      source: "https://www.carlogos.org/logo/Audi-Sport-logo-640x76.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/audi-sport.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/audi-sport.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/audi-sport.jpg",
      localThumb: "./thumb/audi-sport.png",
      localOptimized: "./optimized/audi-sport.png",
      localOriginal: "./original/audi-sport.jpg",
    },
  },
  {
    name: "Audi",
    slug: "audi",
    image: {
      source: "https://www.carlogos.org/logo/Audi-logo-2009-640x334.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/audi.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/audi.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/audi.jpg",
      localThumb: "./thumb/audi.png",
      localOptimized: "./optimized/audi.png",
      localOriginal: "./original/audi.jpg",
    },
  },
  {
    name: "Austin",
    slug: "austin",
    image: {
      source: "https://www.carlogos.org/logo/Morris-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/austin.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/austin.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/austin.png",
      localThumb: "./thumb/austin.png",
      localOptimized: "./optimized/austin.png",
      localOriginal: "./original/austin.png",
    },
  },
  {
    name: "Autobianchi",
    slug: "autobianchi",
    image: {
      source: "https://www.carlogos.org/logo/Autobianchi-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/autobianchi.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/autobianchi.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/autobianchi.jpg",
      localThumb: "./thumb/autobianchi.png",
      localOptimized: "./optimized/autobianchi.png",
      localOriginal: "./original/autobianchi.jpg",
    },
  },
  {
    name: "Autobacs",
    slug: "autobacs",
    image: {
      source: "https://www.carlogos.org/logo/Autobacs-logo-640x462.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/autobacs.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/autobacs.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/autobacs.jpg",
      localThumb: "./thumb/autobacs.png",
      localOptimized: "./optimized/autobacs.png",
      localOriginal: "./original/autobacs.jpg",
    },
  },
  {
    name: "BAC",
    slug: "bac",
    image: {
      source: "https://www.carlogos.org/logo/BAC-logo-black-640x297.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bac.jpg",
      localThumb: "./thumb/bac.png",
      localOptimized: "./optimized/bac.png",
      localOriginal: "./original/bac.jpg",
    },
  },
  {
    name: "Axon",
    slug: "axon",
    image: {
      source: "https://www.carlogos.org/logo/Axon-Automotive-logo-640x520.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/axon.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/axon.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/axon.jpg",
      localThumb: "./thumb/axon.png",
      localOptimized: "./optimized/axon.png",
      localOriginal: "./original/axon.jpg",
    },
  },
  {
    name: "BAIC Motor",
    slug: "baic-motor",
    image: {
      source: "https://www.carlogos.org/logo/BAIC-Motor-logo-640x168.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/baic-motor.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/baic-motor.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/baic-motor.jpg",
      localThumb: "./thumb/baic-motor.png",
      localOptimized: "./optimized/baic-motor.png",
      localOriginal: "./original/baic-motor.jpg",
    },
  },
  {
    name: "Baojun",
    slug: "baojun",
    image: {
      source: "https://www.carlogos.org/logo/Baojun-logo-2010-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/baojun.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/baojun.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/baojun.jpg",
      localThumb: "./thumb/baojun.png",
      localOptimized: "./optimized/baojun.png",
      localOriginal: "./original/baojun.jpg",
    },
  },
  {
    name: "BeiBen",
    slug: "beiben",
    image: {
      source:
        "https://www.carlogos.org/car-logos/beiben-logo-900x1000-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/beiben.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/beiben.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/beiben.png",
      localThumb: "./thumb/beiben.png",
      localOptimized: "./optimized/beiben.png",
      localOriginal: "./original/beiben.png",
    },
  },
  {
    name: "Berkeley",
    slug: "berkeley",
    image: {
      source: "https://www.carlogos.org/logo/Austin-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/berkeley.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/berkeley.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/berkeley.png",
      localThumb: "./thumb/berkeley.png",
      localOptimized: "./optimized/berkeley.png",
      localOriginal: "./original/berkeley.png",
    },
  },
  {
    name: "Bentley",
    slug: "bentley",
    image: {
      source: "https://www.carlogos.org/logo/Bentley-logo-640x324.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bentley.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bentley.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bentley.jpg",
      localThumb: "./thumb/bentley.png",
      localOptimized: "./optimized/bentley.png",
      localOriginal: "./original/bentley.jpg",
    },
  },
  {
    name: "Berliet",
    slug: "berliet",
    image: {
      source: "https://www.carlogos.org/logo/Berliet-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/berliet.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/berliet.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/berliet.jpg",
      localThumb: "./thumb/berliet.png",
      localOptimized: "./optimized/berliet.png",
      localOriginal: "./original/berliet.jpg",
    },
  },
  {
    name: "Bertone",
    slug: "bertone",
    image: {
      source: "https://www.carlogos.org/logo/Bertone-logo-640x388.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bertone.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bertone.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bertone.jpg",
      localThumb: "./thumb/bertone.png",
      localOptimized: "./optimized/bertone.png",
      localOriginal: "./original/bertone.jpg",
    },
  },
  {
    name: "Bitter",
    slug: "bitter",
    image: {
      source: "https://www.carlogos.org/logo/Bitter-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bitter.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bitter.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bitter.jpg",
      localThumb: "./thumb/bitter.png",
      localOptimized: "./optimized/bitter.png",
      localOriginal: "./original/bitter.jpg",
    },
  },
  {
    name: "BharatBenz",
    slug: "bharatbenz",
    image: {
      source: "https://www.carlogos.org/logo/BharatBenz-logo-640x480.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bharatbenz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bharatbenz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bharatbenz.jpg",
      localThumb: "./thumb/bharatbenz.png",
      localOptimized: "./optimized/bharatbenz.png",
      localOriginal: "./original/bharatbenz.jpg",
    },
  },
  {
    name: "Bestune",
    slug: "bestune",
    image: {
      source:
        "https://www.carlogos.org/car-logos/bestune-logo-2400x1200-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bestune.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bestune.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bestune.png",
      localThumb: "./thumb/bestune.png",
      localOptimized: "./optimized/bestune.png",
      localOriginal: "./original/bestune.png",
    },
  },
  {
    name: "BMW",
    slug: "bmw",
    image: {
      source:
        "https://www.carlogos.org/car-logos/bmw-logo-2020-blue-white-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bmw.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bmw.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bmw.png",
      localThumb: "./thumb/bmw.png",
      localOptimized: "./optimized/bmw.png",
      localOriginal: "./original/bmw.png",
    },
  },
  {
    name: "BMW M",
    slug: "bmw-m",
    image: {
      source: "https://www.carlogos.org/logo/BMW-M-logo-640x231.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bmw-m.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bmw-m.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bmw-m.jpg",
      localThumb: "./thumb/bmw-m.png",
      localOptimized: "./optimized/bmw-m.png",
      localOriginal: "./original/bmw-m.jpg",
    },
  },
  {
    name: "Bowler",
    slug: "bowler",
    image: {
      source: "https://www.carlogos.org/logo/Bowler-logo-640x238.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bowler.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bowler.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bowler.jpg",
      localThumb: "./thumb/bowler.png",
      localOptimized: "./optimized/bowler.png",
      localOriginal: "./original/bowler.jpg",
    },
  },
  {
    name: "Borgward",
    slug: "borgward",
    image: {
      source: "https://www.carlogos.org/logo/Borgward-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/borgward.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/borgward.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/borgward.jpg",
      localThumb: "./thumb/borgward.png",
      localOptimized: "./optimized/borgward.png",
      localOriginal: "./original/borgward.jpg",
    },
  },
  {
    name: "Bizzarrini",
    slug: "bizzarrini",
    image: {
      source: "https://www.carlogos.org/logo/Bizzarrini-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bizzarrini.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bizzarrini.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bizzarrini.jpg",
      localThumb: "./thumb/bizzarrini.png",
      localOptimized: "./optimized/bizzarrini.png",
      localOriginal: "./original/bizzarrini.jpg",
    },
  },
  {
    name: "Brabus",
    slug: "brabus",
    image: {
      source: "https://www.carlogos.org/logo/Brabus-logo-640x281.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/brabus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/brabus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/brabus.jpg",
      localThumb: "./thumb/brabus.png",
      localOptimized: "./optimized/brabus.png",
      localOriginal: "./original/brabus.jpg",
    },
  },
  {
    name: "Brammo",
    slug: "brammo",
    image: {
      source: "https://www.carlogos.org/logo/Brammo-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/brammo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/brammo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/brammo.jpg",
      localThumb: "./thumb/brammo.png",
      localOptimized: "./optimized/brammo.png",
      localOriginal: "./original/brammo.jpg",
    },
  },
  {
    name: "Brooke",
    slug: "brooke",
    image: {
      source: "https://www.carlogos.org/logo/Brooke-Cars-logo-640x234.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/brooke.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/brooke.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/brooke.jpg",
      localThumb: "./thumb/brooke.png",
      localOptimized: "./optimized/brooke.png",
      localOriginal: "./original/brooke.jpg",
    },
  },
  {
    name: "Brilliance",
    slug: "brilliance",
    image: {
      source: "https://www.carlogos.org/logo/Brilliance-logo-640x435.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/brilliance.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/brilliance.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/brilliance.jpg",
      localThumb: "./thumb/brilliance.png",
      localOptimized: "./optimized/brilliance.png",
      localOriginal: "./original/brilliance.jpg",
    },
  },
  {
    name: "Bristol",
    slug: "bristol",
    image: {
      source: "https://www.carlogos.org/logo/AC-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bristol.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bristol.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bristol.png",
      localThumb: "./thumb/bristol.png",
      localOptimized: "./optimized/bristol.png",
      localOriginal: "./original/bristol.png",
    },
  },
  {
    name: "Bufori",
    slug: "bufori",
    image: {
      source: "https://www.carlogos.org/logo/Bufori-logo-640x202.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bufori.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bufori.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bufori.jpg",
      localThumb: "./thumb/bufori.png",
      localOptimized: "./optimized/bufori.png",
      localOriginal: "./original/bufori.jpg",
    },
  },
  {
    name: "Buick",
    slug: "buick",
    image: {
      source: "https://www.carlogos.org/logo/Buick-logo-2002-640x200.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/buick.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/buick.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/buick.jpg",
      localThumb: "./thumb/buick.png",
      localOptimized: "./optimized/buick.png",
      localOriginal: "./original/buick.jpg",
    },
  },
  {
    name: "Bugatti",
    slug: "bugatti",
    image: {
      source: "https://www.carlogos.org/logo/Bugatti-logo-640x327.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/bugatti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/bugatti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/bugatti.jpg",
      localThumb: "./thumb/bugatti.png",
      localOptimized: "./optimized/bugatti.png",
      localOriginal: "./original/bugatti.jpg",
    },
  },
  {
    name: "Byton",
    slug: "byton",
    image: {
      source: "https://www.carlogos.org/car-logos/byton-logo-2100x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/byton.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/byton.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/byton.png",
      localThumb: "./thumb/byton.png",
      localOptimized: "./optimized/byton.png",
      localOriginal: "./original/byton.png",
    },
  },
  {
    name: "BYD",
    slug: "byd",
    image: {
      source: "https://www.carlogos.org/logo/BYD-logo-2007-640x396.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/byd.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/byd.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/byd.jpg",
      localThumb: "./thumb/byd.png",
      localOptimized: "./optimized/byd.png",
      localOriginal: "./original/byd.jpg",
    },
  },
  {
    name: "Canoo",
    slug: "canoo",
    image: {
      source: "https://www.carlogos.org/car-logos/canoo-logo-1100x500-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/canoo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/canoo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/canoo.png",
      localThumb: "./thumb/canoo.png",
      localOptimized: "./optimized/canoo.png",
      localOriginal: "./original/canoo.png",
    },
  },
  {
    name: "Cadillac",
    slug: "cadillac",
    image: {
      source: "https://www.carlogos.org/logo/Cadillac-logo-2014-640x250.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/cadillac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/cadillac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/cadillac.jpg",
      localThumb: "./thumb/cadillac.png",
      localOptimized: "./optimized/cadillac.png",
      localOriginal: "./original/cadillac.jpg",
    },
  },
  {
    name: "Caparo",
    slug: "caparo",
    image: {
      source: "https://www.carlogos.org/logo/Caparo-2006-logo-640x238.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/caparo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/caparo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/caparo.jpg",
      localThumb: "./thumb/caparo.png",
      localOptimized: "./optimized/caparo.png",
      localOriginal: "./original/caparo.jpg",
    },
  },
  {
    name: "Carlsson",
    slug: "carlsson",
    image: {
      source: "https://www.carlogos.org/logo/Carlsson-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/carlsson.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/carlsson.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/carlsson.jpg",
      localThumb: "./thumb/carlsson.png",
      localOptimized: "./optimized/carlsson.png",
      localOriginal: "./original/carlsson.jpg",
    },
  },
  {
    name: "CAMC",
    slug: "camc",
    image: {
      source: "https://www.carlogos.org/car-logos/camc-logo-1600x450-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/camc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/camc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/camc.png",
      localThumb: "./thumb/camc.png",
      localOptimized: "./optimized/camc.png",
      localOriginal: "./original/camc.png",
    },
  },
  {
    name: "Changan",
    slug: "changan",
    image: {
      source: "https://www.carlogos.org/logo/Changan-logo-2010-640x174.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/changan.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/changan.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/changan.jpg",
      localThumb: "./thumb/changan.png",
      localOptimized: "./optimized/changan.png",
      localOriginal: "./original/changan.jpg",
    },
  },
  {
    name: "Changfeng",
    slug: "changfeng",
    image: {
      source: "https://www.carlogos.org/logo/Changfeng-logo-640x417.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/changfeng.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/changfeng.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/changfeng.jpg",
      localThumb: "./thumb/changfeng.png",
      localOptimized: "./optimized/changfeng.png",
      localOriginal: "./original/changfeng.jpg",
    },
  },
  {
    name: "Chery",
    slug: "chery",
    image: {
      source: "https://www.carlogos.org/logo/Chery-logo-2013-640x346.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/chery.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/chery.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/chery.jpg",
      localThumb: "./thumb/chery.png",
      localOptimized: "./optimized/chery.png",
      localOriginal: "./original/chery.jpg",
    },
  },
  {
    name: "Caterham",
    slug: "caterham",
    image: {
      source: "https://www.carlogos.org/logo/Caterham-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/caterham.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/caterham.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/caterham.jpg",
      localThumb: "./thumb/caterham.png",
      localOptimized: "./optimized/caterham.png",
      localOriginal: "./original/caterham.jpg",
    },
  },
  {
    name: "Chevrolet Corvette",
    slug: "chevrolet-corvette",
    image: {
      source: "https://www.carlogos.org/logo/Corvette-logo-2014-640x431.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/chevrolet-corvette.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/chevrolet-corvette.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/chevrolet-corvette.jpg",
      localThumb: "./thumb/chevrolet-corvette.png",
      localOptimized: "./optimized/chevrolet-corvette.png",
      localOriginal: "./original/chevrolet-corvette.jpg",
    },
  },
  {
    name: "Chevrolet",
    slug: "chevrolet",
    image: {
      source: "https://www.carlogos.org/logo/Chevrolet-logo-2013-640x281.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/chevrolet.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/chevrolet.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/chevrolet.jpg",
      localThumb: "./thumb/chevrolet.png",
      localOptimized: "./optimized/chevrolet.png",
      localOriginal: "./original/chevrolet.jpg",
    },
  },
  {
    name: "Chrysler",
    slug: "chrysler",
    image: {
      source: "https://www.carlogos.org/logo/Chrysler-logo-2010-640x104.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/chrysler.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/chrysler.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/chrysler.jpg",
      localThumb: "./thumb/chrysler.png",
      localOptimized: "./optimized/chrysler.png",
      localOriginal: "./original/chrysler.jpg",
    },
  },
  {
    name: "Cisitalia",
    slug: "cisitalia",
    image: {
      source: "https://www.carlogos.org/logo/Intermeccanica-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/cisitalia.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/cisitalia.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/cisitalia.png",
      localThumb: "./thumb/cisitalia.png",
      localOptimized: "./optimized/cisitalia.png",
      localOriginal: "./original/cisitalia.png",
    },
  },
  {
    name: "Cizeta",
    slug: "cizeta",
    image: {
      source: "https://www.carlogos.org/logo/Cizeta-logo-640x347.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/cizeta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/cizeta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/cizeta.jpg",
      localThumb: "./thumb/cizeta.png",
      localOptimized: "./optimized/cizeta.png",
      localOriginal: "./original/cizeta.jpg",
    },
  },
  {
    name: "Citroën",
    slug: "citroen",
    image: {
      source: "https://www.carlogos.org/logo/Citroen-logo-2009-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/citroen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/citroen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/citroen.jpg",
      localThumb: "./thumb/citroen.png",
      localOptimized: "./optimized/citroen.png",
      localOriginal: "./original/citroen.jpg",
    },
  },
  {
    name: "Cole",
    slug: "cole",
    image: {
      source: "https://www.carlogos.org/logo/Hupmobile-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/cole.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/cole.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/cole.png",
      localThumb: "./thumb/cole.png",
      localOptimized: "./optimized/cole.png",
      localOriginal: "./original/cole.png",
    },
  },
  {
    name: "Corre La Licorne",
    slug: "corre-la-licorne",
    image: {
      source: "https://www.carlogos.org/logo/Berliet-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/corre-la-licorne.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/corre-la-licorne.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/corre-la-licorne.png",
      localThumb: "./thumb/corre-la-licorne.png",
      localOptimized: "./optimized/corre-la-licorne.png",
      localOriginal: "./original/corre-la-licorne.png",
    },
  },
  {
    name: "DAF",
    slug: "daf",
    image: {
      source: "https://www.carlogos.org/logo/DAF-logo-640x281.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/daf.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/daf.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/daf.jpg",
      localThumb: "./thumb/daf.png",
      localOptimized: "./optimized/daf.png",
      localOriginal: "./original/daf.jpg",
    },
  },
  {
    name: "Daewoo",
    slug: "daewoo",
    image: {
      source: "https://www.carlogos.org/logo/Daewoo-logo-640x404.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/daewoo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/daewoo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/daewoo.jpg",
      localThumb: "./thumb/daewoo.png",
      localOptimized: "./optimized/daewoo.png",
      localOriginal: "./original/daewoo.jpg",
    },
  },
  {
    name: "Dacia",
    slug: "dacia",
    image: {
      source: "https://www.carlogos.org/logo/Dacia-logo-2008-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dacia.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dacia.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dacia.jpg",
      localThumb: "./thumb/dacia.png",
      localOptimized: "./optimized/dacia.png",
      localOriginal: "./original/dacia.jpg",
    },
  },
  {
    name: "Daimler",
    slug: "daimler",
    image: {
      source: "https://www.carlogos.org/logo/Daimler-logo-640x80.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/daimler.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/daimler.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/daimler.jpg",
      localThumb: "./thumb/daimler.png",
      localOptimized: "./optimized/daimler.png",
      localOriginal: "./original/daimler.jpg",
    },
  },
  {
    name: "Daihatsu",
    slug: "daihatsu",
    image: {
      source: "https://www.carlogos.org/logo/Daihatsu-logo-1997-1280x233.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/daihatsu.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/daihatsu.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/daihatsu.png",
      localThumb: "./thumb/daihatsu.png",
      localOptimized: "./optimized/daihatsu.png",
      localOriginal: "./original/daihatsu.png",
    },
  },
  {
    name: "David Brown",
    slug: "david-brown",
    image: {
      source: "https://www.carlogos.org/logo/David-Brown-logo-640x221.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/david-brown.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/david-brown.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/david-brown.jpg",
      localThumb: "./thumb/david-brown.png",
      localOptimized: "./optimized/david-brown.png",
      localOriginal: "./original/david-brown.jpg",
    },
  },
  {
    name: "Datsun",
    slug: "datsun",
    image: {
      source: "https://www.carlogos.org/logo/Datsun-logo-2013-640x344.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/datsun.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/datsun.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/datsun.jpg",
      localThumb: "./thumb/datsun.png",
      localOptimized: "./optimized/datsun.png",
      localOriginal: "./original/datsun.jpg",
    },
  },
  {
    name: "Dartz",
    slug: "dartz",
    image: {
      source: "https://www.carlogos.org/logo/Dartz-logo-yellow-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dartz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dartz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dartz.jpg",
      localThumb: "./thumb/dartz.png",
      localOptimized: "./optimized/dartz.png",
      localOriginal: "./original/dartz.jpg",
    },
  },
  {
    name: "De Tomaso",
    slug: "de-tomaso",
    image: {
      source: "https://www.carlogos.org/logo/De-Tomaso-logo-1960-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/de-tomaso.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/de-tomaso.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/de-tomaso.jpg",
      localThumb: "./thumb/de-tomaso.png",
      localOptimized: "./optimized/de-tomaso.png",
      localOriginal: "./original/de-tomaso.jpg",
    },
  },
  {
    name: "Dayun",
    slug: "dayun",
    image: {
      source:
        "https://www.carlogos.org/car-logos/dayun-logo-1100x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dayun.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dayun.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dayun.png",
      localThumb: "./thumb/dayun.png",
      localOptimized: "./optimized/dayun.png",
      localOriginal: "./original/dayun.png",
    },
  },
  {
    name: "Delage",
    slug: "delage",
    image: {
      source: "https://www.carlogos.org/logo/Delage-logo-640x428.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/delage.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/delage.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/delage.jpg",
      localThumb: "./thumb/delage.png",
      localOptimized: "./optimized/delage.png",
      localOriginal: "./original/delage.jpg",
    },
  },
  {
    name: "Devel Sixteen",
    slug: "devel-sixteen",
    image: {
      source: "https://www.carlogos.org/logo/Devel-Sixteen-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/devel-sixteen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/devel-sixteen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/devel-sixteen.jpg",
      localThumb: "./thumb/devel-sixteen.png",
      localOptimized: "./optimized/devel-sixteen.png",
      localOriginal: "./original/devel-sixteen.jpg",
    },
  },
  {
    name: "Detroit Electric",
    slug: "detroit-electric",
    image: {
      source: "https://www.carlogos.org/logo/Detroit-Electric-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/detroit-electric.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/detroit-electric.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/detroit-electric.jpg",
      localThumb: "./thumb/detroit-electric.png",
      localOptimized: "./optimized/detroit-electric.png",
      localOriginal: "./original/detroit-electric.jpg",
    },
  },
  {
    name: "Diatto",
    slug: "diatto",
    image: {
      source: "https://www.carlogos.org/logo/Innocenti-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/diatto.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/diatto.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/diatto.png",
      localThumb: "./thumb/diatto.png",
      localOptimized: "./optimized/diatto.png",
      localOriginal: "./original/diatto.png",
    },
  },
  {
    name: "DINA",
    slug: "dina",
    image: {
      source: "https://www.carlogos.org/logo/DINA-logo-640x159.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dina.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dina.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dina.jpg",
      localThumb: "./thumb/dina.png",
      localOptimized: "./optimized/dina.png",
      localOriginal: "./original/dina.jpg",
    },
  },
  {
    name: "DeSoto",
    slug: "desoto",
    image: {
      source: "https://www.carlogos.org/car-logos/desoto-logo-650x650-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/desoto.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/desoto.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/desoto.png",
      localThumb: "./thumb/desoto.png",
      localOptimized: "./optimized/desoto.png",
      localOriginal: "./original/desoto.png",
    },
  },
  {
    name: "DKW",
    slug: "dkw",
    image: {
      source: "https://www.carlogos.org/logo/DKW-logo-black-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dkw.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dkw.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dkw.jpg",
      localThumb: "./thumb/dkw.png",
      localOptimized: "./optimized/dkw.png",
      localOriginal: "./original/dkw.jpg",
    },
  },
  {
    name: "Dodge",
    slug: "dodge",
    image: {
      source: "https://www.carlogos.org/logo/Dodge-logo-2011-640x90.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dodge.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dodge.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dodge.jpg",
      localThumb: "./thumb/dodge.png",
      localOptimized: "./optimized/dodge.png",
      localOriginal: "./original/dodge.jpg",
    },
  },
  {
    name: "Dongfeng",
    slug: "dongfeng",
    image: {
      source: "https://www.carlogos.org/logo/Dongfeng-logo-640x117.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dongfeng.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dongfeng.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dongfeng.jpg",
      localThumb: "./thumb/dongfeng.png",
      localOptimized: "./optimized/dongfeng.png",
      localOriginal: "./original/dongfeng.jpg",
    },
  },
  {
    name: "Dodge Viper",
    slug: "dodge-viper",
    image: {
      source: "https://www.carlogos.org/logo/Viper-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/dodge-viper.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/dodge-viper.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/dodge-viper.jpg",
      localThumb: "./thumb/dodge-viper.png",
      localOptimized: "./optimized/dodge-viper.png",
      localOriginal: "./original/dodge-viper.jpg",
    },
  },
  {
    name: "Drako",
    slug: "drako",
    image: {
      source: "https://www.carlogos.org/car-logos/drako-logo-2100x400-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/drako.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/drako.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/drako.png",
      localThumb: "./thumb/drako.png",
      localOptimized: "./optimized/drako.png",
      localOriginal: "./original/drako.png",
    },
  },
  {
    name: "Donkervoort",
    slug: "donkervoort",
    image: {
      source: "https://www.carlogos.org/logo/Donkervoort-logo-640x262.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/donkervoort.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/donkervoort.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/donkervoort.jpg",
      localThumb: "./thumb/donkervoort.png",
      localOptimized: "./optimized/donkervoort.png",
      localOriginal: "./original/donkervoort.jpg",
    },
  },
  {
    name: "DS",
    slug: "ds",
    image: {
      source: "https://www.carlogos.org/logo/DS-logo-2009-640x486.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ds.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ds.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ds.jpg",
      localThumb: "./thumb/ds.png",
      localOptimized: "./optimized/ds.png",
      localOriginal: "./original/ds.jpg",
    },
  },
  {
    name: "EDAG",
    slug: "edag",
    image: {
      source: "https://www.carlogos.org/logo/EDAG-logo-640x158.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/edag.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/edag.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/edag.jpg",
      localThumb: "./thumb/edag.png",
      localOptimized: "./optimized/edag.png",
      localOriginal: "./original/edag.jpg",
    },
  },
  {
    name: "Eagle",
    slug: "eagle",
    image: {
      source: "https://www.carlogos.org/logo/Eagle-automobile-logo-640x514.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/eagle.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/eagle.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/eagle.jpg",
      localThumb: "./thumb/eagle.png",
      localOptimized: "./optimized/eagle.png",
      localOriginal: "./original/eagle.jpg",
    },
  },
  {
    name: "Eicher",
    slug: "eicher",
    image: {
      source: "https://www.carlogos.org/logo/Eicher-logo-640x323.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/eicher.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/eicher.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/eicher.jpg",
      localThumb: "./thumb/eicher.png",
      localOptimized: "./optimized/eicher.png",
      localOriginal: "./original/eicher.jpg",
    },
  },
  {
    name: "Edsel",
    slug: "edsel",
    image: {
      source: "https://www.carlogos.org/logo/Edsel-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/edsel.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/edsel.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/edsel.jpg",
      localThumb: "./thumb/edsel.png",
      localOptimized: "./optimized/edsel.png",
      localOriginal: "./original/edsel.jpg",
    },
  },
  {
    name: "Duesenberg",
    slug: "duesenberg",
    image: {
      source:
        "https://www.carlogos.org/car-logos/duesenberg-logo-1000x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/duesenberg.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/duesenberg.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/duesenberg.png",
      localThumb: "./thumb/duesenberg.png",
      localOptimized: "./optimized/duesenberg.png",
      localOriginal: "./original/duesenberg.png",
    },
  },
  {
    name: "Elemental",
    slug: "elemental",
    image: {
      source: "https://www.carlogos.org/logo/Elemental-logo-640x218.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/elemental.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/elemental.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/elemental.jpg",
      localThumb: "./thumb/elemental.png",
      localOptimized: "./optimized/elemental.png",
      localOriginal: "./original/elemental.jpg",
    },
  },
  {
    name: "Elfin",
    slug: "elfin",
    image: {
      source: "https://www.carlogos.org/logo/Holden-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/elfin.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/elfin.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/elfin.png",
      localThumb: "./thumb/elfin.png",
      localOptimized: "./optimized/elfin.png",
      localOriginal: "./original/elfin.png",
    },
  },
  {
    name: "Elva",
    slug: "elva",
    image: {
      source: "https://www.carlogos.org/logo/MK-Sportscars-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/elva.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/elva.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/elva.png",
      localThumb: "./thumb/elva.png",
      localOptimized: "./optimized/elva.png",
      localOriginal: "./original/elva.png",
    },
  },
  {
    name: "Eterniti",
    slug: "eterniti",
    image: {
      source: "https://www.carlogos.org/logo/TechArt-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/eterniti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/eterniti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/eterniti.png",
      localThumb: "./thumb/eterniti.png",
      localOptimized: "./optimized/eterniti.png",
      localOriginal: "./original/eterniti.png",
    },
  },
  {
    name: "ERF",
    slug: "erf",
    image: {
      source: "https://www.carlogos.org/logo/ERF-logo-640x363.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/erf.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/erf.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/erf.jpg",
      localThumb: "./thumb/erf.png",
      localOptimized: "./optimized/erf.png",
      localOriginal: "./original/erf.jpg",
    },
  },
  {
    name: "Englon",
    slug: "englon",
    image: {
      source: "https://www.carlogos.org/logo/Englon-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/englon.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/englon.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/englon.jpg",
      localThumb: "./thumb/englon.png",
      localOptimized: "./optimized/englon.png",
      localOriginal: "./original/englon.jpg",
    },
  },
  {
    name: "Exeed",
    slug: "exeed",
    image: {
      source: "https://www.carlogos.org/car-logos/exeed-logo-1200x200-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/exeed.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/exeed.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/exeed.png",
      localThumb: "./thumb/exeed.png",
      localOptimized: "./optimized/exeed.png",
      localOriginal: "./original/exeed.png",
    },
  },
  {
    name: "9ff",
    slug: "9ff",
    image: {
      source: "https://www.carlogos.org/logo/9ff-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/9ff.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/9ff.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/9ff.jpg",
      localThumb: "./thumb/9ff.png",
      localOptimized: "./optimized/9ff.png",
      localOriginal: "./original/9ff.jpg",
    },
  },
  {
    name: "Faraday Future",
    slug: "faraday-future",
    image: {
      source: "https://www.carlogos.org/logo/Faraday-Future-logo-640x472.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/faraday-future.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/faraday-future.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/faraday-future.jpg",
      localThumb: "./thumb/faraday-future.png",
      localOptimized: "./optimized/faraday-future.png",
      localOriginal: "./original/faraday-future.jpg",
    },
  },
  {
    name: "Facel Vega",
    slug: "facel-vega",
    image: {
      source: "https://www.carlogos.org/logo/Facel-Vega-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/facel-vega.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/facel-vega.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/facel-vega.jpg",
      localThumb: "./thumb/facel-vega.png",
      localOptimized: "./optimized/facel-vega.png",
      localOriginal: "./original/facel-vega.jpg",
    },
  },
  {
    name: "FAW Jiefang",
    slug: "faw-jiefang",
    image: {
      source: "https://www.carlogos.org/car-logos/faw-jiefang-950x700-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/faw-jiefang.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/faw-jiefang.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/faw-jiefang.png",
      localThumb: "./thumb/faw-jiefang.png",
      localOptimized: "./optimized/faw-jiefang.png",
      localOriginal: "./original/faw-jiefang.png",
    },
  },
  {
    name: "FAW",
    slug: "faw",
    image: {
      source: "https://www.carlogos.org/logo/FAW-logo-640x427.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/faw.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/faw.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/faw.jpg",
      localThumb: "./thumb/faw.png",
      localOptimized: "./optimized/faw.png",
      localOriginal: "./original/faw.jpg",
    },
  },
  {
    name: "Ferrari",
    slug: "ferrari",
    image: {
      source:
        "https://www.carlogos.org/car-logos/ferrari-logo-750x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ferrari.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ferrari.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ferrari.png",
      localThumb: "./thumb/ferrari.png",
      localOptimized: "./optimized/ferrari.png",
      localOriginal: "./original/ferrari.png",
    },
  },
  {
    name: "Fioravanti",
    slug: "fioravanti",
    image: {
      source: "https://www.carlogos.org/logo/Pininfarina-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/fioravanti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/fioravanti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/fioravanti.png",
      localThumb: "./thumb/fioravanti.png",
      localOptimized: "./optimized/fioravanti.png",
      localOriginal: "./original/fioravanti.png",
    },
  },
  {
    name: "Fiat",
    slug: "fiat",
    image: {
      source: "https://www.carlogos.org/logo/Fiat-logo-2006-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/fiat.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/fiat.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/fiat.jpg",
      localThumb: "./thumb/fiat.png",
      localOptimized: "./optimized/fiat.png",
      localOriginal: "./original/fiat.jpg",
    },
  },
  {
    name: "Fisker",
    slug: "fisker",
    image: {
      source: "https://www.carlogos.org/logo/Fisker-logo-2007-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/fisker.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/fisker.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/fisker.jpg",
      localThumb: "./thumb/fisker.png",
      localOptimized: "./optimized/fisker.png",
      localOriginal: "./original/fisker.jpg",
    },
  },
  {
    name: "Force Motors",
    slug: "force-motors",
    image: {
      source: "https://www.carlogos.org/logo/Force-Motors-logo-640x424.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/force-motors.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/force-motors.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/force-motors.jpg",
      localThumb: "./thumb/force-motors.png",
      localOptimized: "./optimized/force-motors.png",
      localOriginal: "./original/force-motors.jpg",
    },
  },
  {
    name: "Foden",
    slug: "foden",
    image: {
      source: "https://www.carlogos.org/logo/Foden-logo-640x480.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/foden.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/foden.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/foden.png",
      localThumb: "./thumb/foden.png",
      localOptimized: "./optimized/foden.png",
      localOriginal: "./original/foden.png",
    },
  },
  {
    name: "Foton",
    slug: "foton",
    image: {
      source: "https://www.carlogos.org/logo/Foton-logo-640x195.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/foton.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/foton.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/foton.jpg",
      localThumb: "./thumb/foton.png",
      localOptimized: "./optimized/foton.png",
      localOriginal: "./original/foton.jpg",
    },
  },
  {
    name: "Ford Mustang",
    slug: "ford-mustang",
    image: {
      source: "https://www.carlogos.org/logo/Mustang-logo-2010-640x359.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ford-mustang.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ford-mustang.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ford-mustang.jpg",
      localThumb: "./thumb/ford-mustang.png",
      localOptimized: "./optimized/ford-mustang.png",
      localOriginal: "./original/ford-mustang.jpg",
    },
  },
  {
    name: "Ford",
    slug: "ford",
    image: {
      source: "https://www.carlogos.org/car-logos/ford-logo-2017-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ford.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ford.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ford.png",
      localThumb: "./thumb/ford.png",
      localOptimized: "./optimized/ford.png",
      localOriginal: "./original/ford.png",
    },
  },
  {
    name: "Franklin",
    slug: "franklin",
    image: {
      source: "https://www.carlogos.org/logo/Cole-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/franklin.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/franklin.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/franklin.png",
      localThumb: "./thumb/franklin.png",
      localOptimized: "./optimized/franklin.png",
      localOriginal: "./original/franklin.png",
    },
  },
  {
    name: "FPV",
    slug: "fpv",
    image: {
      source: "https://www.carlogos.org/logo/FPV-emblem-blue-640x248.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/fpv.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/fpv.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/fpv.jpg",
      localThumb: "./thumb/fpv.png",
      localOptimized: "./optimized/fpv.png",
      localOriginal: "./original/fpv.jpg",
    },
  },
  {
    name: "Freightliner",
    slug: "freightliner",
    image: {
      source: "https://www.carlogos.org/logo/Freightliner-logo-640x124.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/freightliner.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/freightliner.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/freightliner.jpg",
      localThumb: "./thumb/freightliner.png",
      localOptimized: "./optimized/freightliner.png",
      localOriginal: "./original/freightliner.jpg",
    },
  },
  {
    name: "FSO",
    slug: "fso",
    image: {
      source: "https://www.carlogos.org/car-logos/fso-logo-1200x500-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/fso.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/fso.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/fso.png",
      localThumb: "./thumb/fso.png",
      localOptimized: "./optimized/fso.png",
      localOriginal: "./original/fso.png",
    },
  },
  {
    name: "Gardner Douglas",
    slug: "gardner-douglas",
    image: {
      source: "https://www.carlogos.org/logo/Gardner-Douglas-logo-640x269.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gardner-douglas.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gardner-douglas.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gardner-douglas.jpg",
      localThumb: "./thumb/gardner-douglas.png",
      localOptimized: "./optimized/gardner-douglas.png",
      localOriginal: "./original/gardner-douglas.jpg",
    },
  },
  {
    name: "GAC Group",
    slug: "gac-group",
    image: {
      source: "https://www.carlogos.org/logo/GAC-Group-logo-640x125.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gac-group.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gac-group.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gac-group.jpg",
      localThumb: "./thumb/gac-group.png",
      localOptimized: "./optimized/gac-group.png",
      localOriginal: "./original/gac-group.jpg",
    },
  },
  {
    name: "GAZ",
    slug: "gaz",
    image: {
      source: "https://www.carlogos.org/logo/GAZ-logo-2015-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gaz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gaz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gaz.jpg",
      localThumb: "./thumb/gaz.png",
      localOptimized: "./optimized/gaz.png",
      localOriginal: "./original/gaz.jpg",
    },
  },
  {
    name: "Geely",
    slug: "geely",
    image: {
      source: "https://www.carlogos.org/logo/Geely-logo-2014-640x370.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/geely.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/geely.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/geely.jpg",
      localThumb: "./thumb/geely.png",
      localOptimized: "./optimized/geely.png",
      localOriginal: "./original/geely.jpg",
    },
  },
  {
    name: "Geo",
    slug: "geo",
    image: {
      source: "https://www.carlogos.org/logo/Geo-logo-640x172.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/geo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/geo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/geo.jpg",
      localThumb: "./thumb/geo.png",
      localOptimized: "./optimized/geo.png",
      localOriginal: "./original/geo.jpg",
    },
  },
  {
    name: "Genesis",
    slug: "genesis",
    image: {
      source: "https://www.carlogos.org/logo/Genesis-logo-640x248.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/genesis.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/genesis.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/genesis.jpg",
      localThumb: "./thumb/genesis.png",
      localOptimized: "./optimized/genesis.png",
      localOriginal: "./original/genesis.jpg",
    },
  },
  {
    name: "Geometry",
    slug: "geometry",
    image: {
      source: "https://www.carlogos.org/car-logos/geometry-logo-640x480.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/geometry.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/geometry.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/geometry.png",
      localThumb: "./thumb/geometry.png",
      localOptimized: "./optimized/geometry.png",
      localOriginal: "./original/geometry.png",
    },
  },
  {
    name: "Gillet",
    slug: "gillet",
    image: {
      source: "https://www.carlogos.org/logo/Gilbern-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gillet.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gillet.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gillet.png",
      localThumb: "./thumb/gillet.png",
      localOptimized: "./optimized/gillet.png",
      localOriginal: "./original/gillet.png",
    },
  },
  {
    name: "Gilbern",
    slug: "gilbern",
    image: {
      source: "https://www.carlogos.org/logo/Abadal-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gilbern.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gilbern.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gilbern.png",
      localThumb: "./thumb/gilbern.png",
      localOptimized: "./optimized/gilbern.png",
      localOriginal: "./original/gilbern.png",
    },
  },
  {
    name: "GMC",
    slug: "gmc",
    image: {
      source: "https://www.carlogos.org/logo/GMC-logo-640x145.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gmc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gmc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gmc.jpg",
      localThumb: "./thumb/gmc.png",
      localOptimized: "./optimized/gmc.png",
      localOriginal: "./original/gmc.jpg",
    },
  },
  {
    name: "Ginetta",
    slug: "ginetta",
    image: {
      source: "https://www.carlogos.org/logo/Ginetta-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ginetta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ginetta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ginetta.jpg",
      localThumb: "./thumb/ginetta.png",
      localOptimized: "./optimized/ginetta.png",
      localOriginal: "./original/ginetta.jpg",
    },
  },
  {
    name: "Gonow",
    slug: "gonow",
    image: {
      source: "https://www.carlogos.org/logo/Gonow-logo-2010-640x135.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gonow.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gonow.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gonow.jpg",
      localThumb: "./thumb/gonow.png",
      localOptimized: "./optimized/gonow.png",
      localOriginal: "./original/gonow.jpg",
    },
  },
  {
    name: "Golden Dragon",
    slug: "golden-dragon",
    image: {
      source:
        "https://www.carlogos.org/car-logos/golden-dragon-logo-900x700-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/golden-dragon.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/golden-dragon.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/golden-dragon.png",
      localThumb: "./thumb/golden-dragon.png",
      localOptimized: "./optimized/golden-dragon.png",
      localOriginal: "./original/golden-dragon.png",
    },
  },
  {
    name: "Grinnall",
    slug: "grinnall",
    image: {
      source: "https://www.carlogos.org/logo/Grinnall-cars-logo-640x309.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/grinnall.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/grinnall.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/grinnall.jpg",
      localThumb: "./thumb/grinnall.png",
      localOptimized: "./optimized/grinnall.png",
      localOriginal: "./original/grinnall.jpg",
    },
  },
  {
    name: "Great Wall",
    slug: "great-wall",
    image: {
      source: "https://www.carlogos.org/logo/Great-Wall-logo-2007-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/great-wall.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/great-wall.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/great-wall.jpg",
      localThumb: "./thumb/great-wall.png",
      localOptimized: "./optimized/great-wall.png",
      localOriginal: "./original/great-wall.jpg",
    },
  },
  {
    name: "General Motors",
    slug: "general-motors",
    image: {
      source:
        "https://www.carlogos.org/logo/General-Motors-logo-2010-3300x3300.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/general-motors.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/general-motors.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/general-motors.png",
      localThumb: "./thumb/general-motors.png",
      localOptimized: "./optimized/general-motors.png",
      localOriginal: "./original/general-motors.png",
    },
  },
  {
    name: "Gumpert",
    slug: "gumpert",
    image: {
      source: "https://www.carlogos.org/logo/Gumpert-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/gumpert.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/gumpert.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/gumpert.jpg",
      localThumb: "./thumb/gumpert.png",
      localOptimized: "./optimized/gumpert.png",
      localOriginal: "./original/gumpert.jpg",
    },
  },
  {
    name: "Hafei",
    slug: "hafei",
    image: {
      source: "https://www.carlogos.org/logo/Hafei-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hafei.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hafei.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hafei.jpg",
      localThumb: "./thumb/hafei.png",
      localOptimized: "./optimized/hafei.png",
      localOriginal: "./original/hafei.jpg",
    },
  },
  {
    name: "Haval",
    slug: "haval",
    image: {
      source: "https://www.carlogos.org/logo/Haval-logo-640x112.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/haval.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/haval.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/haval.jpg",
      localThumb: "./thumb/haval.png",
      localOptimized: "./optimized/haval.png",
      localOriginal: "./original/haval.jpg",
    },
  },
  {
    name: "Haima",
    slug: "haima",
    image: {
      source: "https://www.carlogos.org/logo/Haima-logo-640x432.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/haima.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/haima.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/haima.jpg",
      localThumb: "./thumb/haima.png",
      localOptimized: "./optimized/haima.png",
      localOriginal: "./original/haima.jpg",
    },
  },
  {
    name: "Hawtai",
    slug: "hawtai",
    image: {
      source: "https://www.carlogos.org/logo/Hawtai-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hawtai.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hawtai.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hawtai.jpg",
      localThumb: "./thumb/hawtai.png",
      localOptimized: "./optimized/hawtai.png",
      localOriginal: "./original/hawtai.jpg",
    },
  },
  {
    name: "Hennessey",
    slug: "hennessey",
    image: {
      source: "https://www.carlogos.org/logo/Hennessey-text-logo-1186x130.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hennessey.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hennessey.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hennessey.png",
      localThumb: "./thumb/hennessey.png",
      localOptimized: "./optimized/hennessey.png",
      localOriginal: "./original/hennessey.png",
    },
  },
  {
    name: "Hillman",
    slug: "hillman",
    image: {
      source: "https://www.carlogos.org/logo/Marcos-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hillman.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hillman.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hillman.png",
      localThumb: "./thumb/hillman.png",
      localOptimized: "./optimized/hillman.png",
      localOriginal: "./original/hillman.png",
    },
  },
  {
    name: "Higer",
    slug: "higer",
    image: {
      source: "https://www.carlogos.org/car-logos/higer-logo-800x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/higer.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/higer.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/higer.png",
      localThumb: "./thumb/higer.png",
      localOptimized: "./optimized/higer.png",
      localOriginal: "./original/higer.png",
    },
  },
  {
    name: "Hino",
    slug: "hino",
    image: {
      source: "https://www.carlogos.org/logo/Hino-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hino.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hino.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hino.jpg",
      localThumb: "./thumb/hino.png",
      localOptimized: "./optimized/hino.png",
      localOriginal: "./original/hino.jpg",
    },
  },
  {
    name: "Hispano-Suiza",
    slug: "hispano-suiza",
    image: {
      source: "https://www.carlogos.org/logo/SEAT-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hispano-suiza.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hispano-suiza.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hispano-suiza.png",
      localThumb: "./thumb/hispano-suiza.png",
      localOptimized: "./optimized/hispano-suiza.png",
      localOriginal: "./original/hispano-suiza.png",
    },
  },
  {
    name: "Holden",
    slug: "holden",
    image: {
      source: "https://www.carlogos.org/logo/Holden-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/holden.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/holden.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/holden.jpg",
      localThumb: "./thumb/holden.png",
      localOptimized: "./optimized/holden.png",
      localOriginal: "./original/holden.jpg",
    },
  },
  {
    name: "Hommell",
    slug: "hommell",
    image: {
      source: "https://www.carlogos.org/logo/Hommell-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hommell.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hommell.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hommell.jpg",
      localThumb: "./thumb/hommell.png",
      localOptimized: "./optimized/hommell.png",
      localOriginal: "./original/hommell.jpg",
    },
  },
  {
    name: "Honda",
    slug: "honda",
    image: {
      source:
        "https://www.carlogos.org/car-logos/honda-logo-1700x1150-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/honda.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/honda.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/honda.png",
      localThumb: "./thumb/honda.png",
      localOptimized: "./optimized/honda.png",
      localOriginal: "./original/honda.png",
    },
  },
  {
    name: "Hongyan",
    slug: "hongyan",
    image: {
      source:
        "https://www.carlogos.org/car-logos/hongyan-logo-1200x1200-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hongyan.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hongyan.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hongyan.png",
      localThumb: "./thumb/hongyan.png",
      localOptimized: "./optimized/hongyan.png",
      localOriginal: "./original/hongyan.png",
    },
  },
  {
    name: "Horch",
    slug: "horch",
    image: {
      source: "https://www.carlogos.org/logo/Horch-logo-black-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/horch.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/horch.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/horch.jpg",
      localThumb: "./thumb/horch.png",
      localOptimized: "./optimized/horch.png",
      localOriginal: "./original/horch.jpg",
    },
  },
  {
    name: "Hongqi",
    slug: "hongqi",
    image: {
      source:
        "https://www.carlogos.org/car-logos/hongqi-logo-2018-800x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hongqi.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hongqi.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hongqi.png",
      localThumb: "./thumb/hongqi.png",
      localOptimized: "./optimized/hongqi.png",
      localOriginal: "./original/hongqi.png",
    },
  },
  {
    name: "HSV",
    slug: "hsv",
    image: {
      source: "https://www.carlogos.org/logo/HSV-logo-640x207.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hsv.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hsv.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hsv.jpg",
      localThumb: "./thumb/hsv.png",
      localOptimized: "./optimized/hsv.png",
      localOriginal: "./original/hsv.jpg",
    },
  },
  {
    name: "Hudson",
    slug: "hudson",
    image: {
      source: "https://www.carlogos.org/logo/Hudson-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hudson.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hudson.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hudson.jpg",
      localThumb: "./thumb/hudson.png",
      localOptimized: "./optimized/hudson.png",
      localOriginal: "./original/hudson.jpg",
    },
  },
  {
    name: "Hummer",
    slug: "hummer",
    image: {
      source: "https://www.carlogos.org/logo/Hummer-logo-2000x205.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hummer.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hummer.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hummer.png",
      localThumb: "./thumb/hummer.png",
      localOptimized: "./optimized/hummer.png",
      localOriginal: "./original/hummer.png",
    },
  },
  {
    name: "Hupmobile",
    slug: "hupmobile",
    image: {
      source: "https://www.carlogos.org/logo/Pierce-Arrow-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hupmobile.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hupmobile.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hupmobile.png",
      localThumb: "./thumb/hupmobile.png",
      localOptimized: "./optimized/hupmobile.png",
      localOriginal: "./original/hupmobile.png",
    },
  },
  {
    name: "Hyundai",
    slug: "hyundai",
    image: {
      source: "https://www.carlogos.org/logo/Hyundai-logo-silver-640x401.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/hyundai.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/hyundai.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/hyundai.jpg",
      localThumb: "./thumb/hyundai.png",
      localOptimized: "./optimized/hyundai.png",
      localOriginal: "./original/hyundai.jpg",
    },
  },
  {
    name: "IC Bus",
    slug: "ic-bus",
    image: {
      source: "https://www.carlogos.org/logo/IC-Bus-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ic-bus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ic-bus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ic-bus.jpg",
      localThumb: "./thumb/ic-bus.png",
      localOptimized: "./optimized/ic-bus.png",
      localOriginal: "./original/ic-bus.jpg",
    },
  },
  {
    name: "Infiniti",
    slug: "infiniti",
    image: {
      source: "https://www.carlogos.org/logo/Infiniti-logo-1989-640x308.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/infiniti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/infiniti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/infiniti.jpg",
      localThumb: "./thumb/infiniti.png",
      localOptimized: "./optimized/infiniti.png",
      localOriginal: "./original/infiniti.jpg",
    },
  },
  {
    name: "Intermeccanica",
    slug: "intermeccanica",
    image: {
      source: "https://www.carlogos.org/logo/Autobianchi-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/intermeccanica.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/intermeccanica.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/intermeccanica.png",
      localThumb: "./thumb/intermeccanica.png",
      localOptimized: "./optimized/intermeccanica.png",
      localOriginal: "./original/intermeccanica.png",
    },
  },
  {
    name: "Innocenti",
    slug: "innocenti",
    image: {
      source: "https://www.carlogos.org/logo/Bizzarrini-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/innocenti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/innocenti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/innocenti.png",
      localThumb: "./thumb/innocenti.png",
      localOptimized: "./optimized/innocenti.png",
      localOriginal: "./original/innocenti.png",
    },
  },
  {
    name: "IH",
    slug: "ih",
    image: {
      source:
        "https://www.carlogos.org/logo/International-Harvester-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ih.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ih.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ih.jpg",
      localThumb: "./thumb/ih.png",
      localOptimized: "./optimized/ih.png",
      localOriginal: "./original/ih.jpg",
    },
  },
  {
    name: "International",
    slug: "international",
    image: {
      source:
        "https://www.carlogos.org/logo/International-Trucks-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/international.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/international.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/international.jpg",
      localThumb: "./thumb/international.png",
      localOptimized: "./optimized/international.png",
      localOriginal: "./original/international.jpg",
    },
  },
  {
    name: "IKCO",
    slug: "ikco",
    image: {
      source: "https://www.carlogos.org/logo/Iran-Khodro-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ikco.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ikco.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ikco.jpg",
      localThumb: "./thumb/ikco.png",
      localOptimized: "./optimized/ikco.png",
      localOriginal: "./original/ikco.jpg",
    },
  },
  {
    name: "Irizar",
    slug: "irizar",
    image: {
      source: "https://www.carlogos.org/logo/Irizar-logo-640x189.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/irizar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/irizar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/irizar.jpg",
      localThumb: "./thumb/irizar.png",
      localOptimized: "./optimized/irizar.png",
      localOriginal: "./original/irizar.jpg",
    },
  },
  {
    name: "Isdera",
    slug: "isdera",
    image: {
      source: "https://www.carlogos.org/logo/EDAG-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/isdera.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/isdera.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/isdera.png",
      localThumb: "./thumb/isdera.png",
      localOptimized: "./optimized/isdera.png",
      localOriginal: "./original/isdera.png",
    },
  },
  {
    name: "Iso",
    slug: "iso",
    image: {
      source: "https://www.carlogos.org/logo/Iso-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/iso.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/iso.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/iso.jpg",
      localThumb: "./thumb/iso.png",
      localOptimized: "./optimized/iso.png",
      localOriginal: "./original/iso.jpg",
    },
  },
  {
    name: "Isuzu",
    slug: "isuzu",
    image: {
      source: "https://www.carlogos.org/logo/Isuzu-logo-1991-640x106.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/isuzu.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/isuzu.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/isuzu.jpg",
      localThumb: "./thumb/isuzu.png",
      localOptimized: "./optimized/isuzu.png",
      localOriginal: "./original/isuzu.jpg",
    },
  },
  {
    name: "JAC",
    slug: "jac",
    image: {
      source: "https://www.carlogos.org/logo/JAC-Motors-logo-2016-640x244.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jac.jpg",
      localThumb: "./thumb/jac.png",
      localOptimized: "./optimized/jac.png",
      localOriginal: "./original/jac.jpg",
    },
  },
  {
    name: "Jaguar",
    slug: "jaguar",
    image: {
      source: "https://www.carlogos.org/logo/Jaguar-logo-2012-640x287.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jaguar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jaguar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jaguar.jpg",
      localThumb: "./thumb/jaguar.png",
      localOptimized: "./optimized/jaguar.png",
      localOriginal: "./original/jaguar.jpg",
    },
  },
  {
    name: "Iveco",
    slug: "iveco",
    image: {
      source: "https://www.carlogos.org/logo/Iveco-logo-silver-640x122.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/iveco.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/iveco.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/iveco.jpg",
      localThumb: "./thumb/iveco.png",
      localOptimized: "./optimized/iveco.png",
      localOriginal: "./original/iveco.jpg",
    },
  },
  {
    name: "Jawa",
    slug: "jawa",
    image: {
      source: "https://www.carlogos.org/logo/Skoda-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jawa.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jawa.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jawa.png",
      localThumb: "./thumb/jawa.png",
      localOptimized: "./optimized/jawa.png",
      localOriginal: "./original/jawa.png",
    },
  },
  {
    name: "JBA Motors",
    slug: "jba-motors",
    image: {
      source: "https://www.carlogos.org/logo/JBA-Motors-logo-640x205.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jba-motors.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jba-motors.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jba-motors.jpg",
      localThumb: "./thumb/jba-motors.png",
      localOptimized: "./optimized/jba-motors.png",
      localOriginal: "./original/jba-motors.jpg",
    },
  },
  {
    name: "Jeep",
    slug: "jeep",
    image: {
      source: "https://www.carlogos.org/logo/Jeep-logo-green-640x258.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jeep.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jeep.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jeep.jpg",
      localThumb: "./thumb/jeep.png",
      localOptimized: "./optimized/jeep.png",
      localOriginal: "./original/jeep.jpg",
    },
  },
  {
    name: "Jetta",
    slug: "jetta",
    image: {
      source:
        "https://www.carlogos.org/car-logos/jetta-logo-2019-1300x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jetta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jetta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jetta.png",
      localThumb: "./thumb/jetta.png",
      localOptimized: "./optimized/jetta.png",
      localOriginal: "./original/jetta.png",
    },
  },
  {
    name: "Jensen",
    slug: "jensen",
    image: {
      source: "https://www.carlogos.org/logo/Bristol-Cars-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jensen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jensen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jensen.png",
      localThumb: "./thumb/jensen.png",
      localOptimized: "./optimized/jensen.png",
      localOriginal: "./original/jensen.png",
    },
  },
  {
    name: "JMC",
    slug: "jmc",
    image: {
      source: "https://www.carlogos.org/logo/Jiangling-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/jmc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/jmc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/jmc.jpg",
      localThumb: "./thumb/jmc.png",
      localOptimized: "./optimized/jmc.png",
      localOriginal: "./original/jmc.jpg",
    },
  },
  {
    name: "Kaiser",
    slug: "kaiser",
    image: {
      source: "https://www.carlogos.org/logo/Auburn-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/kaiser.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/kaiser.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/kaiser.png",
      localThumb: "./thumb/kaiser.png",
      localOptimized: "./optimized/kaiser.png",
      localOriginal: "./original/kaiser.png",
    },
  },
  {
    name: "Kamaz",
    slug: "kamaz",
    image: {
      source: "https://www.carlogos.org/logo/Kamaz-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/kamaz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/kamaz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/kamaz.jpg",
      localThumb: "./thumb/kamaz.png",
      localOptimized: "./optimized/kamaz.png",
      localOriginal: "./original/kamaz.jpg",
    },
  },
  {
    name: "Karma",
    slug: "karma",
    image: {
      source: "https://www.carlogos.org/logo/Karma-logo-640x400.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/karma.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/karma.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/karma.jpg",
      localThumb: "./thumb/karma.png",
      localOptimized: "./optimized/karma.png",
      localOriginal: "./original/karma.jpg",
    },
  },
  {
    name: "Karlmann King",
    slug: "karlmann-king",
    image: {
      source:
        "https://www.carlogos.org/car-logos/karlmann-king-1400x1000-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/karlmann-king.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/karlmann-king.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/karlmann-king.png",
      localThumb: "./thumb/karlmann-king.png",
      localOptimized: "./optimized/karlmann-king.png",
      localOriginal: "./original/karlmann-king.png",
    },
  },
  {
    name: "Keating",
    slug: "keating",
    image: {
      source:
        "https://www.carlogos.org/logo/Keating-Supercars-logo-640x300.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/keating.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/keating.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/keating.jpg",
      localThumb: "./thumb/keating.png",
      localOptimized: "./optimized/keating.png",
      localOriginal: "./original/keating.jpg",
    },
  },
  {
    name: "Kenworth",
    slug: "kenworth",
    image: {
      source: "https://www.carlogos.org/logo/Kenworth-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/kenworth.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/kenworth.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/kenworth.jpg",
      localThumb: "./thumb/kenworth.png",
      localOptimized: "./optimized/kenworth.png",
      localOriginal: "./original/kenworth.jpg",
    },
  },
  {
    name: "King Long",
    slug: "king-long",
    image: {
      source:
        "https://www.carlogos.org/car-logos/king-long-logo-3900x800-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/king-long.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/king-long.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/king-long.png",
      localThumb: "./thumb/king-long.png",
      localOptimized: "./optimized/king-long.png",
      localOriginal: "./original/king-long.png",
    },
  },
  {
    name: "Kia",
    slug: "kia",
    image: {
      source: "https://www.carlogos.org/logo/Kia-logo-640x321.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/kia.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/kia.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/kia.jpg",
      localThumb: "./thumb/kia.png",
      localOptimized: "./optimized/kia.png",
      localOriginal: "./original/kia.jpg",
    },
  },
  {
    name: "Koenigsegg",
    slug: "koenigsegg",
    image: {
      source: "https://www.carlogos.org/logo/Koenigsegg-logo-1994-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/koenigsegg.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/koenigsegg.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/koenigsegg.jpg",
      localThumb: "./thumb/koenigsegg.png",
      localOptimized: "./optimized/koenigsegg.png",
      localOriginal: "./original/koenigsegg.jpg",
    },
  },
  {
    name: "KTM",
    slug: "ktm",
    image: {
      source: "https://www.carlogos.org/logo/KTM-logo-640x200.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ktm.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ktm.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ktm.jpg",
      localThumb: "./thumb/ktm.png",
      localOptimized: "./optimized/ktm.png",
      localOriginal: "./original/ktm.jpg",
    },
  },
  {
    name: "Lada",
    slug: "lada",
    image: {
      source: "https://www.carlogos.org/logo/Lada-logo-silver-640x248.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lada.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lada.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lada.jpg",
      localThumb: "./thumb/lada.png",
      localOptimized: "./optimized/lada.png",
      localOriginal: "./original/lada.jpg",
    },
  },
  {
    name: "Lagonda",
    slug: "lagonda",
    image: {
      source: "https://www.carlogos.org/logo/Lagonda-logo-2014-640x190.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lagonda.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lagonda.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lagonda.jpg",
      localThumb: "./thumb/lagonda.png",
      localOptimized: "./optimized/lagonda.png",
      localOriginal: "./original/lagonda.jpg",
    },
  },
  {
    name: "Lamborghini",
    slug: "lamborghini",
    image: {
      source:
        "https://www.carlogos.org/car-logos/lamborghini-logo-1000x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lamborghini.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lamborghini.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lamborghini.png",
      localThumb: "./thumb/lamborghini.png",
      localOptimized: "./optimized/lamborghini.png",
      localOriginal: "./original/lamborghini.png",
    },
  },
  {
    name: "Lancia",
    slug: "lancia",
    image: {
      source: "https://www.carlogos.org/logo/Lancia-logo-2007-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lancia.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lancia.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lancia.jpg",
      localThumb: "./thumb/lancia.png",
      localOptimized: "./optimized/lancia.png",
      localOriginal: "./original/lancia.jpg",
    },
  },
  {
    name: "Land Rover",
    slug: "land-rover",
    image: {
      source: "https://www.carlogos.org/logo/Land-Rover-logo-2011-640x335.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/land-rover.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/land-rover.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/land-rover.jpg",
      localThumb: "./thumb/land-rover.png",
      localOptimized: "./optimized/land-rover.png",
      localOriginal: "./original/land-rover.jpg",
    },
  },
  {
    name: "Landwind",
    slug: "landwind",
    image: {
      source: "https://www.carlogos.org/logo/Landwind-logo-640x154.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/landwind.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/landwind.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/landwind.jpg",
      localThumb: "./thumb/landwind.png",
      localOptimized: "./optimized/landwind.png",
      localOriginal: "./original/landwind.jpg",
    },
  },
  {
    name: "Laraki",
    slug: "laraki",
    image: {
      source: "https://www.carlogos.org/logo/Laraki-logo-640x273.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/laraki.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/laraki.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/laraki.jpg",
      localThumb: "./thumb/laraki.png",
      localOptimized: "./optimized/laraki.png",
      localOriginal: "./original/laraki.jpg",
    },
  },
  {
    name: "Lexus",
    slug: "lexus",
    image: {
      source: "https://www.carlogos.org/logo/Lexus-logo-1988-640x266.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lexus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lexus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lexus.jpg",
      localThumb: "./thumb/lexus.png",
      localOptimized: "./optimized/lexus.png",
      localOriginal: "./original/lexus.jpg",
    },
  },
  {
    name: "Li Auto",
    slug: "li-auto",
    image: {
      source:
        "https://www.carlogos.org/car-logos/lixiang-logo-1050x300-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/li-auto.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/li-auto.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/li-auto.png",
      localThumb: "./thumb/li-auto.png",
      localOptimized: "./optimized/li-auto.png",
      localOriginal: "./original/li-auto.png",
    },
  },
  {
    name: "Leyland",
    slug: "leyland",
    image: {
      source: "https://www.carlogos.org/logo/Leyland-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/leyland.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/leyland.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/leyland.jpg",
      localThumb: "./thumb/leyland.png",
      localOptimized: "./optimized/leyland.png",
      localOriginal: "./original/leyland.jpg",
    },
  },
  {
    name: "Lifan",
    slug: "lifan",
    image: {
      source: "https://www.carlogos.org/logo/Lifan-logo-640x116.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lifan.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lifan.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lifan.jpg",
      localThumb: "./thumb/lifan.png",
      localOptimized: "./optimized/lifan.png",
      localOriginal: "./original/lifan.jpg",
    },
  },
  {
    name: "Ligier",
    slug: "ligier",
    image: {
      source: "https://www.carlogos.org/logo/Ligier-logo-640x392.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ligier.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ligier.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ligier.jpg",
      localThumb: "./thumb/ligier.png",
      localOptimized: "./optimized/ligier.png",
      localOriginal: "./original/ligier.jpg",
    },
  },
  {
    name: "Lincoln",
    slug: "lincoln",
    image: {
      source: "https://www.carlogos.org/logo/Lincoln-logo-2019-640x222.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lincoln.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lincoln.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lincoln.jpg",
      localThumb: "./thumb/lincoln.png",
      localOptimized: "./optimized/lincoln.png",
      localOriginal: "./original/lincoln.jpg",
    },
  },
  {
    name: "Lister",
    slug: "lister",
    image: {
      source: "https://www.carlogos.org/logo/Lister-Cars-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lister.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lister.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lister.jpg",
      localThumb: "./thumb/lister.png",
      localOptimized: "./optimized/lister.png",
      localOriginal: "./original/lister.jpg",
    },
  },
  {
    name: "Lloyd",
    slug: "lloyd",
    image: {
      source: "https://www.carlogos.org/logo/Lloyd-logo-640x458.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lloyd.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lloyd.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lloyd.jpg",
      localThumb: "./thumb/lloyd.png",
      localOptimized: "./optimized/lloyd.png",
      localOriginal: "./original/lloyd.jpg",
    },
  },
  {
    name: "Lobini",
    slug: "lobini",
    image: {
      source: "https://www.carlogos.org/logo/Lobini-logo-640x304.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lobini.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lobini.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lobini.jpg",
      localThumb: "./thumb/lobini.png",
      localOptimized: "./optimized/lobini.png",
      localOriginal: "./original/lobini.jpg",
    },
  },
  {
    name: "LEVC",
    slug: "levc",
    image: {
      source:
        "https://www.carlogos.org/logo/London-EV-Company-logo-640x299.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/levc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/levc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/levc.jpg",
      localThumb: "./thumb/levc.png",
      localOptimized: "./optimized/levc.png",
      localOriginal: "./original/levc.jpg",
    },
  },
  {
    name: "Lordstown",
    slug: "lordstown",
    image: {
      source:
        "https://www.carlogos.org/car-logos/lordstown-logo-2150x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lordstown.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lordstown.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lordstown.png",
      localThumb: "./thumb/lordstown.png",
      localOptimized: "./optimized/lordstown.png",
      localOriginal: "./original/lordstown.png",
    },
  },
  {
    name: "Lucid",
    slug: "lucid",
    image: {
      source: "https://www.carlogos.org/logo/Lucid-Motors-logo-640x36.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lucid.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lucid.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lucid.jpg",
      localThumb: "./thumb/lucid.png",
      localOptimized: "./optimized/lucid.png",
      localOriginal: "./original/lucid.jpg",
    },
  },
  {
    name: "Luxgen",
    slug: "luxgen",
    image: {
      source: "https://www.carlogos.org/logo/Luxgen-logo-2009-640x391.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/luxgen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/luxgen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/luxgen.jpg",
      localThumb: "./thumb/luxgen.png",
      localOptimized: "./optimized/luxgen.png",
      localOriginal: "./original/luxgen.jpg",
    },
  },
  {
    name: "Lotus",
    slug: "lotus",
    image: {
      source: "https://www.carlogos.org/logo/Lotus-logo-2019-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lotus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lotus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lotus.jpg",
      localThumb: "./thumb/lotus.png",
      localOptimized: "./optimized/lotus.png",
      localOriginal: "./original/lotus.jpg",
    },
  },
  {
    name: "Lynk & Co",
    slug: "lynk-and-co",
    image: {
      source:
        "https://www.carlogos.org/car-logos/lynkco-logo-2150x400-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/lynk-and-co.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/lynk-and-co.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/lynk-and-co.png",
      localThumb: "./thumb/lynk-and-co.png",
      localOptimized: "./optimized/lynk-and-co.png",
      localOriginal: "./original/lynk-and-co.png",
    },
  },
  {
    name: "Mack",
    slug: "mack",
    image: {
      source: "https://www.carlogos.org/logo/Mack-logo-2014-640x314.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mack.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mack.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mack.jpg",
      localThumb: "./thumb/mack.png",
      localOptimized: "./optimized/mack.png",
      localOriginal: "./original/mack.jpg",
    },
  },
  {
    name: "Mahindra",
    slug: "mahindra",
    image: {
      source: "https://www.carlogos.org/logo/Mahindra-logo-640x316.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mahindra.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mahindra.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mahindra.jpg",
      localThumb: "./thumb/mahindra.png",
      localOptimized: "./optimized/mahindra.png",
      localOriginal: "./original/mahindra.jpg",
    },
  },
  {
    name: "Mansory",
    slug: "mansory",
    image: {
      source: "https://www.carlogos.org/logo/Mansory-logo-640x60.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mansory.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mansory.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mansory.jpg",
      localThumb: "./thumb/mansory.png",
      localOptimized: "./optimized/mansory.png",
      localOriginal: "./original/mansory.jpg",
    },
  },
  {
    name: "MAN",
    slug: "man",
    image: {
      source: "https://www.carlogos.org/logo/MAN-logo-640x355.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/man.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/man.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/man.jpg",
      localThumb: "./thumb/man.png",
      localOptimized: "./optimized/man.png",
      localOriginal: "./original/man.jpg",
    },
  },
  {
    name: "Marcos",
    slug: "marcos",
    image: {
      source: "https://www.carlogos.org/logo/Ascari-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/marcos.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/marcos.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/marcos.png",
      localThumb: "./thumb/marcos.png",
      localOptimized: "./optimized/marcos.png",
      localOriginal: "./original/marcos.png",
    },
  },
  {
    name: "Marlin",
    slug: "marlin",
    image: {
      source: "https://www.carlogos.org/logo/Marlin-car-logo-640x324.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/marlin.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/marlin.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/marlin.jpg",
      localThumb: "./thumb/marlin.png",
      localOptimized: "./optimized/marlin.png",
      localOriginal: "./original/marlin.jpg",
    },
  },
  {
    name: "Maserati",
    slug: "maserati",
    image: {
      source: "https://www.carlogos.org/logo/Maserati-logo-black-640x280.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/maserati.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/maserati.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/maserati.jpg",
      localThumb: "./thumb/maserati.png",
      localOptimized: "./optimized/maserati.png",
      localOriginal: "./original/maserati.jpg",
    },
  },
  {
    name: "Mastretta",
    slug: "mastretta",
    image: {
      source: "https://www.carlogos.org/logo/Mastretta-logo-old-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mastretta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mastretta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mastretta.jpg",
      localThumb: "./thumb/mastretta.png",
      localOptimized: "./optimized/mastretta.png",
      localOriginal: "./original/mastretta.jpg",
    },
  },
  {
    name: "Maxus",
    slug: "maxus",
    image: {
      source: "https://www.carlogos.org/logo/Maxus-logo-2014-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/maxus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/maxus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/maxus.jpg",
      localThumb: "./thumb/maxus.png",
      localOptimized: "./optimized/maxus.png",
      localOriginal: "./original/maxus.jpg",
    },
  },
  {
    name: "Maybach",
    slug: "maybach",
    image: {
      source: "https://www.carlogos.org/logo/Maybach-logo-640x353.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/maybach.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/maybach.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/maybach.jpg",
      localThumb: "./thumb/maybach.png",
      localOptimized: "./optimized/maybach.png",
      localOriginal: "./original/maybach.jpg",
    },
  },
  {
    name: "MAZ",
    slug: "maz",
    image: {
      source: "https://www.carlogos.org/logo/MAZ-logo-640x377.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/maz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/maz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/maz.jpg",
      localThumb: "./thumb/maz.png",
      localOptimized: "./optimized/maz.png",
      localOriginal: "./original/maz.jpg",
    },
  },
  {
    name: "Mazda",
    slug: "mazda",
    image: {
      source: "https://www.carlogos.org/logo/Mazda-logo-1997-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mazda.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mazda.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mazda.jpg",
      localThumb: "./thumb/mazda.png",
      localOptimized: "./optimized/mazda.png",
      localOriginal: "./original/mazda.jpg",
    },
  },
  {
    name: "McLaren",
    slug: "mclaren",
    image: {
      source: "https://www.carlogos.org/logo/McLaren-logo-2002-640x92.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mclaren.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mclaren.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mclaren.jpg",
      localThumb: "./thumb/mclaren.png",
      localOptimized: "./optimized/mclaren.png",
      localOriginal: "./original/mclaren.jpg",
    },
  },
  {
    name: "Melkus",
    slug: "melkus",
    image: {
      source: "https://www.carlogos.org/logo/Melkus-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/melkus.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/melkus.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/melkus.jpg",
      localThumb: "./thumb/melkus.png",
      localOptimized: "./optimized/melkus.png",
      localOriginal: "./original/melkus.jpg",
    },
  },
  {
    name: "Mercedes-AMG",
    slug: "mercedes-amg",
    image: {
      source: "https://www.carlogos.org/logo/AMG-logo-black-640x62.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mercedes-amg.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mercedes-amg.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mercedes-amg.jpg",
      localThumb: "./thumb/mercedes-amg.png",
      localOptimized: "./optimized/mercedes-amg.png",
      localOriginal: "./original/mercedes-amg.jpg",
    },
  },
  {
    name: "Mazzanti",
    slug: "mazzanti",
    image: {
      source:
        "https://www.carlogos.org/logo/Mazzanti-Automobili-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mazzanti.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mazzanti.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mazzanti.jpg",
      localThumb: "./thumb/mazzanti.png",
      localOptimized: "./optimized/mazzanti.png",
      localOriginal: "./original/mazzanti.jpg",
    },
  },
  {
    name: "Mercedes-Benz",
    slug: "mercedes-benz",
    image: {
      source:
        "https://www.carlogos.org/logo/Mercedes-Benz-logo-2011-640x369.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mercedes-benz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mercedes-benz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mercedes-benz.jpg",
      localThumb: "./thumb/mercedes-benz.png",
      localOptimized: "./optimized/mercedes-benz.png",
      localOriginal: "./original/mercedes-benz.jpg",
    },
  },
  {
    name: "Mercury",
    slug: "mercury",
    image: {
      source: "https://www.carlogos.org/logo/Mercury-logo-1980-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mercury.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mercury.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mercury.jpg",
      localThumb: "./thumb/mercury.png",
      localOptimized: "./optimized/mercury.png",
      localOriginal: "./original/mercury.jpg",
    },
  },
  {
    name: "MEV",
    slug: "mev",
    image: {
      source:
        "https://www.carlogos.org/logo/Mills-Extreme-Vehicles-logo-640x186.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mev.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mev.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mev.jpg",
      localThumb: "./thumb/mev.png",
      localOptimized: "./optimized/mev.png",
      localOriginal: "./original/mev.jpg",
    },
  },
  {
    name: "Merkur",
    slug: "merkur",
    image: {
      source: "https://www.carlogos.org/logo/Merkur-logo-black-640x294.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/merkur.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/merkur.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/merkur.jpg",
      localThumb: "./thumb/merkur.png",
      localOptimized: "./optimized/merkur.png",
      localOriginal: "./original/merkur.jpg",
    },
  },
  {
    name: "MG",
    slug: "mg",
    image: {
      source: "https://www.carlogos.org/logo/MG-logo-red-2010-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mg.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mg.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mg.jpg",
      localThumb: "./thumb/mg.png",
      localOptimized: "./optimized/mg.png",
      localOriginal: "./original/mg.jpg",
    },
  },
  {
    name: "Microcar",
    slug: "microcar",
    image: {
      source: "https://www.carlogos.org/logo/Microcar-logo-640x330.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/microcar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/microcar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/microcar.jpg",
      localThumb: "./thumb/microcar.png",
      localOptimized: "./optimized/microcar.png",
      localOriginal: "./original/microcar.jpg",
    },
  },
  {
    name: "Mini",
    slug: "mini",
    image: {
      source: "https://www.carlogos.org/logo/Mini-logo-2001-640x270.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mini.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mini.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mini.jpg",
      localThumb: "./thumb/mini.png",
      localOptimized: "./optimized/mini.png",
      localOriginal: "./original/mini.jpg",
    },
  },
  {
    name: "Mitsubishi",
    slug: "mitsubishi",
    image: {
      source: "https://www.carlogos.org/logo/Mitsubishi-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mitsubishi.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mitsubishi.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mitsubishi.jpg",
      localThumb: "./thumb/mitsubishi.png",
      localOptimized: "./optimized/mitsubishi.png",
      localOriginal: "./original/mitsubishi.jpg",
    },
  },
  {
    name: "Mitsuoka",
    slug: "mitsuoka",
    image: {
      source: "https://www.carlogos.org/logo/Mitsuoka-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mitsuoka.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mitsuoka.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mitsuoka.jpg",
      localThumb: "./thumb/mitsuoka.png",
      localOptimized: "./optimized/mitsuoka.png",
      localOriginal: "./original/mitsuoka.jpg",
    },
  },
  {
    name: "MK",
    slug: "mk",
    image: {
      source:
        "https://www.carlogos.org/car-logos/mk-sportscars-logo-1000x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mk.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mk.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mk.png",
      localThumb: "./thumb/mk.png",
      localOptimized: "./optimized/mk.png",
      localOriginal: "./original/mk.png",
    },
  },
  {
    name: "Morgan",
    slug: "morgan",
    image: {
      source: "https://www.carlogos.org/logo/Morgan-logo-640x178.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/morgan.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/morgan.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/morgan.jpg",
      localThumb: "./thumb/morgan.png",
      localOptimized: "./optimized/morgan.png",
      localOriginal: "./original/morgan.jpg",
    },
  },
  {
    name: "Morris",
    slug: "morris",
    image: {
      source: "https://www.carlogos.org/logo/MG-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/morris.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/morris.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/morris.png",
      localThumb: "./thumb/morris.png",
      localOptimized: "./optimized/morris.png",
      localOriginal: "./original/morris.png",
    },
  },
  {
    name: "Mosler",
    slug: "mosler",
    image: {
      source: "https://www.carlogos.org/logo/Saleen-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/mosler.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/mosler.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/mosler.png",
      localThumb: "./thumb/mosler.png",
      localOptimized: "./optimized/mosler.png",
      localOriginal: "./original/mosler.png",
    },
  },
  {
    name: "Navistar",
    slug: "navistar",
    image: {
      source:
        "https://www.carlogos.org/logo/Navistar-International-logo-640x104.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/navistar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/navistar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/navistar.jpg",
      localThumb: "./thumb/navistar.png",
      localOptimized: "./optimized/navistar.png",
      localOriginal: "./original/navistar.jpg",
    },
  },
  {
    name: "NEVS",
    slug: "nevs",
    image: {
      source: "https://www.carlogos.org/car-logos/nevs-logo-2100x450-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nevs.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nevs.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nevs.png",
      localThumb: "./thumb/nevs.png",
      localOptimized: "./optimized/nevs.png",
      localOriginal: "./original/nevs.png",
    },
  },
  {
    name: "NIO",
    slug: "nio",
    image: {
      source: "https://www.carlogos.org/car-logos/nio-logo-1800x700-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nio.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nio.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nio.png",
      localThumb: "./thumb/nio.png",
      localOptimized: "./optimized/nio.png",
      localOriginal: "./original/nio.png",
    },
  },
  {
    name: "Nikola",
    slug: "nikola",
    image: {
      source:
        "https://www.carlogos.org/car-logos/nikola-logo-2100x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nikola.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nikola.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nikola.png",
      localThumb: "./thumb/nikola.png",
      localOptimized: "./optimized/nikola.png",
      localOriginal: "./original/nikola.png",
    },
  },
  {
    name: "Nissan Nismo",
    slug: "nissan-nismo",
    image: {
      source: "https://www.carlogos.org/logo/Nismo-logo-640x79.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nissan-nismo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nissan-nismo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nissan-nismo.jpg",
      localThumb: "./thumb/nissan-nismo.png",
      localOptimized: "./optimized/nissan-nismo.png",
      localOriginal: "./original/nissan-nismo.jpg",
    },
  },
  {
    name: "Nissan",
    slug: "nissan",
    image: {
      source:
        "https://www.carlogos.org/car-logos/nissan-logo-2020-black-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nissan.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nissan.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nissan.png",
      localThumb: "./thumb/nissan.png",
      localOptimized: "./optimized/nissan.png",
      localOriginal: "./original/nissan.png",
    },
  },
  {
    name: "Nissan GT-R",
    slug: "nissan-gt-r",
    image: {
      source: "https://www.carlogos.org/logo/GT-R-logo-640x454.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/nissan-gt-r.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/nissan-gt-r.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/nissan-gt-r.jpg",
      localThumb: "./thumb/nissan-gt-r.png",
      localOptimized: "./optimized/nissan-gt-r.png",
      localOriginal: "./original/nissan-gt-r.jpg",
    },
  },
  {
    name: "Oldsmobile",
    slug: "oldsmobile",
    image: {
      source: "https://www.carlogos.org/logo/Oldsmobile-logo-1996-640x260.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/oldsmobile.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/oldsmobile.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/oldsmobile.jpg",
      localThumb: "./thumb/oldsmobile.png",
      localOptimized: "./optimized/oldsmobile.png",
      localOriginal: "./original/oldsmobile.jpg",
    },
  },
  {
    name: "Oltcit",
    slug: "oltcit",
    image: {
      source: "https://www.carlogos.org/logo/ARO-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/oltcit.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/oltcit.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/oltcit.png",
      localThumb: "./thumb/oltcit.png",
      localOptimized: "./optimized/oltcit.png",
      localOriginal: "./original/oltcit.png",
    },
  },
  {
    name: "Noble",
    slug: "noble",
    image: {
      source: "https://www.carlogos.org/logo/Noble-logo-640x375.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/noble.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/noble.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/noble.jpg",
      localThumb: "./thumb/noble.png",
      localOptimized: "./optimized/noble.png",
      localOriginal: "./original/noble.jpg",
    },
  },
  {
    name: "OSCA",
    slug: "osca",
    image: {
      source: "https://www.carlogos.org/logo/Innocenti-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/osca.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/osca.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/osca.png",
      localThumb: "./thumb/osca.png",
      localOptimized: "./optimized/osca.png",
      localOriginal: "./original/osca.png",
    },
  },
  {
    name: "Opel",
    slug: "opel",
    image: {
      source: "https://www.carlogos.org/logo/Opel-logo-2009-640x496.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/opel.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/opel.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/opel.jpg",
      localThumb: "./thumb/opel.png",
      localOptimized: "./optimized/opel.png",
      localOriginal: "./original/opel.jpg",
    },
  },
  {
    name: "Paccar",
    slug: "paccar",
    image: {
      source: "https://www.carlogos.org/logo/Paccar-logo-640x110.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/paccar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/paccar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/paccar.jpg",
      localThumb: "./thumb/paccar.png",
      localOptimized: "./optimized/paccar.png",
      localOriginal: "./original/paccar.jpg",
    },
  },
  {
    name: "Pagani",
    slug: "pagani",
    image: {
      source: "https://www.carlogos.org/logo/Pagani-logo-1992-640x343.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pagani.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pagani.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pagani.jpg",
      localThumb: "./thumb/pagani.png",
      localOptimized: "./optimized/pagani.png",
      localOriginal: "./original/pagani.jpg",
    },
  },
  {
    name: "Panhard",
    slug: "panhard",
    image: {
      source: "https://www.carlogos.org/logo/Facel-Vega-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/panhard.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/panhard.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/panhard.png",
      localThumb: "./thumb/panhard.png",
      localOptimized: "./optimized/panhard.png",
      localOriginal: "./original/panhard.png",
    },
  },
  {
    name: "Panoz",
    slug: "panoz",
    image: {
      source: "https://www.carlogos.org/logo/Panoz-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/panoz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/panoz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/panoz.jpg",
      localThumb: "./thumb/panoz.png",
      localOptimized: "./optimized/panoz.png",
      localOriginal: "./original/panoz.jpg",
    },
  },
  {
    name: "Packard",
    slug: "packard",
    image: {
      source:
        "https://www.carlogos.org/car-logos/packard-logo-650x650-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/packard.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/packard.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/packard.png",
      localThumb: "./thumb/packard.png",
      localOptimized: "./optimized/packard.png",
      localOriginal: "./original/packard.png",
    },
  },
  {
    name: "Pegaso",
    slug: "pegaso",
    image: {
      source: "https://www.carlogos.org/logo/Pegaso-logo-black-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pegaso.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pegaso.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pegaso.jpg",
      localThumb: "./thumb/pegaso.png",
      localOptimized: "./optimized/pegaso.png",
      localOriginal: "./original/pegaso.jpg",
    },
  },
  {
    name: "Perodua",
    slug: "perodua",
    image: {
      source: "https://www.carlogos.org/logo/Perodua-logo-2008-640x434.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/perodua.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/perodua.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/perodua.jpg",
      localThumb: "./thumb/perodua.png",
      localOptimized: "./optimized/perodua.png",
      localOriginal: "./original/perodua.jpg",
    },
  },
  {
    name: "Peterbilt",
    slug: "peterbilt",
    image: {
      source: "https://www.carlogos.org/logo/Peterbilt-logo-640x325.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/peterbilt.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/peterbilt.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/peterbilt.jpg",
      localThumb: "./thumb/peterbilt.png",
      localOptimized: "./optimized/peterbilt.png",
      localOriginal: "./original/peterbilt.jpg",
    },
  },
  {
    name: "Peugeot",
    slug: "peugeot",
    image: {
      source: "https://www.carlogos.org/logo/Peugeot-logo-2010-640x451.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/peugeot.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/peugeot.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/peugeot.jpg",
      localThumb: "./thumb/peugeot.png",
      localOptimized: "./optimized/peugeot.png",
      localOriginal: "./original/peugeot.jpg",
    },
  },
  {
    name: "PGO",
    slug: "pgo",
    image: {
      source: "https://www.carlogos.org/logo/PGO-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pgo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pgo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pgo.jpg",
      localThumb: "./thumb/pgo.png",
      localOptimized: "./optimized/pgo.png",
      localOriginal: "./original/pgo.jpg",
    },
  },
  {
    name: "Pierce-Arrow",
    slug: "pierce-arrow",
    image: {
      source: "https://www.carlogos.org/logo/Cole-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pierce-arrow.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pierce-arrow.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pierce-arrow.png",
      localThumb: "./thumb/pierce-arrow.png",
      localOptimized: "./optimized/pierce-arrow.png",
      localOriginal: "./original/pierce-arrow.png",
    },
  },
  {
    name: "Pininfarina",
    slug: "pininfarina",
    image: {
      source: "https://www.carlogos.org/logo/Pininfarina-logo-640x232.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pininfarina.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pininfarina.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pininfarina.jpg",
      localThumb: "./thumb/pininfarina.png",
      localOptimized: "./optimized/pininfarina.png",
      localOriginal: "./original/pininfarina.jpg",
    },
  },
  {
    name: "Polestar",
    slug: "polestar",
    image: {
      source: "https://www.carlogos.org/logo/Polestar-logo-640x505.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/polestar.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/polestar.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/polestar.jpg",
      localThumb: "./thumb/polestar.png",
      localOptimized: "./optimized/polestar.png",
      localOriginal: "./original/polestar.jpg",
    },
  },
  {
    name: "Plymouth",
    slug: "plymouth",
    image: {
      source: "https://www.carlogos.org/logo/Plymouth-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/plymouth.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/plymouth.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/plymouth.jpg",
      localThumb: "./thumb/plymouth.png",
      localOptimized: "./optimized/plymouth.png",
      localOriginal: "./original/plymouth.jpg",
    },
  },
  {
    name: "Pontiac",
    slug: "pontiac",
    image: {
      source: "https://www.carlogos.org/logo/Pontiac-logo-640x440.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/pontiac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/pontiac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/pontiac.jpg",
      localThumb: "./thumb/pontiac.png",
      localOptimized: "./optimized/pontiac.png",
      localOriginal: "./original/pontiac.jpg",
    },
  },
  {
    name: "Porsche",
    slug: "porsche",
    image: {
      source:
        "https://www.carlogos.org/car-logos/porsche-logo-2100x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/porsche.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/porsche.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/porsche.png",
      localThumb: "./thumb/porsche.png",
      localOptimized: "./optimized/porsche.png",
      localOriginal: "./original/porsche.png",
    },
  },
  {
    name: "Premier",
    slug: "premier",
    image: {
      source: "https://www.carlogos.org/logo/Premier-logo-640x242.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/premier.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/premier.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/premier.jpg",
      localThumb: "./thumb/premier.png",
      localOptimized: "./optimized/premier.png",
      localOriginal: "./original/premier.jpg",
    },
  },
  {
    name: "Praga",
    slug: "praga",
    image: {
      source: "https://www.carlogos.org/logo/Praga-logo-blue-640x284.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/praga.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/praga.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/praga.jpg",
      localThumb: "./thumb/praga.png",
      localOptimized: "./optimized/praga.png",
      localOriginal: "./original/praga.jpg",
    },
  },
  {
    name: "Prodrive",
    slug: "prodrive",
    image: {
      source: "https://www.carlogos.org/logo/Prodrive-logo-640x103.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/prodrive.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/prodrive.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/prodrive.jpg",
      localThumb: "./thumb/prodrive.png",
      localOptimized: "./optimized/prodrive.png",
      localOriginal: "./original/prodrive.jpg",
    },
  },
  {
    name: "Proton",
    slug: "proton",
    image: {
      source: "https://www.carlogos.org/logo/Proton-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/proton.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/proton.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/proton.jpg",
      localThumb: "./thumb/proton.png",
      localOptimized: "./optimized/proton.png",
      localOriginal: "./original/proton.jpg",
    },
  },
  {
    name: "Qoros",
    slug: "qoros",
    image: {
      source: "https://www.carlogos.org/logo/Qoros-logo-2007-640x454.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/qoros.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/qoros.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/qoros.jpg",
      localThumb: "./thumb/qoros.png",
      localOptimized: "./optimized/qoros.png",
      localOriginal: "./original/qoros.jpg",
    },
  },
  {
    name: "Radical",
    slug: "radical",
    image: {
      source:
        "https://www.carlogos.org/logo/Radical-Sportscars-logo-640x215.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/radical.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/radical.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/radical.jpg",
      localThumb: "./thumb/radical.png",
      localOptimized: "./optimized/radical.png",
      localOriginal: "./original/radical.jpg",
    },
  },
  {
    name: "RAM",
    slug: "ram",
    image: {
      source: "https://www.carlogos.org/logo/RAM-logo-2009-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ram.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ram.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ram.jpg",
      localThumb: "./thumb/ram.png",
      localOptimized: "./optimized/ram.png",
      localOriginal: "./original/ram.jpg",
    },
  },
  {
    name: "Rambler",
    slug: "rambler",
    image: {
      source: "https://www.carlogos.org/logo/Rambler-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rambler.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rambler.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rambler.jpg",
      localThumb: "./thumb/rambler.png",
      localOptimized: "./optimized/rambler.png",
      localOriginal: "./original/rambler.jpg",
    },
  },
  {
    name: "Ranz",
    slug: "ranz",
    image: {
      source: "https://www.carlogos.org/logo/Ranz-logo-640x268.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ranz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ranz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ranz.jpg",
      localThumb: "./thumb/ranz.png",
      localOptimized: "./optimized/ranz.png",
      localOriginal: "./original/ranz.jpg",
    },
  },
  {
    name: "Renault",
    slug: "renault",
    image: {
      source: "https://www.carlogos.org/logo/Renault-logo-2015-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/renault.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/renault.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/renault.jpg",
      localThumb: "./thumb/renault.png",
      localOptimized: "./optimized/renault.png",
      localOriginal: "./original/renault.jpg",
    },
  },
  {
    name: "Renault Samsung",
    slug: "renault-samsung",
    image: {
      source:
        "https://www.carlogos.org/logo/Renault-Samsung-Motors-logo-640x368.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/renault-samsung.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/renault-samsung.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/renault-samsung.jpg",
      localThumb: "./thumb/renault-samsung.png",
      localOptimized: "./optimized/renault-samsung.png",
      localOriginal: "./original/renault-samsung.jpg",
    },
  },
  {
    name: "Rezvani",
    slug: "rezvani",
    image: {
      source: "https://www.carlogos.org/logo/Rezvani-logo-640x345.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rezvani.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rezvani.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rezvani.jpg",
      localThumb: "./thumb/rezvani.png",
      localOptimized: "./optimized/rezvani.png",
      localOriginal: "./original/rezvani.jpg",
    },
  },
  {
    name: "Riley",
    slug: "riley",
    image: {
      source: "https://www.carlogos.org/logo/Marcos-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/riley.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/riley.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/riley.png",
      localThumb: "./thumb/riley.png",
      localOptimized: "./optimized/riley.png",
      localOriginal: "./original/riley.png",
    },
  },
  {
    name: "Rimac",
    slug: "rimac",
    image: {
      source: "https://www.carlogos.org/logo/Rimac-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rimac.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rimac.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rimac.jpg",
      localThumb: "./thumb/rimac.png",
      localOptimized: "./optimized/rimac.png",
      localOriginal: "./original/rimac.jpg",
    },
  },
  {
    name: "Rinspeed",
    slug: "rinspeed",
    image: {
      source: "https://www.carlogos.org/logo/Rinspeed-logo-640x65.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rinspeed.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rinspeed.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rinspeed.jpg",
      localThumb: "./thumb/rinspeed.png",
      localOptimized: "./optimized/rinspeed.png",
      localOriginal: "./original/rinspeed.jpg",
    },
  },
  {
    name: "Rivian",
    slug: "rivian",
    image: {
      source:
        "https://www.carlogos.org/car-logos/rivian-logo-black-1300x1150-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rivian.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rivian.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rivian.png",
      localThumb: "./thumb/rivian.png",
      localOptimized: "./optimized/rivian.png",
      localOriginal: "./original/rivian.png",
    },
  },
  {
    name: "Rolls-Royce",
    slug: "rolls-royce",
    image: {
      source: "https://www.carlogos.org/logo/Rolls-Royce-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rolls-royce.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rolls-royce.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rolls-royce.jpg",
      localThumb: "./thumb/rolls-royce.png",
      localOptimized: "./optimized/rolls-royce.png",
      localOriginal: "./original/rolls-royce.jpg",
    },
  },
  {
    name: "Roewe",
    slug: "roewe",
    image: {
      source: "https://www.carlogos.org/logo/Roewe-logo-2006-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/roewe.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/roewe.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/roewe.jpg",
      localThumb: "./thumb/roewe.png",
      localOptimized: "./optimized/roewe.png",
      localOriginal: "./original/roewe.jpg",
    },
  },
  {
    name: "Ronart",
    slug: "ronart",
    image: {
      source: "https://www.carlogos.org/logo/Ronart-Cars-logo-640x385.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ronart.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ronart.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ronart.jpg",
      localThumb: "./thumb/ronart.png",
      localOptimized: "./optimized/ronart.png",
      localOriginal: "./original/ronart.jpg",
    },
  },
  {
    name: "Rossion",
    slug: "rossion",
    image: {
      source: "https://www.carlogos.org/logo/Rossion-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rossion.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rossion.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rossion.jpg",
      localThumb: "./thumb/rossion.png",
      localOptimized: "./optimized/rossion.png",
      localOriginal: "./original/rossion.jpg",
    },
  },
  {
    name: "Rover",
    slug: "rover",
    image: {
      source: "https://www.carlogos.org/logo/Rover-logo-2003-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/rover.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/rover.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/rover.jpg",
      localThumb: "./thumb/rover.png",
      localOptimized: "./optimized/rover.png",
      localOriginal: "./original/rover.jpg",
    },
  },
  {
    name: "RUF",
    slug: "ruf",
    image: {
      source: "https://www.carlogos.org/logo/Ruf-logo-640x282.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ruf.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ruf.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ruf.jpg",
      localThumb: "./thumb/ruf.png",
      localOptimized: "./optimized/ruf.png",
      localOriginal: "./original/ruf.jpg",
    },
  },
  {
    name: "Saab",
    slug: "saab",
    image: {
      source: "https://www.carlogos.org/logo/Saab-logo-2013-640x143.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/saab.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/saab.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/saab.jpg",
      localThumb: "./thumb/saab.png",
      localOptimized: "./optimized/saab.png",
      localOriginal: "./original/saab.jpg",
    },
  },
  {
    name: "Saipa",
    slug: "saipa",
    image: {
      source: "https://www.carlogos.org/logo/Saipa-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/saipa.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/saipa.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/saipa.jpg",
      localThumb: "./thumb/saipa.png",
      localOptimized: "./optimized/saipa.png",
      localOriginal: "./original/saipa.jpg",
    },
  },
  {
    name: "SAIC Motor",
    slug: "saic-motor",
    image: {
      source: "https://www.carlogos.org/logo/SAIC-Motor-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/saic-motor.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/saic-motor.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/saic-motor.jpg",
      localThumb: "./thumb/saic-motor.png",
      localOptimized: "./optimized/saic-motor.png",
      localOriginal: "./original/saic-motor.jpg",
    },
  },
  {
    name: "Saleen",
    slug: "saleen",
    image: {
      source: "https://www.carlogos.org/logo/Hennessey-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/saleen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/saleen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/saleen.png",
      localThumb: "./thumb/saleen.png",
      localOptimized: "./optimized/saleen.png",
      localOriginal: "./original/saleen.png",
    },
  },
  {
    name: "Saturn",
    slug: "saturn",
    image: {
      source: "https://www.carlogos.org/logo/Saturn-logo-1985-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/saturn.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/saturn.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/saturn.jpg",
      localThumb: "./thumb/saturn.png",
      localOptimized: "./optimized/saturn.png",
      localOriginal: "./original/saturn.jpg",
    },
  },
  {
    name: "Scania",
    slug: "scania",
    image: {
      source: "https://www.carlogos.org/logo/Scania-logo-640x169.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/scania.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/scania.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/scania.jpg",
      localThumb: "./thumb/scania.png",
      localOptimized: "./optimized/scania.png",
      localOriginal: "./original/scania.jpg",
    },
  },
  {
    name: "Scion",
    slug: "scion",
    image: {
      source: "https://www.carlogos.org/logo/Scion-logo-2003-640x442.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/scion.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/scion.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/scion.jpg",
      localThumb: "./thumb/scion.png",
      localOptimized: "./optimized/scion.png",
      localOriginal: "./original/scion.jpg",
    },
  },
  {
    name: "SEAT",
    slug: "seat",
    image: {
      source: "https://www.carlogos.org/logo/SEAT-logo-2012-640x508.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/seat.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/seat.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/seat.jpg",
      localThumb: "./thumb/seat.png",
      localOptimized: "./optimized/seat.png",
      localOriginal: "./original/seat.jpg",
    },
  },
  {
    name: "Setra",
    slug: "setra",
    image: {
      source: "https://www.carlogos.org/logo/Setra-logo-640x135.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/setra.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/setra.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/setra.jpg",
      localThumb: "./thumb/setra.png",
      localOptimized: "./optimized/setra.png",
      localOriginal: "./original/setra.jpg",
    },
  },
  {
    name: "Shacman",
    slug: "shacman",
    image: {
      source:
        "https://www.carlogos.org/car-logos/shacman-logo-1400x1400-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/shacman.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/shacman.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/shacman.png",
      localThumb: "./thumb/shacman.png",
      localOptimized: "./optimized/shacman.png",
      localOriginal: "./original/shacman.png",
    },
  },
  {
    name: "Simca",
    slug: "simca",
    image: {
      source: "https://www.carlogos.org/logo/Simca-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/simca.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/simca.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/simca.jpg",
      localThumb: "./thumb/simca.png",
      localOptimized: "./optimized/simca.png",
      localOriginal: "./original/simca.jpg",
    },
  },
  {
    name: "Singer",
    slug: "singer",
    image: {
      source: "https://www.carlogos.org/logo/Marcos-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/singer.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/singer.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/singer.png",
      localThumb: "./thumb/singer.png",
      localOptimized: "./optimized/singer.png",
      localOriginal: "./original/singer.png",
    },
  },
  {
    name: "Singulato",
    slug: "singulato",
    image: {
      source:
        "https://www.carlogos.org/car-logos/singulato-logo-1050x400-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/singulato.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/singulato.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/singulato.png",
      localThumb: "./thumb/singulato.png",
      localOptimized: "./optimized/singulato.png",
      localOriginal: "./original/singulato.png",
    },
  },
  {
    name: "Sisu",
    slug: "sisu",
    image: {
      source: "https://www.carlogos.org/logo/Sisu-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/sisu.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/sisu.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/sisu.jpg",
      localThumb: "./thumb/sisu.png",
      localOptimized: "./optimized/sisu.png",
      localOriginal: "./original/sisu.jpg",
    },
  },
  {
    name: "Sinotruk",
    slug: "sinotruk",
    image: {
      source:
        "https://www.carlogos.org/car-logos/sinotruk-logo-1100x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/sinotruk.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/sinotruk.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/sinotruk.png",
      localThumb: "./thumb/sinotruk.png",
      localOptimized: "./optimized/sinotruk.png",
      localOriginal: "./original/sinotruk.png",
    },
  },
  {
    name: "Škoda",
    slug: "skoda",
    image: {
      source: "https://www.carlogos.org/logo/Skoda-logo-2016-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/skoda.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/skoda.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/skoda.jpg",
      localThumb: "./thumb/skoda.png",
      localOptimized: "./optimized/skoda.png",
      localOriginal: "./original/skoda.jpg",
    },
  },
  {
    name: "Smart",
    slug: "smart",
    image: {
      source: "https://www.carlogos.org/logo/Smart-logo-1994-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/smart.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/smart.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/smart.jpg",
      localThumb: "./thumb/smart.png",
      localOptimized: "./optimized/smart.png",
      localOriginal: "./original/smart.jpg",
    },
  },
  {
    name: "Soueast",
    slug: "soueast",
    image: {
      source: "https://www.carlogos.org/logo/Soueast-logo-1995-640x459.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/soueast.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/soueast.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/soueast.jpg",
      localThumb: "./thumb/soueast.png",
      localOptimized: "./optimized/soueast.png",
      localOriginal: "./original/soueast.jpg",
    },
  },
  {
    name: "Spania GTA",
    slug: "spania-gta",
    image: {
      source: "https://www.carlogos.org/logo/GTA-Motor-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/spania-gta.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/spania-gta.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/spania-gta.jpg",
      localThumb: "./thumb/spania-gta.png",
      localOptimized: "./optimized/spania-gta.png",
      localOriginal: "./original/spania-gta.jpg",
    },
  },
  {
    name: "Spirra",
    slug: "spirra",
    image: {
      source: "https://www.carlogos.org/logo/Spirra-logo-640x529.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/spirra.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/spirra.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/spirra.jpg",
      localThumb: "./thumb/spirra.png",
      localOptimized: "./optimized/spirra.png",
      localOriginal: "./original/spirra.jpg",
    },
  },
  {
    name: "Spyker",
    slug: "spyker",
    image: {
      source: "https://www.carlogos.org/logo/Spyker-logo-black-640x335.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/spyker.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/spyker.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/spyker.jpg",
      localThumb: "./thumb/spyker.png",
      localOptimized: "./optimized/spyker.png",
      localOriginal: "./original/spyker.jpg",
    },
  },
  {
    name: "SsangYong",
    slug: "ssangyong",
    image: {
      source: "https://www.carlogos.org/logo/SsangYong-logo-640x422.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ssangyong.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ssangyong.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ssangyong.jpg",
      localThumb: "./thumb/ssangyong.png",
      localOptimized: "./optimized/ssangyong.png",
      localOriginal: "./original/ssangyong.jpg",
    },
  },
  {
    name: "Sterling",
    slug: "sterling",
    image: {
      source: "https://www.carlogos.org/logo/Sterling-logo-640x367.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/sterling.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/sterling.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/sterling.jpg",
      localThumb: "./thumb/sterling.png",
      localOptimized: "./optimized/sterling.png",
      localOriginal: "./original/sterling.jpg",
    },
  },
  {
    name: "SSC",
    slug: "ssc",
    image: {
      source: "https://www.carlogos.org/logo/SSC-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ssc.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ssc.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ssc.jpg",
      localThumb: "./thumb/ssc.png",
      localOptimized: "./optimized/ssc.png",
      localOriginal: "./original/ssc.jpg",
    },
  },
  {
    name: "Studebaker",
    slug: "studebaker",
    image: {
      source: "https://www.carlogos.org/logo/Studebaker-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/studebaker.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/studebaker.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/studebaker.jpg",
      localThumb: "./thumb/studebaker.png",
      localOptimized: "./optimized/studebaker.png",
      localOriginal: "./original/studebaker.jpg",
    },
  },
  {
    name: "Subaru",
    slug: "subaru",
    image: {
      source: "https://www.carlogos.org/logo/Subaru-logo-2003-640x358.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/subaru.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/subaru.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/subaru.jpg",
      localThumb: "./thumb/subaru.png",
      localOptimized: "./optimized/subaru.png",
      localOriginal: "./original/subaru.jpg",
    },
  },
  {
    name: "Suzuki",
    slug: "suzuki",
    image: {
      source: "https://www.carlogos.org/logo/Suzuki-logo-640x285.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/suzuki.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/suzuki.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/suzuki.jpg",
      localThumb: "./thumb/suzuki.png",
      localOptimized: "./optimized/suzuki.png",
      localOriginal: "./original/suzuki.jpg",
    },
  },
  {
    name: "Suffolk",
    slug: "suffolk",
    image: {
      source:
        "https://www.carlogos.org/logo/Suffolk-Sportscars-logo-640x315.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/suffolk.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/suffolk.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/suffolk.jpg",
      localThumb: "./thumb/suffolk.png",
      localOptimized: "./optimized/suffolk.png",
      localOriginal: "./original/suffolk.jpg",
    },
  },
  {
    name: "Stutz",
    slug: "stutz",
    image: {
      source: "https://www.carlogos.org/car-logos/stutz-logo-800x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/stutz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/stutz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/stutz.png",
      localThumb: "./thumb/stutz.png",
      localOptimized: "./optimized/stutz.png",
      localOriginal: "./original/stutz.png",
    },
  },
  {
    name: "Talbot",
    slug: "talbot",
    image: {
      source: "https://www.carlogos.org/logo/Talbot-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/talbot.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/talbot.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/talbot.jpg",
      localThumb: "./thumb/talbot.png",
      localOptimized: "./optimized/talbot.png",
      localOriginal: "./original/talbot.jpg",
    },
  },
  {
    name: "Tata",
    slug: "tata",
    image: {
      source: "https://www.carlogos.org/logo/Tata-logo-2000-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tata.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tata.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tata.jpg",
      localThumb: "./thumb/tata.png",
      localOptimized: "./optimized/tata.png",
      localOriginal: "./original/tata.jpg",
    },
  },
  {
    name: "Tesla",
    slug: "tesla",
    image: {
      source:
        "https://www.carlogos.org/car-logos/tesla-logo-2200x2800-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tesla.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tesla.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tesla.png",
      localThumb: "./thumb/tesla.png",
      localOptimized: "./optimized/tesla.png",
      localOriginal: "./original/tesla.png",
    },
  },
  {
    name: "Tatra",
    slug: "tatra",
    image: {
      source: "https://www.carlogos.org/logo/Tatra-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tatra.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tatra.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tatra.jpg",
      localThumb: "./thumb/tatra.png",
      localOptimized: "./optimized/tatra.png",
      localOriginal: "./original/tatra.jpg",
    },
  },
  {
    name: "TechArt",
    slug: "techart",
    image: {
      source: "https://www.carlogos.org/logo/TechArt-logo-640x395.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/techart.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/techart.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/techart.jpg",
      localThumb: "./thumb/techart.png",
      localOptimized: "./optimized/techart.png",
      localOriginal: "./original/techart.jpg",
    },
  },
  {
    name: "Toyota Alphard",
    slug: "toyota-alphard",
    image: {
      source:
        "https://www.carlogos.org/car-logos/toyota-alphard-logo-500x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/toyota-alphard.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/toyota-alphard.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/toyota-alphard.png",
      localThumb: "./thumb/toyota-alphard.png",
      localOptimized: "./optimized/toyota-alphard.png",
      localOriginal: "./original/toyota-alphard.png",
    },
  },
  {
    name: "Toyota",
    slug: "toyota",
    image: {
      source:
        "https://www.carlogos.org/car-logos/toyota-logo-2019-3700x1200-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/toyota.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/toyota.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/toyota.png",
      localThumb: "./thumb/toyota.png",
      localOptimized: "./optimized/toyota.png",
      localOriginal: "./original/toyota.png",
    },
  },
  {
    name: "Toyota Crown",
    slug: "toyota-crown",
    image: {
      source: "https://www.carlogos.org/logo/Toyota-Crown-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/toyota-crown.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/toyota-crown.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/toyota-crown.jpg",
      localThumb: "./thumb/toyota-crown.png",
      localOptimized: "./optimized/toyota-crown.png",
      localOriginal: "./original/toyota-crown.jpg",
    },
  },
  {
    name: "Tramontana",
    slug: "tramontana",
    image: {
      source: "https://www.carlogos.org/logo/Tramontana-logo-black-640x384.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tramontana.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tramontana.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tramontana.jpg",
      localThumb: "./thumb/tramontana.png",
      localOptimized: "./optimized/tramontana.png",
      localOriginal: "./original/tramontana.jpg",
    },
  },
  {
    name: "Toyota Century",
    slug: "toyota-century",
    image: {
      source:
        "https://www.carlogos.org/car-logos/toyota-century-logo-900x1000-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/toyota-century.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/toyota-century.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/toyota-century.png",
      localThumb: "./thumb/toyota-century.png",
      localOptimized: "./optimized/toyota-century.png",
      localOriginal: "./original/toyota-century.png",
    },
  },
  {
    name: "Trion",
    slug: "trion",
    image: {
      source: "https://www.carlogos.org/logo/Trion-logo-640x404.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/trion.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/trion.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/trion.jpg",
      localThumb: "./thumb/trion.png",
      localOptimized: "./optimized/trion.png",
      localOriginal: "./original/trion.jpg",
    },
  },
  {
    name: "Troller",
    slug: "troller",
    image: {
      source: "https://www.carlogos.org/logo/Troller-logo-640x108.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/troller.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/troller.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/troller.jpg",
      localThumb: "./thumb/troller.png",
      localOptimized: "./optimized/troller.png",
      localOriginal: "./original/troller.jpg",
    },
  },
  {
    name: "Triumph",
    slug: "triumph",
    image: {
      source: "https://www.carlogos.org/logo/Triumph-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/triumph.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/triumph.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/triumph.jpg",
      localThumb: "./thumb/triumph.png",
      localOptimized: "./optimized/triumph.png",
      localOriginal: "./original/triumph.jpg",
    },
  },
  {
    name: "TVR",
    slug: "tvr",
    image: {
      source: "https://www.carlogos.org/logo/TVR-logo-640x224.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tvr.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tvr.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tvr.jpg",
      localThumb: "./thumb/tvr.png",
      localOptimized: "./optimized/tvr.png",
      localOriginal: "./original/tvr.jpg",
    },
  },
  {
    name: "UAZ",
    slug: "uaz",
    image: {
      source: "https://www.carlogos.org/logo/UAZ-logo-640x364.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/uaz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/uaz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/uaz.jpg",
      localThumb: "./thumb/uaz.png",
      localOptimized: "./optimized/uaz.png",
      localOriginal: "./original/uaz.jpg",
    },
  },
  {
    name: "Tucker",
    slug: "tucker",
    image: {
      source: "https://www.carlogos.org/car-logos/tucker-logo-700x900-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/tucker.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/tucker.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/tucker.png",
      localThumb: "./thumb/tucker.png",
      localOptimized: "./optimized/tucker.png",
      localOriginal: "./original/tucker.png",
    },
  },
  {
    name: "Ultima",
    slug: "ultima",
    image: {
      source: "https://www.carlogos.org/logo/Ultima-Sports-logo-640x149.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ultima.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ultima.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ultima.jpg",
      localThumb: "./thumb/ultima.png",
      localOptimized: "./optimized/ultima.png",
      localOriginal: "./original/ultima.jpg",
    },
  },
  {
    name: "UD",
    slug: "ud",
    image: {
      source: "https://www.carlogos.org/logo/UD-Trucks-logo-640x508.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/ud.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/ud.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/ud.jpg",
      localThumb: "./thumb/ud.png",
      localOptimized: "./optimized/ud.png",
      localOriginal: "./original/ud.jpg",
    },
  },
  {
    name: "Vandenbrink",
    slug: "vandenbrink",
    image: {
      source: "https://www.carlogos.org/logo/Spyker-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/vandenbrink.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/vandenbrink.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/vandenbrink.png",
      localThumb: "./thumb/vandenbrink.png",
      localOptimized: "./optimized/vandenbrink.png",
      localOriginal: "./original/vandenbrink.png",
    },
  },
  {
    name: "Vauxhall",
    slug: "vauxhall",
    image: {
      source:
        "https://www.carlogos.org/logo/Vauxhall-logo-2008-red-640x478.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/vauxhall.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/vauxhall.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/vauxhall.jpg",
      localThumb: "./thumb/vauxhall.png",
      localOptimized: "./optimized/vauxhall.png",
      localOriginal: "./original/vauxhall.jpg",
    },
  },
  {
    name: "Vector",
    slug: "vector",
    image: {
      source: "https://www.carlogos.org/logo/Vector-Motors-logo-640x480.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/vector.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/vector.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/vector.jpg",
      localThumb: "./thumb/vector.png",
      localOptimized: "./optimized/vector.png",
      localOriginal: "./original/vector.jpg",
    },
  },
  {
    name: "Venturi",
    slug: "venturi",
    image: {
      source: "https://www.carlogos.org/logo/Ligier-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/venturi.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/venturi.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/venturi.png",
      localThumb: "./thumb/venturi.png",
      localOptimized: "./optimized/venturi.png",
      localOriginal: "./original/venturi.png",
    },
  },
  {
    name: "Vencer",
    slug: "vencer",
    image: {
      source: "https://www.carlogos.org/logo/Vencer-logo-640x479.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/vencer.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/vencer.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/vencer.jpg",
      localThumb: "./thumb/vencer.png",
      localOptimized: "./optimized/vencer.png",
      localOriginal: "./original/vencer.jpg",
    },
  },
  {
    name: "Venucia",
    slug: "venucia",
    image: {
      source: "https://www.carlogos.org/logo/Venucia-logo-2017-640x260.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/venucia.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/venucia.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/venucia.jpg",
      localThumb: "./thumb/venucia.png",
      localOptimized: "./optimized/venucia.png",
      localOriginal: "./original/venucia.jpg",
    },
  },
  {
    name: "Volkswagen",
    slug: "volkswagen",
    image: {
      source: "https://www.carlogos.org/logo/Volkswagen-logo-2019-640x500.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/volkswagen.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/volkswagen.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/volkswagen.jpg",
      localThumb: "./thumb/volkswagen.png",
      localOptimized: "./optimized/volkswagen.png",
      localOriginal: "./original/volkswagen.jpg",
    },
  },
  {
    name: "VinFast",
    slug: "vinfast",
    image: {
      source:
        "https://www.carlogos.org/car-logos/vinfast-logo-900x850-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/vinfast.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/vinfast.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/vinfast.png",
      localThumb: "./thumb/vinfast.png",
      localOptimized: "./optimized/vinfast.png",
      localOriginal: "./original/vinfast.png",
    },
  },
  {
    name: "Volvo",
    slug: "volvo",
    image: {
      source: "https://www.carlogos.org/logo/Volvo-logo-2014-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/volvo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/volvo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/volvo.jpg",
      localThumb: "./thumb/volvo.png",
      localOptimized: "./optimized/volvo.png",
      localOriginal: "./original/volvo.jpg",
    },
  },
  {
    name: "Wanderer",
    slug: "wanderer",
    image: {
      source: "https://www.carlogos.org/logo/Wanderer-logo-black-640x311.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/wanderer.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/wanderer.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/wanderer.jpg",
      localThumb: "./thumb/wanderer.png",
      localOptimized: "./optimized/wanderer.png",
      localOriginal: "./original/wanderer.jpg",
    },
  },
  {
    name: "W Motors",
    slug: "w-motors",
    image: {
      source: "https://www.carlogos.org/logo/W-Motors-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/w-motors.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/w-motors.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/w-motors.jpg",
      localThumb: "./thumb/w-motors.png",
      localOptimized: "./optimized/w-motors.png",
      localOriginal: "./original/w-motors.jpg",
    },
  },
  {
    name: "Wartburg",
    slug: "wartburg",
    image: {
      source: "https://www.carlogos.org/logo/Wartburg-logo-640x167.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/wartburg.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/wartburg.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/wartburg.jpg",
      localThumb: "./thumb/wartburg.png",
      localOptimized: "./optimized/wartburg.png",
      localOriginal: "./original/wartburg.jpg",
    },
  },
  {
    name: "Weltmeister",
    slug: "weltmeister",
    image: {
      source:
        "https://www.carlogos.org/car-logos/weltmeister-logo-1200x900-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/weltmeister.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/weltmeister.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/weltmeister.png",
      localThumb: "./thumb/weltmeister.png",
      localOptimized: "./optimized/weltmeister.png",
      localOriginal: "./original/weltmeister.png",
    },
  },
  {
    name: "WEY",
    slug: "wey",
    image: {
      source: "https://www.carlogos.org/car-logos/wey-logo-800x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/wey.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/wey.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/wey.png",
      localThumb: "./thumb/wey.png",
      localOptimized: "./optimized/wey.png",
      localOriginal: "./original/wey.png",
    },
  },
  {
    name: "Western Star",
    slug: "western-star",
    image: {
      source: "https://www.carlogos.org/logo/Western-Star-logo-640x419.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/western-star.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/western-star.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/western-star.jpg",
      localThumb: "./thumb/western-star.png",
      localOptimized: "./optimized/western-star.png",
      localOriginal: "./original/western-star.jpg",
    },
  },
  {
    name: "Westfield",
    slug: "westfield",
    image: {
      source: "https://www.carlogos.org/logo/Westfield-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/westfield.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/westfield.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/westfield.jpg",
      localThumb: "./thumb/westfield.png",
      localOptimized: "./optimized/westfield.png",
      localOriginal: "./original/westfield.jpg",
    },
  },
  {
    name: "Willys-Overland",
    slug: "willys-overland",
    image: {
      source: "https://www.carlogos.org/logo/Willys-Overland-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/willys-overland.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/willys-overland.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/willys-overland.jpg",
      localThumb: "./thumb/willys-overland.png",
      localOptimized: "./optimized/willys-overland.png",
      localOriginal: "./original/willys-overland.jpg",
    },
  },
  {
    name: "Wiesmann",
    slug: "wiesmann",
    image: {
      source: "https://www.carlogos.org/logo/Wiesmann-logo-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/wiesmann.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/wiesmann.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/wiesmann.jpg",
      localThumb: "./thumb/wiesmann.png",
      localOptimized: "./optimized/wiesmann.png",
      localOriginal: "./original/wiesmann.jpg",
    },
  },
  {
    name: "Workhorse",
    slug: "workhorse",
    image: {
      source:
        "https://www.carlogos.org/car-logos/workhorse-logo-1400x650-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/workhorse.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/workhorse.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/workhorse.png",
      localThumb: "./thumb/workhorse.png",
      localOptimized: "./optimized/workhorse.png",
      localOriginal: "./original/workhorse.png",
    },
  },
  {
    name: "XPeng",
    slug: "xpeng",
    image: {
      source: "https://www.carlogos.org/car-logos/xpeng-logo-1050x200-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/xpeng.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/xpeng.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/xpeng.png",
      localThumb: "./thumb/xpeng.png",
      localOptimized: "./optimized/xpeng.png",
      localOriginal: "./original/xpeng.png",
    },
  },
  {
    name: "Wuling",
    slug: "wuling",
    image: {
      source: "https://www.carlogos.org/logo/Wuling-logo-640x542.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/wuling.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/wuling.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/wuling.jpg",
      localThumb: "./thumb/wuling.png",
      localOptimized: "./optimized/wuling.png",
      localOriginal: "./original/wuling.jpg",
    },
  },
  {
    name: "Yulon",
    slug: "yulon",
    image: {
      source: "https://www.carlogos.org/logo/Yulon-logo-640x142.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/yulon.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/yulon.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/yulon.jpg",
      localThumb: "./thumb/yulon.png",
      localOptimized: "./optimized/yulon.png",
      localOriginal: "./original/yulon.jpg",
    },
  },
  {
    name: "Zastava",
    slug: "zastava",
    image: {
      source: "https://www.carlogos.org/logo/Gilbern-logo.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zastava.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zastava.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zastava.png",
      localThumb: "./thumb/zastava.png",
      localOptimized: "./optimized/zastava.png",
      localOriginal: "./original/zastava.png",
    },
  },
  {
    name: "Yutong",
    slug: "yutong",
    image: {
      source:
        "https://www.carlogos.org/car-logos/yutong-logo-2100x1300-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/yutong.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/yutong.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/yutong.png",
      localThumb: "./thumb/yutong.png",
      localOptimized: "./optimized/yutong.png",
      localOriginal: "./original/yutong.png",
    },
  },
  {
    name: "ZAZ",
    slug: "zaz",
    image: {
      source: "https://www.carlogos.org/logo/ZAZ-logo-640x294.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zaz.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zaz.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zaz.jpg",
      localThumb: "./thumb/zaz.png",
      localOptimized: "./optimized/zaz.png",
      localOriginal: "./original/zaz.jpg",
    },
  },
  {
    name: "Zenos",
    slug: "zenos",
    image: {
      source: "https://www.carlogos.org/logo/Zenos-Cars-logo-640x466.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zenos.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zenos.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zenos.jpg",
      localThumb: "./thumb/zenos.png",
      localOptimized: "./optimized/zenos.png",
      localOriginal: "./original/zenos.jpg",
    },
  },
  {
    name: "Zhongtong",
    slug: "zhongtong",
    image: {
      source:
        "https://www.carlogos.org/car-logos/zhongtong-logo-1400x1100-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zhongtong.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zhongtong.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zhongtong.png",
      localThumb: "./thumb/zhongtong.png",
      localOptimized: "./optimized/zhongtong.png",
      localOriginal: "./original/zhongtong.png",
    },
  },
  {
    name: "Zenvo",
    slug: "zenvo",
    image: {
      source: "https://www.carlogos.org/logo/Zenvo-logo-2009-640x550.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zenvo.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zenvo.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zenvo.jpg",
      localThumb: "./thumb/zenvo.png",
      localOptimized: "./optimized/zenvo.png",
      localOriginal: "./original/zenvo.jpg",
    },
  },
  {
    name: "Zotye",
    slug: "zotye",
    image: {
      source: "https://www.carlogos.org/logo/Zotye-logo-640x146.jpg",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zotye.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zotye.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zotye.jpg",
      localThumb: "./thumb/zotye.png",
      localOptimized: "./optimized/zotye.png",
      localOriginal: "./original/zotye.jpg",
    },
  },
  {
    name: "Zinoro",
    slug: "zinoro",
    image: {
      source: "https://www.carlogos.org/car-logos/zhinuo-logo-800x600-show.png",
      thumb:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/thumb/zinoro.png",
      optimized:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/optimized/zinoro.png",
      original:
        "https://raw.githubusercontent.com/filippofilip95/car-logos-dataset/master/logos/original/zinoro.png",
      localThumb: "./thumb/zinoro.png",
      localOptimized: "./optimized/zinoro.png",
      localOriginal: "./original/zinoro.png",
    },
  },
];

export default CreateCarAssetForm;
