import { CircularProgress } from "@mui/material";
import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import bgpdf from "../../assets/bgpdf.png";
import logo3 from "../../assets/logo.png";
import robotoRegular from "../../assets/fonts/Roboto-Regular.ttf";
import robotBold from "../../assets/fonts/Roboto-Bold.ttf";

import moment from "moment";
import { Box } from "@mui/joy";

const Tubu = require("@tubu/tubuio-sdk-node");
const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
const contract = app.contract(
  process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
);

// Create styles
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: robotoRegular,
    },
    {
      src: robotBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  image: {
    width: 150,
    height: 40,
    marginLeft: 270,
    marginTop: "40px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "center",
    marginTop: "-20px",
  },
  desc: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
  },
  desc2: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "700",
    textAlign: "justify",
  },
  title2: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 14,
    width: 300,
  },
  title3: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontSize: 14,
    width: 700,
  },
  table: {
    fontFamily: "Roboto",

    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "20px",
  },
  tableRow: {
    fontFamily: "Roboto",

    flexDirection: "row",
  },
  tableCol: {
    fontFamily: "Roboto",

    width: "34%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    fontFamily: "Roboto",
    marginLeft: "5px",
    marginVertical: 20,
    fontSize: 10,
  },
  president: {
    fontFamily: "Roboto",
    fontSize: 14,
  },
  last: {
    fontFamily: "Roboto",
    fontSize: 9.5,
  },
  pageBackground: {
    position: "absolute",
    width: "100%",
    height: "590px",
    padding: "16px",
  },
});

// Create Document Component
const DocumentPDF = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [QRCodeString, setQRCodeString] = useState(null);
  const [QRCodeString2, setQRCodeString2] = useState(null);
  const [fromUsers, setFromUsers] = useState([]);
  const [toUsers, setToUsers] = useState([]);

  const location = useLocation();
  console.log(location);
  console.log(data);

  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );

  useEffect(() => {
    contract
      .call("getDocument", id)
      .then((result) => {
        setData(result.data);

        console.log("userarray", result.data);

        result.data[2][0].map((item) => {
          userContract.call("getUserByHash", [item]).then((result) => {
            console.log(result.data);
            setFromUsers((prevArray) => [...prevArray, result.data]);
          });
        });

        // tek adamdan gelirse filtrele userhashten
        result.data[2][2].map((item) => {
          userContract.call("getUserByHash", [item]).then((result) => {
            console.log(result.data);
            setToUsers((prevArray) => [...prevArray, result.data]);
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    QRCode.toDataURL("https://notary.tubu.io/viewDocument/" + id).then(
      (data) => {
        setQRCodeString(data);
      }
    );
  }, []);

  useEffect(() => {
    if (data) {
      QRCode.toDataURL(
        "https://" + data[1][3] + ".ipfs.nftstorage.link/metadata.json"
      ).then((data) => {
        setQRCodeString2(data);
      });
    }
  }, [data]);

  return (
    <>
      {data && QRCodeString && QRCodeString2 ? (
        <PDFViewer style={styles.viewer}>
          {/* Start of the document*/}

          <Document>
            {/*render a single page*/}
            <Page
              size="A4"
              style={[
                styles.page,
                data[4] == "APPROVE"
                  ? { backgroundColor: "green" }
                  : data[4] == "PASSIVE"
                  ? { backgroundColor: "red" }
                  : { backgroundColor: "#F4C430" },
              ]}
              orientation="landscape"
            >
              <Image src={bgpdf} style={styles.pageBackground} />
              <View
                style={{
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  height: "100%",
                }}
              >
                <Image src={logo3} style={styles.image} />

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Text style={styles.title2}>Document Type</Text>
                  <Text style={styles.title3}>
                    :{" "}
                    {data[1][2] == "EDNAC"
                      ? "Enhanced Digital Notary Asset Certificate"
                      : "Permanent Digital Notary Asset Certificate"}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Document Name</Text>
                  <Text style={styles.title3}>: {data[1][0]}</Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>POS Asset Owner(s)</Text>
                  <Text style={styles.title3}>
                    : {toUsers.map((item) => item[0] + " " + item[1]) + " "}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Asset Owner(s)</Text>
                  <Text style={styles.title3}>
                    : {fromUsers.map((item) => item[0] + " " + item[1]) + " "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Document Name</Text>
                  <Text style={styles.title3}>: {data[1][0]}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Status</Text>
                  <Text
                    style={[
                      styles.title3,
                      data[4] == "APPROVE"
                        ? { color: "green", fontWeight: "bold" }
                        : data[4] == "PASSIVE"
                        ? { color: "red", fontWeight: "bold" }
                        : { color: "#F4C430", fontWeight: "bold" },
                    ]}
                  >
                    : {data[4]}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "10px",
                      marginLeft: "48px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Notary
                  </Text>
                  <Text
                    style={{
                      fontSize: "10px",
                      marginRight: "64px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    IPFS
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={QRCodeString}
                    style={{
                      width: "150px",
                      height: "150px",
                      marginLeft: "-10px",
                    }}
                  />
                  <Image
                    src={QRCodeString2}
                    style={{
                      width: "150px",
                      height: "150px",
                      marginLeft: "-10px",
                    }}
                  />
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default DocumentPDF;
