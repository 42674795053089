import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  AspectRatio,
  Card,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Radio,
  RadioGroup,
  Select,
  Sheet,
  Snackbar,
  Table,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { TableContainer } from "@mui/material";

export default function AssetDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(process.env.REACT_APP_NFT_CONTRACT_SHORT_ID);
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );

  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );

  const [data, setData] = useState([]);
  const [shareholderData, setShareholderData] = useState([]);
  const target = React.useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [fromUserData, setFromUserData] = React.useState();
  const [currency, setCurrency] = React.useState("nationalId");
  const [shareholderModal, setShareholderModal] = useState(false);
  const [searchUserType, setSearchUserType] = React.useState("");
  const [value, setValue] = React.useState();

  const initialShareholderFormData = {
    user: {},
    totalShares: "",
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialFormData = {
    _title: "",
    _documentType: "",
    _agreementType: "",
    _from: "",
    _to: "",
    _nftUid: "",
    _ipfs: "",
    _cUser: localStorage.getItem("user"),
    _uNotary: localStorage.getItem("user"),
    files: [],
  };

  const [shareholderFormData, setShareholderFormData] = useState({
    ...initialShareholderFormData,
  });

  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [toUserData, setToUserData] = React.useState();

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSearchChange = (name, value) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnShareholderChange = (name, value) => {
    console.log(shareholderFormData);

    setShareholderFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addShareholder = () => {
    console.log("test");
    console.log(fromUserData);
    console.log(shareholderFormData);
  };

  const unlockUser = async (row) => {
    assetContract
      .send("unlock", {
        args: [row[1], parseInt(location.state.id)],
        account: process.env.REACT_APP_SENDER_ACCOUNT,
      })
      .then((result) => {
        console.log(result);
        Swal.fire("Success", "User asset unlocked.", "success").then(
          (result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShareholders = async () => {
    let tempShareholders = location?.state?.shareholders;

    tempShareholders?.map(async (item) => {
      let userWalletItem = await userContract.call("getUserByUserWallet", [
        item[1],
      ]);
      item[4] = userWalletItem.data[0] + " " + userWalletItem.data[1];

      assetContract
        .call("getLockDetail", [location.state.id, item[1]])
        .then((result) => {
          console.log(result);
          item[5] = result.data;
          //setShareholderData((prev) => [...prev, item]);

          setShareholderData((prev) => {
            const isDuplicate = prev.some(
              (existingItem) => existingItem[1] === item[1]
            );

            if (isDuplicate) {
              return prev;
            }

            return [...prev, item];
          });
        });
    });
  };

  function getAssets() {
    fetch(
      "https://" + location.state.ipfs + ".ipfs.nftstorage.link/metadata.json"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson);
      });
  }

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    getShareholders();
  }, []);

  const search = (e) => {
    e.preventDefault();
    setFromUserData();
    setToUserData();
    userContract
      .call("searchUser", [
        searchFormData.nationalId,
        searchFormData.passportId,
        searchFormData.civilRegistryDocumentId,
      ])
      .then((result) => {
        if (
          result.data[1] ==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          setFromUserData("error");
          setToUserData("error");
        } else {
          assetContract
            .call("balanceOfShares", [location.state.id, result.data[5]])
            .then((balance) => {
              console.log(balance);
              let objs = [result.data].map((item, index) => ({
                id: index,
                firstName: item[0][0],
                lastName: item[0][1],
                fathersName: item[0][2],
                mothersName: item[0][3],
                dateOfBirth: item[0][4],
                placeOfBirth: item[0][5],
                gender: item[0][6],
                nationality: item[0][7],
                registryPlace: item[0][8],
                number: item[0][9],
                files: item[0][10],
                userHash: item[1],
                nationalId: item[2],
                passportId: item[3],
                civilRegistryDocumentId: item[4],
                userWallet: item[5],
                balanceOfShares: balance.data,
              }));

              console.log(objs[0]);

              handleOnShareholderChange("user", objs[0]);
              setFromUserData(objs[0]);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/assets"
            aria-label="Assets"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Assets
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Asset Details
          </Typography>
        </Breadcrumbs>
      </Box>
      {data.length != 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">Asset Details</Typography>
          </Box>
          <Card>
            Details of the asset.
            <Divider />
            <Grid container>
              <Grid xs={12} sm={6} md={3}>
                <AspectRatio sx={{ width: 200 }} ratio="1">
                  <img
                    src={
                      "https://" +
                      data?.image?.substring(7, 66) +
                      ".ipfs.nftstorage.link/" +
                      data?.image?.substring(4).split("/")[3]
                    }
                  />
                </AspectRatio>
              </Grid>
              <Grid container xs={12} sm={12} md={9}>
                <Grid xs={12} sm={6} md={3}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    {JSON.parse(data?.description)?.data?.documentType ==
                    "land" ? (
                      <>
                        <FormLabel>Property Number</FormLabel>
                        <Typography level="h2" fontSize="xl">
                          {JSON.parse(data?.description)?.data?.propertyNumber}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <FormLabel>Registration Number</FormLabel>
                        <Typography level="h2" fontSize="xl">
                          {
                            JSON.parse(data?.description)?.data
                              ?.registrationNumber
                          }
                        </Typography>
                      </>
                    )}
                  </FormControl>
                </Grid>
                {JSON.parse(data?.description)?.data?.documentType ==
                  "land" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Asset Type</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.assetType}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType ==
                  "land" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Address</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.address}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Engine Number</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.engineNumber}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>VIN</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.vin}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Color</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.color}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Model</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.modelType}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Brand</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.carBrand}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                {JSON.parse(data?.description)?.data?.documentType == "car" && (
                  <Grid xs={12} sm={6} md={3}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <FormLabel>Model year</FormLabel>
                      <Typography level="h2" fontSize="xl">
                        {JSON.parse(data?.description)?.data?.modelYear}
                      </Typography>
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={3}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <FormLabel>Total Shares</FormLabel>
                    <Typography level="h2" fontSize="xl">
                      {JSON.parse(data?.description)?.data?.totalShares}
                    </Typography>
                  </FormControl>
                </Grid>

                <Grid xs={12} sm={6} md={3}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <FormLabel>IPFS Hash</FormLabel>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Link
                        level="h2"
                        fontSize="xl"
                        component={"text"}
                        underline="none"
                        color="black"
                        ref={target}
                        onClick={() => {
                          navigator.clipboard.writeText(location?.state?.ipfs);
                          setOpenSnackbar(true);
                        }}
                      >
                        <ContentCopyIcon
                          sx={{ marginRight: 1 }}
                          color="primary"
                        />
                        {location?.state?.ipfs?.slice(0, 5) +
                          "..." +
                          location?.state?.ipfs?.slice(-5)}
                      </Link>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h4">Shareholders</Typography>
            <Button
              color="primary"
              startDecorator={<AddCircleIcon />}
              size="md"
              onClick={() => {
                setShareholderModal(true);
                setSearchUserType("from");
                setFromUserData();
                handleOnSearchChange("searchKey", "");
              }}
            >
              Add shareholder
            </Button>
          </Box>
          <Grid xs={12} sm={12} md={12}>
            {data.length != 0 ? (
              <Card>
                <TableContainer>
                  <Table
                    borderAxis="xBetween"
                    color="neutral"
                    size="md"
                    stickyFooter={false}
                    stickyHeader={false}
                    variant="plain"
                    sx={{
                      "& tr > *:not(:first-child)": { textAlign: "center" },
                      minWidth: 650,
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Wallet</th>
                        <th>Shares</th>
                        <th>Lock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shareholderData &&
                        shareholderData?.map((row) => (
                          <tr>
                            <td>{row[4]}</td>
                            <td>
                              <Button
                                color="neutral"
                                size="sm"
                                sx={{ fontWeight: "normal" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(row[1]);
                                  setOpenSnackbar(true);
                                }}
                                variant="plain"
                                startDecorator={<ContentCopyIcon />}
                              >
                                {row[1]?.slice(0, 10) +
                                  "..." +
                                  row[1]?.slice(-10)}
                              </Button>
                            </td>
                            <td>{row[2]}</td>
                            <td>
                              {}
                              {String(row[5]) == "false" ? (
                                <Button color="success" size="xs">
                                  Unlocked
                                </Button>
                              ) : (
                                <Button
                                  color="warning"
                                  size="xs"
                                  onClick={() => unlockUser(row)}
                                >
                                  Unlock User
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </TableContainer>
              </Card>
            ) : (
              <Card
                invertedColors={false}
                orientation="horizontal"
                size="md"
                variant="plain"
                color="neutral"
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography level="title-md">
                    There are no shareholders.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Modal open={shareholderModal} onClose={() => setShareholderModal(false)}>
        <ModalDialog color="primary" layout="center" size="lg" variant="soft">
          <ModalClose />
          <DialogTitle>Add Shareholder</DialogTitle>
          <DialogContent>Search for a user to add shareholder.</DialogContent>
          <FormControl>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <form onSubmit={search}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <Input
                      placeholder="Search"
                      value={
                        currency == "nationalId"
                          ? searchFormData.nationalId
                          : currency == "passportId"
                          ? searchFormData.passportId
                          : searchFormData.civilRegistryDocumentId
                      }
                      onChange={(event) => {
                        handleOnSearchChange(currency, event.target.value);
                      }}
                      endDecorator={
                        <Button
                          variant="soft"
                          color="primary"
                          startDecorator={<SearchIcon />}
                          type="submit"
                        >
                          Search
                        </Button>
                      }
                      startDecorator={
                        <React.Fragment>
                          <Select
                            variant="plain"
                            value={currency}
                            onChange={(_, value) => {
                              setCurrency(value);
                              handleOnSearchChange(value, "");
                            }}
                            slotProps={{
                              listbox: {
                                variant: "outlined",
                              },
                            }}
                          >
                            <Option value="nationalId">
                              Search by National ID
                            </Option>
                            <Option value="passportId">
                              Search by Passport ID
                            </Option>
                            <Option value="civilRegistryDocumentId">
                              Search by Civil Registry Document ID
                            </Option>
                          </Select>
                          <Divider orientation="vertical" />
                        </React.Fragment>
                      }
                    />
                  </FormControl>
                </form>
              </Grid>
              {fromUserData &&
                (fromUserData == "error" ? (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            No results found.
                          </Typography>
                          <Typography>
                            Click{" "}
                            <Link
                              component="button"
                              underline="none"
                              color="primary"
                              onClick={() => navigate("/users/create")}
                            >
                              here
                            </Link>{" "}
                            to create a user.
                          </Typography>
                        </CardContent>
                      </Card>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid xs={12} sm={12} md={12}>
                    {searchUserType == "from" &&
                    fromUserData?.balanceOfShares > 0 ? (
                      <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                        <Card
                          invertedColors={false}
                          orientation="horizontal"
                          size="md"
                          variant="plain"
                          color="neutral"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CardContent>
                            <Typography level="title-md">
                              {fromUserData?.firstName +
                                " " +
                                fromUserData?.lastName}
                            </Typography>
                            <Typography>
                              Date of Birth: {fromUserData?.dateOfBirth}
                            </Typography>
                            <Typography>
                              Place of Birth: {fromUserData?.placeOfBirth}
                            </Typography>
                            <Typography>
                              Total Shares: {fromUserData?.balanceOfShares}
                            </Typography>
                          </CardContent>
                          <Divider orientation="vertical" />
                          <Input
                            sx={{ maxHeight: 50 }}
                            type="number"
                            size="sm"
                            placeholder="Share"
                            onChange={(event) =>
                              handleOnShareholderChange(
                                "totalShares",
                                event.target.value
                              )
                            }
                          />
                        </Card>
                      </FormControl>
                    ) : searchUserType == "from" &&
                      fromUserData?.balanceOfShares == 0 ? (
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography level="title-md">
                            This person has no share in the selected asset.
                          </Typography>
                        </CardContent>
                      </Card>
                    ) : true ? (
                      <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                        <Card
                          invertedColors={false}
                          orientation="horizontal"
                          size="md"
                          variant="plain"
                          color="neutral"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CardContent>
                            <Typography level="title-md">
                              {fromUserData?.firstName +
                                " " +
                                fromUserData?.lastName}
                            </Typography>
                            <Typography>
                              Date of Birth: {fromUserData?.dateOfBirth}
                            </Typography>
                            <Typography>
                              Place of Birth: {fromUserData?.placeOfBirth}
                            </Typography>
                            <Typography>
                              Total Shares: {fromUserData?.balanceOfShares}
                            </Typography>
                          </CardContent>
                          <Divider orientation="vertical" />
                          <Input
                            sx={{ maxHeight: 50 }}
                            type="number"
                            size="sm"
                            placeholder="Share"
                            onChange={(event) =>
                              handleOnShareholderChange(
                                "totalShares",
                                event.target.value
                              )
                            }
                          />
                        </Card>
                      </FormControl>
                    ) : (
                      <FormControl>
                        <RadioGroup
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={(event) =>
                            handleOnShareholderChange("totalShares", "")
                          }
                          sx={{ my: 1 }}
                        >
                          <List
                            sx={{
                              "--List-gap": "0.5rem",
                              "--ListItem-paddingY": "1rem",
                              "--ListItem-radius": "8px",
                              "--ListItemDecorator-size": "32px",
                            }}
                          >
                            <Grid container>
                              <Grid xs={12} sm={12} md={12}>
                                <ListItem
                                  variant="soft"
                                  color="neutral"
                                  sx={{ boxShadow: "md", mr: 1 }}
                                >
                                  <Radio
                                    overlay
                                    label={
                                      <Grid container>
                                        <Grid xs={12} sm={12} md={12}>
                                          <Typography level="h4" fontSize="md">
                                            {fromUserData?.firstName +
                                              " " +
                                              fromUserData?.lastName}
                                          </Typography>
                                        </Grid>

                                        <Grid xs={12} sm={12} md={12}>
                                          <Typography>
                                            Date of Birth:{" "}
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {fromUserData?.dateOfBirth}
                                            </Typography>
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12}>
                                          <Typography>
                                            Place of Birth:{" "}
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {fromUserData?.placeOfBirth}
                                            </Typography>
                                          </Typography>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12}>
                                          <Typography>
                                            Total Shares:{" "}
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {fromUserData?.balanceOfShares}
                                            </Typography>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    }
                                    sx={{
                                      flexGrow: 1,
                                      flexDirection: "row-reverse",
                                    }}
                                    slotProps={{
                                      action: ({ checked }) => ({
                                        sx: (theme) => ({
                                          ...(checked && {
                                            inset: -1,
                                            border: "2px solid",
                                            borderColor:
                                              theme.vars.palette.primary[500],
                                          }),
                                        }),
                                      }),
                                    }}
                                  />
                                </ListItem>
                              </Grid>
                            </Grid>
                          </List>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Grid>
                ))}
            </Grid>
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              size="sm"
              onClick={() => addShareholder()}
              disabled={shareholderFormData?.totalShares == ""}
            >
              Add Shareholder
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        Success! Copied.
      </Snackbar>
    </Box>
  );
}
