import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import Swal from "sweetalert2";
import { apiCall } from "../../../utils/util";

import {
  GET_USERS_SERVICE_REQUEST,
  GET_USER_INFO_SERVICE_REQUEST,
  POST_LOGIN_SERVICE_REQUEST,
} from "../../actions";
import {
  getUserInfoServiceFailed,
  getUserInfoServiceSucceed,
  getUsersServiceFailed,
  getUsersServiceSucceed,
  postLoginServiceFailed,
  postLoginServiceSucceed,
} from "../userService";

function* watchGetUsersService() {
  yield takeLatest(GET_USERS_SERVICE_REQUEST, getUsers);
}

function* getUsers() {
  try {
    const res = yield call(apiCall, "get", "user", {});
    yield put(
      getUsersServiceSucceed({
        data: res.data,
      })
    );
  } catch (err) {
    yield put(getUsersServiceFailed());
  }
}

function* watchPostLoginService() {
  yield takeLatest(POST_LOGIN_SERVICE_REQUEST, postLogin);
}

function* postLogin(action) {
  const { email, password, navigate } = action.payload;

  try {
    if (
      (email === "notary1@tubu.io" && password === "notary1") ||
      (email === "notary2@tubu.io" && password === "notary2") ||
      (email === "notary3@tubu.io" && password === "notary3") ||
      (email === "user1@tubu.io" && password === "user1") ||
      (email === "user2@tubu.io" && password === "user2") ||
      (email === "user3@tubu.io" && password === "user3")
    ) {
      yield localStorage.setItem("token", true);
      yield localStorage.setItem("user", password);
      yield localStorage.setItem("email", email);

      yield put(
        postLoginServiceSucceed({
          data: true,
        })
      );
      navigate("/home");
    } else {
      Swal.fire("Hata", "Kullanıcı Adı veya şifre hatalı", "error");
    }
    yield localStorage.setItem("token", true);
    yield put(
      postLoginServiceSucceed({
        data: true,
      })
    );
  } catch (err) {
    yield put(postLoginServiceFailed());
  }
}

function* watchGetUserInfoService() {
  yield takeLatest(GET_USER_INFO_SERVICE_REQUEST, getUserInfo);
}

function* getUserInfo(action) {
  // const navigate = action.payload;
  // const kullaniciAdi = localStorage.getItem("email");
  // const token = localStorage.getItem("token");

  try {
    // const res = yield call(tysApiCall, "post", "findKullaniciDetay", {
    //   kullaniciAdi: kullaniciAdi,
    // });

    // if (res.data.resultCode == 401) {
    //   yield navigate("/signin");
    // }

    //let data = res.data;
    // if (res.data.tysKullaniciBilgileriDto !== null) {
    //   data = { ...data, isAdmin: false };
    // } else {
    //   data = { ...data, isAdmin: true };
    // }

    yield put(
      getUserInfoServiceSucceed({
        //data: data,
      })
    );
  } catch (err) {
    yield put(getUserInfoServiceFailed());
  }
}

export default function* userSaga() {
  yield all([
    fork(watchGetUsersService),
    fork(watchPostLoginService),
    fork(watchGetUserInfoService),
  ]);
}
