import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Card, CardActions, CardOverflow, Divider, FormLabel } from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CreateUserForm from "../../../components/Forms/CreateUserForm";
import { validate } from "../../../utils/validate";
import CreateCarAssetForm from "../../../components/Forms/CreateCarAssetForm";
import CreateLandAssetForm from "../../../components/Forms/CreateLandAssetForm";
import IconButton from "@mui/joy/IconButton";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { NFTStorage } from "nft.storage";

export default function CreateAsset() {
  const navigate = useNavigate();
  const location = useLocation();

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(process.env.REACT_APP_USER_CONTRACT_SHORT_ID);
  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );
  const JWT = process.env.REACT_APP_PINATA_TOKEN;
  const [loading, setLoading] = useState(false);
  const [assetType, setAssetType] = React.useState("land");

  const initialCarFormData = {
    registrationNumber: "",
    engineNumber: "",
    vin: "",
    color: "",
    modelYear: "",
    modelType: "",
    carBrand: "",
    files: [],
    documentType: "car",
    totalShares: "",
    shareholders: [],
  };

  const initialLandFormData = {
    propertyNumber: "",
    address: "",
    assetType: "",
    files: [],
    documentType: "land",
    totalShares: "",
    shareholders: [],
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialCarFormDataValidate = {
    registrationNumber: {
      required: true,
    },
    engineNumber: {
      required: true,
    },
    vin: {
      required: true,
    },
    color: {
      required: true,
    },
    modelYear: {
      required: true,
    },
    modelType: {
      required: true,
    },
    carBrand: {
      required: true,
    },
    files: {
      required: true,
    },
    totalShares: {
      required: true,
    },
  };

  const initialLandFormDataValidate = {
    propertyNumber: {
      required: true,
    },
    address: {
      required: true,
    },
    assetType: {
      required: true,
    },
    files: {
      required: true,
    },
    totalShares: {
      required: true,
    },
  };
  const [carFormData, setCarFormData] = useState({ ...initialCarFormData });
  const [landFormData, setLandFormData] = useState({ ...initialLandFormData });

  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  const [formDataErrors, setFormDataErrors] = useState({});

  const [carFormDataValidate, setCarFormDataValidate] = useState({
    ...initialCarFormDataValidate,
  });

  const [landFormDataValidate, setLandFormDataValidate] = useState({
    ...initialLandFormDataValidate,
  });

  const carHandleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setCarFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const landHandleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setLandFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = (type) => {
    const errorMsgs = validate(carFormData, carFormDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const pinFileToIPFS = async (data) => {
    const nftstorage = new NFTStorage({
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDYxQjFCMEE1ZTY1MEE5N2MxYWY1OTU1QjU3YzU1MkQzOTBmMTkzNTciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNjEwNTkyNzA1OSwibmFtZSI6InR1YnUifQ.n-mhJNsY8xRJns3KgbsVTI7I3Pcu7GOGnQrLB1Ae6TE",
    });

    const check = true;
    if (true) {
      setLoading(true);
      try {
        nftstorage
          .store({
            image: data.files[0],
            name: data.documentType + new Date(),
            description: JSON.stringify({
              data,
            }),
          })
          .then((res) => {
            console.log(res);
            createAsset(res.ipnft);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const createAsset = (hash) => {
    if (assetType == "land") {
      let shares = [];
      let addresses = [];

      landFormData.shareholders.map((item) => {
        shares.push(item.totalShares);
        addresses.push(item.shareholderWallet);
      });

      assetContract
        .send("createAsset", {
          args: [
            landFormData.totalShares,
            hash,
            addresses,
            shares,
            landFormData.propertyNumber,
          ],
          account: process.env.REACT_APP_SENDER_ACCOUNT,
        })
        .then((result) => {
          setLoading(false);

          Swal.fire("Success", "NFT created.", "success").then((result) => {
            if (result.isConfirmed) {
              navigate("/assets");
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let shares = [];
      let addresses = [];

      carFormData.shareholders.map((item) => {
        shares.push(item.totalShares);
        addresses.push(item.shareholderWallet);
      });

      assetContract
        .send("createAsset", {
          args: [
            carFormData.totalShares,
            hash,
            addresses,
            shares,
            carFormData.registrationNumber,
          ],
          account: process.env.REACT_APP_SENDER_ACCOUNT,
        })
        .then((result) => {
          setLoading(false);

          Swal.fire("Success", "NFT created.", "success").then((result) => {
            if (result.isConfirmed) {
              navigate("/assets");
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/assets"
            aria-label="Assets"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Assets
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create Asset
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Create Asset</Typography>
      </Box>
      <Card>
        Fill in the information of the asset.
        <FormLabel id="assetType">Type</FormLabel>
        <ToggleButtonGroup
          value={assetType}
          onChange={(event, newValue) => {
            if (newValue != null) {
              setAssetType(newValue);
              console.log(newValue);
            }
          }}
        >
          <Button value="land">Land</Button>
          <Button value="car">Car</Button>
        </ToggleButtonGroup>
        <Divider />
        {assetType == "land" ? (
          <CreateLandAssetForm
            formData={landFormData}
            formDataErrors={formDataErrors}
            handleOnChange={landHandleOnChange}
          />
        ) : (
          <CreateCarAssetForm
            formData={carFormData}
            formDataErrors={formDataErrors}
            handleOnChange={carHandleOnChange}
          />
        )}
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              color="danger"
              onClick={() => navigate(-1)}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={() => {
                if (assetType == "land") {
                  pinFileToIPFS(landFormData);
                } else {
                  pinFileToIPFS(carFormData);
                }
              }}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Box>
  );
}
