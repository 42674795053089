//Public Layout
import Homepage from "./MainLayout/Homepage";
import Users from "./MainLayout/Users/Users";
import Assets from "./MainLayout/Assets/Assets";
import UserDetails from "./MainLayout/Users/UserDetails";
import DocumentDetails from "./MainLayout/Documents/DocumentDetails";
import AssetDetails from "./MainLayout/Assets/AssetDetails";
import CreateUser from "./MainLayout/Users/CreateUser";
import CreateAsset from "./MainLayout/Assets/CreateAsset";
import CreateDocument from "./MainLayout/Documents/CreateDocument";
import Notaries from "./MainLayout/Notaries/Notaries";
import DocumentTypes from "./MainLayout/DocumentTypes/DocumentTypes";
import Documents from "./MainLayout/Documents/Documents";

//Main Layout
import Error from "./PublicLayout/Error/Error";
import SignIn from "./PublicLayout/SignIn/SignIn";

export {
  Homepage,
  Error,
  SignIn,
  Users,
  UserDetails,
  DocumentDetails,
  Notaries,
  DocumentTypes,
  Documents,
  CreateUser,
  Assets,
  CreateAsset,
  CreateDocument,
  AssetDetails,
};
