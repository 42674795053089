import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import * as React from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {
  Card,
  CardActions,
  CardContent,
  CardCover,
  CircularProgress,
  Grid,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import { useNavigate } from "react-router-dom";
import { trimBcData } from "../../utils/util";
import loop from "../../assets/loop.mp4";
import { useTranslation } from "react-i18next";

export default function Homepage() {
  const { t, i18n } = useTranslation();
  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(process.env.REACT_APP_USER_CONTRACT_SHORT_ID);
  const contract2 = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );

  const navigate = useNavigate();
  const [data, setData] = React.useState(0);
  const [data2, setData2] = React.useState(0);

  function getUsers() {
    contract
      .call("getUsers")
      .then((result) => {
        console.log(result.data.length);
        setData(result.data.length);
        getBlockchainData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getBlockchainData = () => {
    contract2
      .call("getDocuments")
      .then((result) => {
        console.log(result.data.length);

        setData2(result.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {t("home")}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">{t("home")}</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Card variant="soft" color="neutral">
            <CardContent orientation="horizontal">
              <CardContent>
                <Typography level="body-md">
                  {t("total")} {t("documents")}
                </Typography>
                <Typography level="h2">
                  {data2 ? (
                    data2
                  ) : (
                    <CircularProgress determinate={false} size="sm" />
                  )}
                </Typography>
              </CardContent>
            </CardContent>
            <CardActions>
              <Button
                variant="soft"
                color="primary"
                size="sm"
                onClick={() => navigate("/documents")}
              >
                {t("seeMore")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card variant="soft" color="neutral">
            <CardContent orientation="horizontal">
              <CardContent>
                <Typography level="body-md">
                  {t("total")} {t("users")}
                </Typography>
                <Typography level="h2">
                  {data ? (
                    data
                  ) : (
                    <CircularProgress determinate={false} size="sm" />
                  )}
                </Typography>
              </CardContent>
            </CardContent>
            <CardActions>
              <Button
                variant="soft"
                color="primary"
                size="sm"
                onClick={() => navigate("/users")}
              >
                {t("seeMore")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card variant="soft" color="neutral">
            <CardContent orientation="horizontal">
              <CardContent>
                <Typography level="body-md">
                  {t("total")} {t("documentTypes")}
                </Typography>
                <Typography level="h2">9</Typography>
              </CardContent>
            </CardContent>
            <CardActions>
              <Button
                variant="soft"
                size="sm"
                onClick={() => navigate("/document-types")}
              >
                {t("seeMore")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card variant="soft" color="neutral">
            <CardContent orientation="horizontal">
              <CardContent>
                <Typography level="body-md">
                  {t("total")} {t("authorizedNotaries")}
                </Typography>
                <Typography level="h2">5</Typography>
              </CardContent>
            </CardContent>
            <CardActions>
              <Button
                variant="soft"
                size="sm"
                onClick={() => navigate("/notaries")}
              >
                {t("seeMore")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} md={12}>
          <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
            <CardCover>
              <video autoPlay loop muted>
                <source src={loop} type="video/mp4" />
              </video>
            </CardCover>
            <CardContent>
              <Typography
                level="body-lg"
                fontWeight="lg"
                textColor="#fff"
                mt={{ xs: 12, sm: 18 }}
              >
                <img
                  width={"128px"}
                  src="https://tubu.io/image/logo-blue.svg"
                  alt=""
                  style={{ filter: "brightness(0) invert(1)" }}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
