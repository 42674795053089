/* eslint-disable jsx-a11y/anchor-is-valid */
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import * as React from "react";

import { useNavigate } from "react-router-dom";

export default function AssetListList({
  listItems,
  setOpenSnackbar,
  setOpenStatus,
  setFormData,
}) {
  const navigate = useNavigate();
  const target = React.useRef(null);

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      {listItems.map((listItem) => (
        <List
          key={listItem.hash}
          size="sm"
          sx={{
            "--ListItem-paddingX": 0,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <ListItemContent
              sx={{ display: "flex", gap: 2, alignItems: "start" }}
            >
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {listItem.name}
                </Typography>
                <Typography level="body-xs" gutterBottom>
                  {listItem.form}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 0.5,
                    mb: 0.5,
                  }}
                >
                  <Link
                    level="body-xs"
                    component="button"
                    underline="none"
                    color="neutral"
                    ref={target}
                    onClick={() => {
                      navigator.clipboard.writeText(listItem.hash);
                      setOpenSnackbar(true);
                    }}
                  >
                    {listItem[1]}
                    <ContentCopyIcon sx={{ marginLeft: 1 }} color="primary" />
                  </Link>
                </Box>
                {localStorage.getItem("user").startsWith("notary") ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <Link
                      level="body-xs"
                      component="button"
                      underline="none"
                      color="neutral"
                      onClick={() => {
                        setOpenStatus(true);
                        setFormData({
                          hash: listItem.hash,
                          document_name: listItem.name,
                          document_type: listItem.form,
                          status: listItem.status,
                        });
                      }}
                    >
                      {listItem.status}
                      <SettingsIcon sx={{ marginLeft: 1 }} color="primary" />
                    </Link>
                  </Box>
                ) : (
                  ""
                )}

                <Typography level="body-xs" gutterBottom>
                  {new Date(listItem.createdDate * 1000).toLocaleString(
                    undefined,
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                      minute: "numeric",
                      hour: "numeric",
                    }
                  )}
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <Link
                    level="body-xs"
                    component="button"
                    underline="none"
                    color="primary"
                    onClick={() => navigate("/viewDocument/" + listItem.hash)}
                  >
                    View
                  </Link>
                </Box>
              </div>
            </ListItemContent>
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  );
}
