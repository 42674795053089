import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Card, CardActions, CardOverflow, Divider } from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CreateUserForm from "../../../components/Forms/CreateUserForm";
import { validate } from "../../../utils/validate";

export default function CreateUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const walletArray = [
    "0xc45fb24a6a8d4c26d5d9c6803f34845cdd579785",
    "0x15687e97c4f8f9b4cec4be3b66609e1244340926",
    "0xfc9d97596562be9504aed57500c63096e02c484a",
    "0x070fe0c04dc69ba20b8ec7ee86f2be9015ce22a4",
    "0x0ee27dd8ac089cd9731b8024bfa2f5ea6d4ca1db",
    "0xbd0f67dd1b5a51d4a924f3017e4cbb7d91b284de",
    "0xb7f3b95c91de62c0cdefe1a5138525e42671dbbd",
    "0x4840266e547b6e7bba9b808f78dab7f683352546",
    "0x29d15f86f08322609b783c510e398e8b5a765a82",
    "0xd88f34c67b5a4ffda06b9fbc462c9e9a37dcb8aa",
  ];
  const [userCount, setUserCount] = useState(0);
  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );
  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );

  const JWT = process.env.REACT_APP_PINATA_TOKEN;
  const [loading, setLoading] = useState(false);

  function getUsers() {
    userContract
      .call("getUsers")
      .then((result) => {
        console.log(result.data.length);
        setUserCount(result.data.length);
        handleOnChange(
          "userWallet",
          walletArray[parseInt(result.data.length) - 5]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  const initialFormData = {
    firstName: "",
    lastName: "",
    fathersName: "",
    mothersName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    gender: "",
    nationality: "",
    registryPlace: "",
    number: "",
    files: [],
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
    tripleValidation: "",
    userWallet: "",
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialFormDataValidate = {
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    fathersName: {
      required: true,
    },
    mothersName: {
      required: true,
    },
    dateOfBirth: {
      required: true,
    },
    placeOfBirth: {
      required: true,
    },
    gender: {
      required: true,
    },
    nationality: {
      required: true,
    },
    registryPlace: {
      required: true,
    },
    number: {
      required: true,
    },
    files: {
      required: true,
    },
    tripleValidation: {
      required: true,
    },
    userWallet: {
      required: true,
    },
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  const [formDataErrors, setFormDataErrors] = useState({});

  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  const handleOnChange = (name, value) => {
    console.log(formData);
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const pinFileToIPFS = async (data) => {
    const check = checkValidation();
    if (check) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", data?.files[0]);

      const pinataMetadata = JSON.stringify({
        name: data?.firstName + data?.lastName,
        type: "Create New User",
      });
      formData.append("pinataMetadata", pinataMetadata);

      const pinataOptions = JSON.stringify({
        cidVersion: 0,
      });
      formData.append("pinataOptions", pinataOptions);

      try {
        axios
          .post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
            maxBodyLength: "Infinity",
            headers: {
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              Authorization: `Bearer ${JWT}`,
            },
          })
          .then((res) => {
            createUser(res.data.IpfsHash);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const createUser = (hash) => {
    console.log(Object.values(formData));
    let array = Object.values(formData).slice(0, -6);
    array.push(hash);
    console.log(array);

    userContract
      .send("createUser", {
        args: [
          array,
          formData.nationalId,
          formData.passportId,
          formData.civilRegistryDocumentId,
          formData.userWallet,
        ],
        account: "0x918Be5A9A1421bdf4e49c9871eC34eDc6C3dde59",
      })
      .then((result) => {
        setLoading(false);

        assetContract
          .send("setApprovalForAll", {
            args: [process.env.REACT_APP_AGREEMENT_CONTRACT_ADDRESS, true],
            account: formData.userWallet,
          })
          .then((res) => {
            Swal.fire("Success", "User created.", "success").then((result) => {
              if (result.isConfirmed) {
                navigate("/users");
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/users"
            aria-label="Users"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Users
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create User
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Create User</Typography>
      </Box>
      <Card>
        Fill in the information of the user.
        <Divider />
        <CreateUserForm
          formData={formData}
          formDataErrors={formDataErrors}
          handleOnChange={handleOnChange}
        />
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              color="danger"
              onClick={() => navigate(-1)}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={() => pinFileToIPFS(formData)}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Box>
  );
}
