import { DocumentScanner } from "@mui/icons-material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import {
  CircularProgress,
  Snackbar,
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
} from "@mui/joy";

import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DocumentTable from "./DocumentTable";

export default function Documents() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );

  const [data, setData] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const getBlockchainData = () => {
    contract
      .call("getDocuments")
      .then((result) => {
        let objs = result.data.map((item, index) => ({
          id: index,
          batchNo: item[0],
          documentHash: item[1][0],
          title: item[1][1][0],
          documentType: item[1][1][1],
          agreementType: item[1][1][2],
          ipfs: item[1][1][3],
          from: item[1][4],
          to: item[1][5],
          nftUid: item[1][6],
          status: item[1][4],
          cUser: item[1][9],
          uNotary: item[1][10],
        }));

        setData(objs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBlockchainData();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {t("documents")}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">{t("documents")}</Typography>
        <Button
          color="primary"
          startDecorator={<DocumentScanner />}
          size="md"
          onClick={() => navigate("/documents/create")}
        >
          {t("createDocument")}
        </Button>
      </Box>
      {data?.length != 0 ? (
        <DocumentTable rows={data} setOpenSnackbar={setOpenSnackbar} />
      ) : (
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <CircularProgress determinate={false} size="sm" />
        </Box>
      )}

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        Success! Copied.
      </Snackbar>
    </Box>
  );
}
