import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Grid, IconButton } from "@mui/joy";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableContainer } from "@mui/material";

export default function UserTable({
  rows,
  setOpenSnackbar,
  handleOnSearchChange,
  search,
  searchFormData,
  setSearchFormData,
}) {
  const target = React.useRef(null);
  const navigate = useNavigate();
  const [currency, setCurrency] = React.useState("nationalId");

  React.useEffect(() => {
    setSearchFormData({
      nationalId: "",
      passportId: "",
      civilRegistryDocumentId: "",
    });
  }, [currency]);

  console.log(rows);
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
      </Sheet>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <Input
              placeholder="Search"
              value={
                currency == "nationalId"
                  ? searchFormData.nationalId
                  : currency == "passportId"
                  ? searchFormData.passportId
                  : searchFormData.civilRegistryDocumentId
              }
              onChange={(event) => {
                handleOnSearchChange(currency, event.target.value);
              }}
              endDecorator={
                <Button
                  variant="soft"
                  color="primary"
                  startDecorator={<SearchIcon />}
                  onClick={() => search()}
                >
                  Search
                </Button>
              }
              startDecorator={
                <React.Fragment>
                  <Select
                    variant="plain"
                    value={currency}
                    onChange={(_, value) => {
                      setCurrency(value);
                      handleOnSearchChange(value, "");
                    }}
                    slotProps={{
                      listbox: {
                        variant: "outlined",
                      },
                    }}
                  >
                    <Option value="nationalId">Search by National ID</Option>
                    <Option value="passportId">Search by Passport ID</Option>
                    <Option value="civilRegistryDocumentId">
                      Search by Civil Registry Document ID
                    </Option>
                  </Select>
                  <Divider orientation="vertical" />
                </React.Fragment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Card>
        <TableContainer>
          <Table
            borderAxis="xBetween"
            color="neutral"
            size="md"
            stickyFooter={false}
            stickyHeader={false}
            variant="plain"
            sx={{
              "& tr > *:not(:first-child)": { textAlign: "center" },
              minWidth: 650,
            }}
          >
            <thead>
              <tr>
                <th>National ID</th>
                <th>Passport ID</th>
                <th>Civil ID</th>
                <th>Name</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Nationality</th>
                <th>User Hash</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  <td>{row?.nationalId}</td>
                  <td>{row?.passportId}</td>
                  <td>{row?.civilRegistryDocumentId}</td>
                  <td>{row?.firstName + " " + row?.lastName}</td>

                  <td>
                    {new Date(row?.dateOfBirth).toLocaleString(undefined, {
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                    })}
                  </td>
                  <td>{row?.gender}</td>
                  <td>{row?.nationality}</td>
                  <td>
                    <Button
                      color="neutral"
                      size="sm"
                      sx={{ fontWeight: "normal" }}
                      onClick={() => {
                        navigator.clipboard.writeText(row?.userHash);
                        setOpenSnackbar(true);
                      }}
                      variant="plain"
                      startDecorator={<ContentCopyIcon />}
                    >
                      {row?.userHash?.slice(0, 5) +
                        "..." +
                        row?.userHash?.slice(-3)}
                    </Button>
                  </td>
                  <td>
                    <IconButton
                      size="sm"
                      onClick={() =>
                        navigate("/users/user/" + row.id, { state: row })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Card>
    </React.Fragment>
  );
}
