/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const listItems = [
  {
    id: 1,
    name: "Notary 1",
    hash: "63dad7dd9fda9df1825ab5607552c5bfa09950d39b33ab4a1c288f91b126f3d4",
    ip: "3.76.43.144:30304",
  },
  {
    id: 2,
    name: "Notary 2",
    hash: "6c249b7429c7f001e9fc8accc73000759b7210beee39d7930d8b34be72016d4c",
    ip: "18.184.72.47:30302",
  },
  {
    id: 3,
    name: "Notary 3",
    hash: "a865fb882b4314d77cf544f2b166bd7e671b73916c1f66a3e26f8f1de850bb2b",
    ip: "18.184.72.47:30300",
  },
  {
    id: 4,
    name: "Notary 4",
    hash: "eaff93c698158b64df10baa4181145b25e99fcf02c2dc5f0b2829594e6514b79",
    ip: "3.76.43.144:30305",
  },
  {
    id: 5,
    name: "Notary 5",
    hash: "f6c1dc18560da4f8a002c03cd5a06a5bf4eecc317f17af0b2f48ea70b621b851",
    ip: "3.76.43.144:30303",
  },
];

export default function NotariesList() {
  const navigate = useNavigate();
  const target = React.useRef(null);

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      {listItems.map((listItem) => (
        <List
          key={listItem.hash}
          size="sm"
          sx={{
            "--ListItem-paddingX": 0,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <ListItemContent
              sx={{ display: "flex", gap: 2, alignItems: "start" }}
            >
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {listItem.name}
                </Typography>

                <Typography fontWeight={400} gutterBottom>
                  {listItem.hash.slice(0, 5) + "..." + listItem.hash.slice(-5)}
                </Typography>
                <Typography fontWeight={500} gutterBottom>
                  {listItem.ip}
                </Typography>
              </div>
            </ListItemContent>
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  );
}
