import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Snackbar,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CreateUserForm from "../../../components/Forms/CreateUserForm";
import { validate } from "../../../utils/validate";
import UserList from "./UserList";
import UserTable from "./UserTable";

export default function Users() {
  const navigate = useNavigate();

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(process.env.REACT_APP_USER_CONTRACT_SHORT_ID);
  const JWT = process.env.REACT_APP_PINATA_TOKEN;
  const [data, setData] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialFormData = {
    firstName: "",
    lastName: "",
    fathersName: "",
    mothersName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    gender: "",
    nationality: "",
    registryPlace: "",
    number: "",
    files: [],
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
    tripleValidation: "",
    userWallet: "",
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialFormDataValidate = {
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    fathersName: {
      required: true,
    },
    mothersName: {
      required: true,
    },
    dateOfBirth: {
      required: true,
    },
    placeOfBirth: {
      required: true,
    },
    gender: {
      required: true,
    },
    nationality: {
      required: true,
    },
    registryPlace: {
      required: true,
    },
    number: {
      required: true,
    },
    files: {
      required: true,
    },
    tripleValidation: {
      required: true,
    },
    userWallet: {
      required: true,
    },
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  const [formDataErrors, setFormDataErrors] = useState({});

  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  const handleOnChange = (name, value) => {
    console.log(formData);
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSearchChange = (name, value) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function getUsers() {
    contract
      .call("getUsers")
      .then((result) => {
        let objs = result.data.map((item, index) => ({
          id: index,
          firstName: item[0][0],
          lastName: item[0][1],
          fathersName: item[0][2],
          mothersName: item[0][3],
          dateOfBirth: item[0][4],
          placeOfBirth: item[0][5],
          gender: item[0][6],
          nationality: item[0][7],
          registryPlace: item[0][8],
          number: item[0][9],
          files: item[0][10],
          userHash: item[1],
          nationalId: item[2],
          passportId: item[3],
          civilRegistryDocumentId: item[4],
          userWallet: item[5],
        }));

        setData(objs);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  const pinFileToIPFS = async (data) => {
    const check = checkValidation();
    if (check) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", data?.files[0]);

      const pinataMetadata = JSON.stringify({
        name: data?.firstName + data?.lastName,
        type: "Create New User",
      });
      formData.append("pinataMetadata", pinataMetadata);

      const pinataOptions = JSON.stringify({
        cidVersion: 0,
      });
      formData.append("pinataOptions", pinataOptions);

      try {
        axios
          .post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
            maxBodyLength: "Infinity",
            headers: {
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              Authorization: `Bearer ${JWT}`,
            },
          })
          .then((res) => {
            createUser(res.data.IpfsHash);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const search = () => {
    contract
      .call("searchUser", [
        searchFormData.nationalId,
        searchFormData.passportId,
        searchFormData.civilRegistryDocumentId,
      ])
      .then((result) => {
        let objs = [result.data].map((item, index) => ({
          id: index,
          firstName: item[0][0],
          lastName: item[0][1],
          fathersName: item[0][2],
          mothersName: item[0][3],
          dateOfBirth: item[0][4],
          placeOfBirth: item[0][5],
          gender: item[0][6],
          nationality: item[0][7],
          registryPlace: item[0][8],
          number: item[0][9],
          files: item[0][10],
          userHash: item[1],
          nationalId: item[2],
          passportId: item[3],
          civilRegistryDocumentId: item[4],
          userWallet: item[5],
        }));

        setData(objs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createUser = (hash) => {
    console.log(Object.values(formData));
    let array = Object.values(formData).slice(0, -6);
    array.push(hash);
    console.log(array);

    contract
      .send("createUser", {
        args: [
          array,
          formData.nationalId,
          formData.passportId,
          formData.civilRegistryDocumentId,
          formData.userWallet,
        ],
        account: "0x8Ec3B326C9655242CA50Fc447083C28ee29f49A7",
      })
      .then((result) => {
        setOpenUpload(false);
        setLoading(false);

        Swal.fire("Success", "User created.", "success").then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Users
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Users</Typography>
        <Button
          color="primary"
          startDecorator={<AccountCircleIcon />}
          size="md"
          onClick={() => navigate("/users/create")}
        >
          Create User
        </Button>
      </Box>
      {data?.length != 0 ? (
        <UserTable
          rows={data}
          setOpenSnackbar={setOpenSnackbar}
          handleOnSearchChange={handleOnSearchChange}
          searchFormData={searchFormData}
          setSearchFormData={setSearchFormData}
          search={search}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <CircularProgress determinate={false} size="sm" />
        </Box>
      )}

      <UserList
        listItems={data}
        setOpenSnackbar={setOpenSnackbar}
        setOpenStatus={setOpenStatus}
        setFormData={setFormData}
      />

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        Success! Copied.
      </Snackbar>
    </Box>
  );
}
