/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import * as React from "react";
// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Card } from "@mui/joy";
import { TableContainer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DocumentTable({ rows, setOpenSnackbar }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("desc");
  const target = React.useRef(null);
  const navigate = useNavigate();
  console.log(rows);
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for document</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
      </Box>
      <Card>
        <TableContainer>
          <Table
            borderAxis="xBetween"
            color="neutral"
            size="md"
            stickyFooter={false}
            stickyHeader={false}
            variant="plain"
            sx={{
              "& tr > *:not(:first-child)": { textAlign: "center" },
              minWidth: 650,
            }}
          >
            <thead>
              <tr>
                <th>{t("title")}</th>
                <th>{t("batchNo")}</th>
                <th>{t("agreementType")}</th>
                <th>{t("documentType")}</th>
                <th>{t("view")}</th>
              </tr>
            </thead>
            <tbody>
              {stableSort(rows, getComparator(order, "id")).map((row) => (
                <tr key={row._hash}>
                  <td>{row.title}</td>
                  <td>
                    <Button
                      color="neutral"
                      size="sm"
                      sx={{ fontWeight: "normal" }}
                      onClick={() => {
                        navigator.clipboard.writeText(row.batchNo);
                        setOpenSnackbar(true);
                      }}
                      variant="plain"
                      startDecorator={<ContentCopyIcon />}
                    >
                      {row.batchNo.slice(0, 5) + "..." + row.batchNo.slice(-5)}
                    </Button>
                  </td>

                  <td>{row.documentType}</td>
                  <td>{row.agreementType}</td>
                  <td>
                    <IconButton
                      size="sm"
                      onClick={() =>
                        navigate("/documents/document/" + row.id, {
                          state: row,
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Card>
    </React.Fragment>
  );
}
