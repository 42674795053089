import { createTheme } from "@mui/material/styles";
export const Colors = {
  // green
  red: "#EB455F",
  darkBlue: "#2B3467",
  lightBlue: "#0F6292",
  cream: "#FCFFE7",
  white: "#FFFFFF",
  black: "#000000",
};

let theme = createTheme({});

theme = createTheme(theme, {
  palette: {
    primary: {
      red: Colors.red,
      darkBlue: Colors.darkBlue,
      lightBlue: Colors.lightBlue,
      cream: Colors.cream,
      white: Colors.white,
      black: Colors.black,
    },
    background: {
      default: "#F0EEED",
      paper: "#fafafa",
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px !important",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: Colors.white,
          background: Colors.lightBlue,
        },
        contained: {
          color: Colors.white,
          background: Colors.lightBlue,
          "&:hover": { background: Colors.lightBlue },
          "&:active": {},
          "&:disabled": {},
        },
        text: {
          color: Colors.white,
          background: "transparent",
          "&:hover": { background: "transparent" },
          "&:active": {},
          "&:disabled": {},
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          background: "white",
          boxShadow: 1,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: { color: "black !important" },
      },
    },
  },
});

export default theme;
