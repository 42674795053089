/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TwoSidedLayout from "./TwoSidedLayout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HeroLeft01() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <TwoSidedLayout>
      <Typography color="danger" fontSize="lg" fontWeight="lg">
        {t("landing1")}
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        {t("landing2")}
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        {t("landing3")}
      </Typography>
      <Button
        variant="solid"
        color="danger"
        onClick={() => {
          navigate("/signin");
        }}
        size="lg"
        endDecorator={<ArrowForward fontSize="xl" />}
      >
        {t("getStarted")}
      </Button>
      <Typography>
        {t("landing4")}{" "}
        <Link
          color="danger"
          fontWeight="lg"
          onClick={() => {
            navigate("/signin");
          }}
        >
          {t("signIn")}
        </Link>
      </Typography>
    </TwoSidedLayout>
  );
}
