import axios from "axios";

const API_URL = process.env.REACT_APP_GAZBIRFAZ1_API_URL;
const TYS_API_URL = process.env.REACT_APP_TYS_API_URL;

/**
 * @param {string} method the HTTP verb you want to use
 * @param {string} path the route path / endpoint
 * @param {object} data (optional) data in JSON form for POST requests
 * @param {object} params (optional) data in the url for GET requests
 */

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return "";
};

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["authorizationTicket"] = token;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

export const apiCall = async (method, path, data, params, responseType) => {
  const res = await axios({
    method,
    url: API_URL + path,
    data,
    params,
    responseType,
  });
  return res;
};

export const tysApiCall = async (
  method,
  path,
  data,
  headers,
  params,
  responseType
) => {
  const res = await axios({
    method,
    url: TYS_API_URL + path,
    data,
    headers,
    params,
    responseType,
  });
  return res;
};

export const trimBcData = (data) => {
  const newArray = data.map((item) =>
    Object.fromEntries(
      Object.entries(item.returnValues).slice(
        Object.entries(item.returnValues).length / 2
      )
    )
  );
  return newArray;
};

export const aggregateBcDataAndTysData = (bcData, tysData) => {
  let newBcData = [];

  for (const item of bcData) {
    newBcData = [
      ...newBcData,
      {
        firmaId: item._company[0],
        firmaAdi: item._company[1],
        firmaUnvani: item._company[2],
        sartnameAdi: item._specification[1],
        sertifikaTarihi: item._createdDate,
        vizeGecerlilikTarihi: item._expiredDate,
        certificateId: item._certificate_id,
        status: item._status,
      },
    ];
  }

  let innerTysData = tysData;

  for (const item of newBcData) {
    if (
      !tysData?.find(
        (e) =>
          e.firmaAdi === item?.firmaAdi &&
          e.firmaUnvani === item?.firmaUnvani &&
          e.sartnameAdi === item?.sartnameAdi &&
          e.sertifikaTarihi === item?.sertifikaTarihi &&
          e.vizeGecerlilikTarihi === item?.vizeGecerlilikTarihi
      )
    ) {
      innerTysData = [{ ...item, tysStatus: "BC" }, ...innerTysData];
    } else {
    }
  }
  return innerTysData;
};

export const findCompanyId = (data, name) => {
  const filteredData = data?.find((e) => e.adi === name);
  return filteredData?.id;
};

export const openSidebar = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
};

export const closeSidebar = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
};

export const toggleSidebar = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};
