import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
  Table,
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  IconButton,
} from "@mui/joy";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DocumentDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  function getDocument() {
    fetch(
      "https://" + location.state.ipfs + ".ipfs.nftstorage.link/metadata.json"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setData(JSON.parse(responseJson.description));
        console.log(JSON.parse(responseJson.description));
      });
  }

  useEffect(() => {
    getDocument();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/documents"
            aria-label="Documents"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              {t("documents")}
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {t("documentDetails")}
          </Typography>
        </Breadcrumbs>
      </Box>
      {data.length != 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Typography level="h2">
              {t("documentDetails")}: {location.state.title}
            </Typography>
          </Box>
          <Card>
            {t("documentDetails2")}
            <Divider />
            <Grid container>
              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("title")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {location.state.title}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("nftID")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {data?._nftUid}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("documentType")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {data?._documentType}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("agreementType")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {data?._agreementType}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("createdBy")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {data?._cUser}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6} md={3}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                  <FormLabel>{t("updatedBy")}</FormLabel>
                  <Typography level="h2" fontSize="xl">
                    {data?._uNotary}
                  </Typography>
                </FormControl>
              </Grid>

              {data?._documentType == "EDNAC" && (
                <Grid xs={12} sm={6} md={3}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <FormLabel>{t("viewDocument")}</FormLabel>
                    <Typography level="h2" fontSize="xl">
                      <IconButton
                        color="black"
                        onClick={() =>
                          window.open(
                            "/viewDocument/" + location.state.documentHash,
                            "_blank"
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Typography>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Card>
          <Typography level="h4" sx={{ mt: 2 }}>
            {t("shareholders")}
          </Typography>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid lg={6}>
              {data?.length != 0 && (
                <Card>
                  <TableContainer>
                    <Table
                      borderAxis="xBetween"
                      color="neutral"
                      size="md"
                      stickyFooter={false}
                      stickyHeader={false}
                      variant="plain"
                      sx={{
                        "& tr > *:not(:first-child)": { textAlign: "center" },
                        minWidth: 650,
                        minWidth: 650,
                      }}
                    >
                      <caption
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        From
                      </caption>
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("wallet")}</th>
                          {data?._documentType == "PDNAC" && (
                            <th>{t("shares")}</th>
                          )}
                          {data?._documentType == "PDNAC" && (
                            <th>{t("viewPDNACDocument")}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.shareholders?.map((row) => (
                          <tr>
                            <td>
                              {row?.shareholderFirstname +
                                " " +
                                row?.shareholderLastname}
                            </td>
                            <td>
                              <Button
                                color="neutral"
                                size="sm"
                                sx={{ fontWeight: "normal" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    row?.shareholderWallet
                                  );
                                  setOpenSnackbar(true);
                                }}
                                variant="plain"
                                startDecorator={<ContentCopyIcon />}
                              >
                                {row?.shareholderWallet.slice(0, 10) +
                                  "..." +
                                  row?.shareholderWallet.slice(-10)}
                              </Button>
                            </td>
                            {data?._documentType == "PDNAC" && (
                              <td>{row?.totalShares}</td>
                            )}

                            {data?._documentType == "PDNAC" && (
                              <td>
                                <IconButton
                                  size="sm"
                                  onClick={() =>
                                    window.open(
                                      "/viewAsset/" +
                                        data?._nftUid +
                                        "?uh=" +
                                        row?.userHash +
                                        "&uw=" +
                                        row?.shareholderWallet,
                                      "_blank"
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Card>
              )}
            </Grid>
            <Grid lg={6}>
              {data?.length != 0 && (
                <Card>
                  <TableContainer>
                    <Table
                      borderAxis="xBetween"
                      color="neutral"
                      size="md"
                      stickyFooter={false}
                      stickyHeader={false}
                      variant="plain"
                      sx={{
                        "& tr > *:not(:first-child)": { textAlign: "center" },
                        minWidth: 650,
                        minWidth: 650,
                      }}
                    >
                      <caption
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        To
                      </caption>
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>{t("wallet")}</th>
                          {data?._documentType == "PDNAC" && (
                            <th>{t("shares")}</th>
                          )}
                          {data?._documentType == "PDNAC" && (
                            <th>{t("viewPDNACDocument")}</th>
                          )}
                          {data?._documentType == "EDNAC" && (
                            <th>{t("viewEDNACDocument")}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.toShareholders?.map((row) => (
                          <tr>
                            <td>
                              {row?.shareholderFirstname +
                                " " +
                                row?.shareholderLastname}
                            </td>
                            <td>
                              <Button
                                color="neutral"
                                size="sm"
                                sx={{ fontWeight: "normal" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    row?.shareholderWallet
                                  );
                                  setOpenSnackbar(true);
                                }}
                                variant="plain"
                                startDecorator={<ContentCopyIcon />}
                              >
                                {row?.shareholderWallet.slice(0, 10) +
                                  "..." +
                                  row?.shareholderWallet.slice(-10)}
                              </Button>
                            </td>
                            {data?._documentType == "PDNAC" && (
                              <td>{row?.totalShares}</td>
                            )}

                            {data?._documentType == "PDNAC" && (
                              <td>
                                <IconButton
                                  size="sm"
                                  onClick={() =>
                                    window.open(
                                      "/viewAsset/" +
                                        data?._nftUid +
                                        "?uh=" +
                                        row?.userHash +
                                        "&uw=" +
                                        row?.shareholderWallet,
                                      "_blank"
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </td>
                            )}

                            {data?._documentType == "EDNAC" && (
                              <td>
                                <IconButton
                                  size="sm"
                                  onClick={() =>
                                    window.open(
                                      "/viewDocument/" +
                                        location.state.documentHash +
                                        "?uh=" +
                                        row?.userHash,
                                      "_blank"
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableContainer>
                </Card>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            {t("dismiss")}
          </Button>
        }
      >
        {t("copied")}
      </Snackbar>
    </Box>
  );
}
