import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Card, CardActions, CardOverflow, Divider } from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CreateUserForm from "../../../components/Forms/CreateUserForm";
import { validate } from "../../../utils/validate";
import CreateDocumentForm from "../../../components/Forms/CreateDocumentForm";
import { NFTStorage } from "nft.storage";

export default function CreateDocument() {
  const navigate = useNavigate();
  const location = useLocation();

  const JWT = process.env.REACT_APP_PINATA_TOKEN;

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const aggreementContract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );
  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );
  const agreementContract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchString, setSearchString] = React.useState("");
  const [data, setData] = React.useState([]);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fromUserData, setFromUserData] = React.useState();
  const [parentUserHash, setParentUserHash] = React.useState();
  const [fromUserAssetsData, setFromUserAssetsData] = React.useState([]);
  const [toUserData, setToUserData] = React.useState();
  const [balanceOfShares, setBalanceOfShares] = React.useState();
  const [searchResult, setSearchResult] = React.useState();
  const [searchUserType, setSearchUserType] = React.useState("");

  const initialFormData = {
    _title: "",
    _documentType: "",
    _agreementType: "",
    _from: "",
    _to: "",
    _nftUid: "",
    _ipfs: "",
    _cUser: localStorage.getItem("user"),
    _uNotary: localStorage.getItem("user"),
    files: [],
    shareholders: [],
    toShareholders: [],
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialStatusFormData = {
    batchNo: "",
    _status: "",
    _uNotary: localStorage.getItem("user"),
  };

  const initialFormDataValidate = {
    _documentType: {
      required: true,
    },
    _agreementType: {
      required: true,
    },
    _nftUid: {
      required: true,
    },
    files: {
      required: true,
    },
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [statusFormData, setStatusFormData] = useState({
    ...initialStatusFormData,
  });

  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });
  const [formDataErrors, setFormDataErrors] = useState({});

  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnSearchChange = (name, value) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      console.log(errorMsgs);
      return false;
    }
    return true;
  };

  const pinFileToIPFS = async (data) => {
    const nftstorage = new NFTStorage({
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDYxQjFCMEE1ZTY1MEE5N2MxYWY1OTU1QjU3YzU1MkQzOTBmMTkzNTciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNjEwNTkyNzA1OSwibmFtZSI6InR1YnUifQ.n-mhJNsY8xRJns3KgbsVTI7I3Pcu7GOGnQrLB1Ae6TE",
    });

    const check = checkValidation();
    console.log(check);

    if (check) {
      setLoading(true);
      try {
        nftstorage
          .store({
            image: data?.files[0],
            name: data._title,
            description: JSON.stringify(data),
          })
          .then((res) => {
            console.log(res);
            createDocument(res.ipnft);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const createDocument = (ipfs) => {
    let fromAddresses = [];
    let fromShares = [];
    let toAddresses = [];
    let toShares = [];
    let toGrants = [];
    let fromGrants = [];

    if (formData?._documentType == "EDNAC") {
      formData.shareholders.map((item) => {
        console.log(item);
        fromAddresses.push(item.userHash);
        fromGrants.push(item.userHash);
        fromShares.push(0);
      });

      formData.toShareholders.map((item) => {
        console.log(item);
        toAddresses.push(item.userHash);
        toGrants.push(item.userHash);
        toShares.push(0);
      });
    } else {
      formData.shareholders.map((item) => {
        console.log(item);
        fromAddresses.push(item.userHash);
        fromGrants.push(item.fromGrant);
        fromShares.push(item.totalShares);
      });

      formData.toShareholders.map((item) => {
        console.log(item);
        toAddresses.push(item.userHash);
        toGrants.push(item.toGrant);
        toShares.push(item.totalShares);
      });
    }

    console.log(formData, ipfs);

    formData._ipfs = ipfs;
    delete formData.files;
    aggreementContract
      .send("createDocument", {
        args: [
          [
            formData?._title,
            formData?._agreementType,
            formData?._documentType,
            ipfs,
          ],
          [
            fromAddresses,
            fromShares,
            toAddresses,
            toShares,
            fromGrants,
            toGrants,
          ],
          formData?._nftUid,
          [formData?._cUser, formData?._uNotary, Date.parse(new Date())],
        ],
        account: "0x6406255922015bBD9a6034C5CbfF1A6188EEF804",
      })
      .then((result) => {
        setOpenUpload(false);
        setLoading(false);

        Swal.fire("Success", "Document created.", "success").then((result) => {
          if (result.isConfirmed) {
            navigate("/documents");
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const search = (e) => {
    e.preventDefault();
    setFromUserData();
    setToUserData();
    setSearchResult();
    userContract
      .call("searchUser", [
        searchFormData.nationalId,
        searchFormData.passportId,
        searchFormData.civilRegistryDocumentId,
      ])
      .then((result) => {
        if (
          result.data[1] ==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          setFromUserData("error");
          setToUserData("error");
        } else {
          setSearchResult(result.data);
          agreementContract
            .call("controlUserGrantsAndBalance", [
              result.data[1],
              formData._nftUid,
            ])
            .then((balance) => {
              console.log(balance);

              let owned = balance.data.filter(
                (item) => item[1] == result.data[1]
              );
              let ednac = balance.data.filter(
                (item) => item[1] != result.data[1]
              );

              setFromUserData(balance.data);
              setParentUserHash(
                balance.data.filter((item) => item[4] == "PDNAC")[0][1]
              );

              /* let objs = [result.data].map((item, index) => ({
                id: index,
                firstName: item[0][0],
                lastName: item[0][1],
                fathersName: item[0][2],
                mothersName: item[0][3],
                dateOfBirth: item[0][4],
                placeOfBirth: item[0][5],
                gender: item[0][6],
                nationality: item[0][7],
                registryPlace: item[0][8],
                number: item[0][9],
                files: item[0][10],
                userHash: item[1],
                nationalId: item[2],
                passportId: item[3],
                civilRegistryDocumentId: item[4],
                userWallet: item[5],
                balanceOfShares: balance.data,
              }));

              console.log(objs[0]);

              handleOnShareholderChange("user", objs[0]);
              setFromUserData(objs[0]); */
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialShareholderFormData = {
    user: {},
    totalShares: "",
  };

  const [shareholderFormData, setShareholderFormData] = useState({
    ...initialShareholderFormData,
  });

  const handleOnShareholderChange = (name, value) => {
    console.log(shareholderFormData);

    setShareholderFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/documents"
            aria-label="Documents"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Documents
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Create Document
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Create Document</Typography>
      </Box>
      <Card>
        Fill in the information of the document.
        <Divider />
        <CreateDocumentForm
          formData={formData}
          formDataErrors={formDataErrors}
          handleOnChange={handleOnChange}
          handleOnSearchChange={handleOnSearchChange}
          searchFormData={searchFormData}
          setSearchFormData={setSearchFormData}
          search={search}
          fromUserData={fromUserData}
          setFromUserData={setFromUserData}
          searchResult={searchResult}
          parentUserHash={parentUserHash}
          toUserData={toUserData}
          setSearchUserType={setSearchUserType}
          searchUserType={searchUserType}
          fromUserAssetsData={fromUserAssetsData}
          shareholderFormData={shareholderFormData}
          setShareholderFormData={setShareholderFormData}
          handleOnShareholderChange={handleOnShareholderChange}
        />
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button
              size="sm"
              variant="outlined"
              color="danger"
              onClick={() => navigate(-1)}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={() => pinFileToIPFS(formData)}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Box>
  );
}
