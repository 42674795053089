import * as React from "react";
import { useColorScheme } from "@mui/joy/styles";
import IconButton from "@mui/joy/IconButton";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";

export default function LanguageChanger({ onClick, sx, ...props }) {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  const { t, i18n } = useTranslation();

  console.log(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return (
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        {...props}
        sx={sx}
        disabled
      />
    );
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...props}
      onClick={(event) => {
        if (i18n.language.startsWith("en")) {
          changeLanguage("ar");
        } else {
          changeLanguage("en");
        }
        onClick?.(event);
      }}
      sx={[
        {
          "& > *:first-child": {
            display: mode === "dark" ? "none" : "initial",
          },
          "& > *:last-child": {
            display: mode === "light" ? "none" : "initial",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <TranslateIcon />
      <TranslateIcon />
    </IconButton>
  );
}
