/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, IconButton, Link } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableContainer } from "@mui/material";

// icons

export default function AssetTable({ rows, setOpenSnackbar, user }) {
  const [carArray, setCarArray] = React.useState([]);
  const [landArray, setLandArray] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (rows) {
      rows?.forEach((obj) => {
        switch (JSON.parse(obj.description)?.data?.documentType) {
          case "land":
            console.log(obj);
            setLandArray((prevArray) => [...prevArray, obj]);
            console.log(obj);
            break;
          case "car":
            setCarArray((prevArray) => [...prevArray, obj]);
            console.log(obj);

            break;

          default:
            break;
        }
      });
    }
  }, [rows]);

  console.log(carArray, landArray);
  return (
    <React.Fragment>
      {carArray.length != 0 && (
        <Card>
          <TableContainer>
            <Table
              borderAxis="xBetween"
              color="neutral"
              size="md"
              stickyFooter={false}
              stickyHeader={false}
              variant="plain"
              sx={{
                "& tr > *:not(:first-child)": { textAlign: "center" },
                minWidth: 650,
              }}
            >
              <caption
                style={{ fontSize: 16, fontWeight: "bold", textAlign: "start" }}
              >
                Car Assets
              </caption>
              <thead>
                <tr>
                  <th>Registration Number</th>
                  <th>Engine Number</th>
                  <th>Vehicle Identification Number (VIN)</th>
                  <th>Color</th>
                  <th>Model Year</th>
                  <th>Model Type</th>
                  <th>Car Brand</th>
                  <th>View Asset Document</th>
                </tr>
              </thead>
              <tbody>
                {carArray.length != 0 ? (
                  carArray.map((row) => (
                    <tr key={row.id}>
                      <td>
                        {JSON.parse(row.description)?.data?.registrationNumber}
                      </td>
                      <td>{JSON.parse(row.description)?.data?.engineNumber}</td>

                      <td>{JSON.parse(row.description)?.data?.vin}</td>
                      <td>{JSON.parse(row.description)?.data?.color}</td>
                      <td>{JSON.parse(row.description)?.data?.modelYear}</td>

                      <td>{JSON.parse(row.description)?.data?.modelType}</td>

                      <td>{JSON.parse(row.description)?.data?.carBrand}</td>
                      <td>
                        <IconButton
                          size="sm"
                          onClick={() =>
                            window.open(
                              "/viewAsset/" +
                                row.id +
                                "?uh=" +
                                user.userHash +
                                "&uw=" +
                                user.userWallet,
                              "_blank"
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>no data </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableContainer>
        </Card>
      )}

      {landArray.length != 0 && (
        <Card>
          <TableContainer>
            <Table
              borderAxis="xBetween"
              color="neutral"
              size="md"
              stickyFooter={false}
              stickyHeader={false}
              variant="plain"
              sx={{
                "& tr > *:not(:first-child)": { textAlign: "center" },
                minWidth: 650,
              }}
            >
              <caption
                style={{ fontSize: 16, fontWeight: "bold", textAlign: "start" }}
              >
                Land Assets
              </caption>
              <thead>
                <tr>
                  <th>Property Number</th>
                  <th>Address</th>
                  <th>View Asset Document</th>
                </tr>
              </thead>
              <tbody>
                {landArray.length != 0 ? (
                  landArray.map((row) => (
                    <tr key={row.id}>
                      <td>
                        {JSON.parse(row.description)?.data?.propertyNumber}
                      </td>
                      <td>{JSON.parse(row.description)?.data?.address}</td>
                      <td>
                        <IconButton
                          size="sm"
                          onClick={() =>
                            window.open(
                              "/viewAsset/" +
                                row.id +
                                "?uh=" +
                                user.userHash +
                                "&uw=" +
                                user.userWallet,
                              "_blank"
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>no data </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableContainer>
        </Card>
      )}

      {carArray.length == 0 && landArray.length == 0 && (
        <Card sx={{ justifyContent: "center", alignItems: "center" }}>
          No data
        </Card>
      )}
    </React.Fragment>
  );
}
