/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

const listItems = [
  {
    id: 1,
    name: "Employment Contract",
    hash: "QmPgYnGFycpLv1xLkagkQQRZuctq2StacnLWDoaww6RtkV",
  },
  {
    id: 2,
    name: "Lease Agreement",
    hash: "Qmc26JiEamvZ6a6G5woK2onDMdiBytguCyEdRQrNz9BfXq",
  },
  {
    id: 3,
    name: "Sales Contract",
    hash: "QmUUEs9eZiFb5SAevG7kaXWnhXDC5s5G64B8SzAcqBEgoh",
  },
  {
    id: 4,
    name: "Service Contract",
    hash: "Qmcu5rA5jaxU6brnynmAGqLqAN1tKUhTuLUTJwL1W3ufsY",
  },
  {
    id: 5,
    name: "Partnership Agreement",
    hash: "QmZGAw3FWoVJvaojaMoADkkd3BcPQnsMgxEd33Ui2rLDec",
  },
  {
    id: 6,
    name: "Power of Attorney",
    hash: "QmammYuSue6kS8UqNfdPqPGwcajNdYu3yHAdR97wcbMyKe",
  },
  {
    id: 7,
    name: "Non-Disclosure Agreement (NDA)",
    hash: "QmWdboeetE3dW3WcSKLuEC526UieqJqkJWS2cppAFGmQLx",
  },
  {
    id: 8,
    name: "Independent Contractor Agreement",
    hash: "QmT2m42hPX61L1DKk43twWXKPbu11YkZ9cY6xZbePkmK3q",
  },
  {
    id: 9,
    name: "Acknowledgment",
    hash: "QmPxQZueUnGE1vSFW9QzcmVBMnsZzNwThkGAdghPfPFrSE",
  },
];

export default function DocumentTypesList() {
  const navigate = useNavigate();
  const target = React.useRef(null);

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      {listItems.map((listItem) => (
        <List
          key={listItem.hash}
          size="sm"
          sx={{
            "--ListItem-paddingX": 0,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <ListItemContent
              sx={{ display: "flex", gap: 2, alignItems: "start" }}
            >
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {listItem.name}
                </Typography>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <Link
                    level="body-xs"
                    underline="none"
                    color="primary"
                    href={"https://ipfs.io/ipfs/" + listItem.hash}
                    target="_blank"
                  >
                    View Sample
                  </Link>
                </Box>
              </div>
            </ListItemContent>
          </ListItem>
          <ListDivider />
        </List>
      ))}
    </Box>
  );
}
