import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Divider, FormHelperText } from "@mui/joy";
import { useTranslation } from "react-i18next";

// ========================================================

// ========================================================

const DropZone = ({
  onChange,
  title = "Drag & drop file here",
  imageSize = "Upload 280*280 image",
  error,
  helperText,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => onChange(acceptedFiles),
    [onChange]
  );
  const { t, i18n } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 10,
    multiple: true,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
    },
  });
  return (
    <>
      <Box
        py={4}
        px={{
          md: 10,
          xs: 4,
        }}
        display="flex"
        minHeight="200px"
        height="200px"
        alignItems="center"
        borderRadius="10px"
        border="1.5px dashed"
        flexDirection="column"
        borderColor={error ? "red" : "grey.100"}
        justifyContent="center"
        textAlign="center"
        bgcolor={isDragActive ? "grey.200" : "grey.100"}
        sx={{
          transition: "all 250ms ease-in-out",
          outline: "none",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <h5 mb={1} color="grey.600">
          {t("dragDrop")}
        </h5>
        <Divider
          sx={{
            "::before, ::after": {
              borderColor: "grey.300",
              width: 70,
            },
          }}
        >
          <span color="text.disabled" px={1}>
            OR
          </span>
        </Divider>
        <Button
          type="button"
          variant="outlined"
          color="info"
          sx={{
            px: 4,
            my: 4,
          }}
        >
          {t("selectFiles")}
        </Button>
        <span color="grey.600"></span>
      </Box>
      <FormHelperText style={{ color: "red" }}>
        {error ? helperText : " "}
      </FormHelperText>{" "}
    </>
  );
};
export default DropZone;
