import { useRoutes } from "react-router-dom";
import DocumentPDF from "../components/PDF/DocumentPDF";
import { MainLayout, PublicLayout } from "../layouts";
import {
  Error,
  Homepage,
  SignIn,
  UserDetails,
  Users,
  Notaries,
  DocumentTypes,
  Documents,
  CreateUser,
  Assets,
  CreateAsset,
  CreateDocument,
  AssetDetails,
  DocumentDetails,
} from "../pages";
import LandingPage from "../pages/PublicLayout/LandingPage/LandingPage";
import AssetPDF from "../components/PDF/AssetPDF";

export default function Router() {
  let mainRoutes = useRoutes([
    {
      element: <PublicLayout />,
      children: [
        { path: "*", element: <Error /> },
        { path: "/", element: <LandingPage /> },
        { path: "/signin", element: <SignIn /> },
        {
          path: "/viewDocument/:id",
          element: <DocumentPDF />,
        },
        {
          path: "/viewAsset/:id",
          element: <AssetPDF />,
        },
      ],
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: "/home",
          element: <Homepage />,
        },
        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/assets",
          element: <Assets />,
        },
        {
          path: "/assets/create",
          element: <CreateAsset />,
        },
        {
          path: "/users/create",
          element: <CreateUser />,
        },
        {
          path: "/users/user/:id",
          element: <UserDetails />,
        },
        {
          path: "/assets/asset/:id",
          element: <AssetDetails />,
        },
        {
          path: "/documents/document/:id",
          element: <DocumentDetails />,
        },
        {
          path: "/document-types",
          element: <DocumentTypes />,
        },
        {
          path: "/notaries",
          element: <Notaries />,
        },
        {
          path: "/documents",
          element: <Documents />,
        },
        {
          path: "/documents/create",
          element: <CreateDocument />,
        },
      ],
    },
  ]);

  return mainRoutes;
}
