import { InfoOutlined } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropZone from "../DropZone/DropZone";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const CreateDocumentForm = ({
  formData,
  formDataErrors,
  handleOnChange,
  handleOnSearchChange,
  search,
  fromUserData,
  setFromUserData,
  toUserData,
  searchUserType,
  searchFormData,
  setSearchFormData,
  setSearchUserType,
  fromUserAssetsData,
  shareholderFormData,
  setShareholderFormData,
  handleOnShareholderChange,
  parentUserHash,
  searchResult,
}) => {
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState([]);
  const [currency, setCurrency] = React.useState("nationalId");
  const navigate = useNavigate();
  const [shareholders, setShareholders] = useState([]);
  const [toShareholders, setToShareholders] = useState([]);
  const [data, setData] = useState([]);
  const [assetData, setAssetData] = useState();
  const [selectedAssetData, setSelectedAssetData] = useState();

  const [shareholderModal, setShareholderModal] = useState(false);
  const [assetModal, setAssetModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [maxValue, setMaxValue] = useState();
  const [loading, setLoading] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);

  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );

  useEffect(() => {
    setSearchFormData({
      nationalId: "",
      passportId: "",
      civilRegistryDocumentId: "",
    });
  }, [currency]);

  const initialShareholderFormData = {
    user: {},
    totalShares: "",
  };

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  useEffect(() => {
    handleOnChange("shareholders", shareholders);
  }, [shareholders]);

  useEffect(() => {
    handleOnChange("toShareholders", toShareholders);
  }, [toShareholders]);

  const unlockUser = async (row) => {
    console.log(shareholderFormData.user);
    if (searchUserType == "from") {
      assetContract
        .send("unlock", {
          args: [row?.shareholderWallet, parseInt(formData._nftUid)],
          account: process.env.REACT_APP_SENDER_ACCOUNT,
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addShareholder = () => {
    setShareholderModal(false);
    setShareModal(false);
    console.log(shareholderFormData);
    console.log(searchUserType);
    console.log(shareholders);

    if (searchUserType == "from") {
      assetContract
        .call("getLockDetail", [
          formData._nftUid,
          shareholderFormData?.user[0][5],
        ])
        .then((result) => {
          if (result.data == false) {
            assetContract
              .send("lock", {
                args: [
                  parseInt(formData._nftUid),
                  shareholderFormData?.user[0][5],
                  "noter",
                ],
                account: process.env.REACT_APP_SENDER_ACCOUNT,
              })
              .then((result) => {
                console.log(result);
              })
              .catch((err) => {
                console.log(err);
              });

            if (searchUserType == "from") {
              if (
                shareholders.find(
                  (e) => e.shareholderWallet === shareholderFormData?.user[0][5]
                )
              ) {
                setOpenSnackbarWarning(true);
              } else {
                setShareholders((oldArray) => [
                  ...oldArray,
                  {
                    shareholderFirstname: shareholderFormData?.user[0][0][0],
                    shareholderLastname: shareholderFormData?.user[0][0][1],
                    shareholderWallet: shareholderFormData?.user[0][5],
                    userHash: shareholderFormData?.user[0][1],
                    totalShares: shareholderFormData?.totalShares,
                    fromGrant:
                      shareholderFormData?.user[4] == "EDNAC"
                        ? parentUserHash
                        : "0x0000000000000000000000000000000000000000000000000000000000000000",
                  },
                ]);
                setOpenSnackbarSuccess(true);
              }
              setShareholderFormData(initialShareholderFormData);
              setFromUserData();
              setSearchFormData(initialSearchFormData);
            } else {
              if (
                toShareholders.find(
                  (e) => e.shareholderWallet === shareholderFormData?.user[0][5]
                )
              ) {
                setOpenSnackbarWarning(true);
              } else {
                setToShareholders((oldArray) => [
                  ...oldArray,
                  {
                    shareholderFirstname: shareholderFormData?.user[0][0][0],
                    shareholderLastname: shareholderFormData?.user[0][0][1],
                    shareholderWallet: shareholderFormData?.user[0][5],
                    userHash: shareholderFormData?.user[0][1],
                    totalShares: shareholderFormData?.totalShares,
                    toGrant:
                      shareholderFormData?.user[4] == "EDNAC"
                        ? parentUserHash
                        : "0x0000000000000000000000000000000000000000000000000000000000000000",
                  },
                ]);
                setOpenSnackbarSuccess(true);
              }
              setShareholderFormData(initialShareholderFormData);
              setFromUserData();
              setSearchFormData(initialSearchFormData);
            }
          } else {
            Swal.fire("Warning", "This user is locked", "warning").then(
              (result) => {}
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (searchUserType == "from") {
        if (
          shareholders.find(
            (e) => e.shareholderWallet === shareholderFormData?.user[0][5]
          )
        ) {
          setOpenSnackbarWarning(true);
        } else {
          setShareholders((oldArray) => [
            ...oldArray,
            {
              shareholderFirstname: shareholderFormData?.user[0][0][0],
              shareholderLastname: shareholderFormData?.user[0][0][1],
              shareholderWallet: shareholderFormData?.user[0][5],
              userHash: shareholderFormData?.user[0][1],
              totalShares: shareholderFormData?.totalShares,
              fromGrant:
                shareholderFormData?.user[4] == "EDNAC"
                  ? parentUserHash
                  : "0x0000000000000000000000000000000000000000000000000000000000000000",
            },
          ]);
          setOpenSnackbarSuccess(true);
        }
        setShareholderFormData(initialShareholderFormData);
        setFromUserData();
        setSearchFormData(initialSearchFormData);
      } else {
        if (
          toShareholders.find(
            (e) => e.shareholderWallet === shareholderFormData?.user[0][5]
          )
        ) {
          setOpenSnackbarWarning(true);
        } else {
          setToShareholders((oldArray) => [
            ...oldArray,
            {
              shareholderFirstname: shareholderFormData?.user[0][0][0],
              shareholderLastname: shareholderFormData?.user[0][0][1],
              shareholderWallet: shareholderFormData?.user[0][5],
              userHash: shareholderFormData?.user[0][1],
              totalShares: shareholderFormData?.totalShares,
              toGrant:
                shareholderFormData?.user[4] == "EDNAC"
                  ? parentUserHash
                  : "0x0000000000000000000000000000000000000000000000000000000000000000",
            },
          ]);
          setOpenSnackbarSuccess(true);
        }
        setShareholderFormData(initialShareholderFormData);
        setFromUserData();
        setSearchFormData(initialSearchFormData);
      }
    }
  };

  const selectAsset = () => {
    setSelectedAssetData(assetData);
  };

  // HANDLE UPDATE NEW IMAGE VIA DROP ZONE
  const handleChangeDropZone = (files) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles((prevState) => [...prevState.concat(files)]);
    handleOnChange("files", files);
  };

  const [value, setValue] = React.useState();

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value);
  };

  const searchAsset = (e) => {
    e.preventDefault();
    assetContract
      .call("getAssetIdBySearchKey", [searchFormData.searchKey])
      .then((result) => {
        console.log(result);
        handleOnChange("_nftUid", result.data);
        assetContract
          .call("getAssetDetailsByAssetId", [result.data])
          .then((result) => {
            console.log(result.data[0][3]);

            if (result.data[0][3]) {
              fetch(
                "https://" +
                  result.data[0][3] +
                  ".ipfs.nftstorage.link/metadata.json"
              )
                .then((response) => response.json())
                .then((responseJson) => {
                  console.log(responseJson);
                  setAssetData(responseJson);
                });
            } else {
              setAssetData("error");
              handleOnSearchChange("searchKey", "");
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors._documentType}
        >
          <FormLabel id="_documentType">{t("documentType")}</FormLabel>
          <Select
            onChange={(event, newValue) => {
              handleOnChange("_documentType", newValue);
            }}
            placeholder={"Select Document Type"}
          >
            <Option value="PDNAC">PDNAC</Option>
            <Option value="EDNAC">EDNAC</Option>
          </Select>
          {formDataErrors._documentType && (
            <FormHelperText>
              <InfoOutlined />
              {t("pleaseFill")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors._agreementType}
        >
          <FormLabel id="_agreementType">{t("agreementType")}</FormLabel>
          <Select
            onChange={(event, newValue) => {
              handleOnChange("_agreementType", newValue);
            }}
            placeholder={"Select Agreement Type"}
          >
            <Option value="Car Sale">{t("carSale")}</Option>
            <Option value="Property Sale">{t("propertySale")}</Option>
          </Select>
          {formDataErrors._agreementType && (
            <FormHelperText>
              <InfoOutlined />
              {t("pleaseFill")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography level="h4" sx={{ mt: 2 }}>
          {t("asset")}
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        {selectedAssetData ? (
          <>
            <FormControl>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{ my: 1 }}
              >
                <List
                  sx={{
                    "--List-gap": "0.5rem",
                    "--ListItem-paddingY": "1rem",
                    "--ListItem-radius": "8px",
                    "--ListItemDecorator-size": "32px",
                  }}
                >
                  <Grid container gap={1}>
                    <Grid xs={12} sm={12} md={10}>
                      <ListItem
                        variant="outlined"
                        sx={{ boxShadow: "md", mr: 1 }}
                      >
                        <Avatar
                          size="lg"
                          src={
                            "https://" +
                            selectedAssetData?.image?.substring(7, 66) +
                            ".ipfs.nftstorage.link/" +
                            selectedAssetData?.image?.substring(4).split("/")[3]
                          }
                        />

                        <Radio
                          checked={true}
                          overlay
                          label={
                            <Grid container>
                              <Grid xs={12} sm={12} md={12}>
                                {JSON?.parse(selectedAssetData?.description)
                                  ?.data?.documentType == "land" ? (
                                  <Typography>
                                    {t("propertyNumber")}:
                                    <Typography level="h4" fontSize="md">
                                      {" " +
                                        JSON?.parse(
                                          selectedAssetData?.description
                                        )?.data?.propertyNumber}
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography>
                                    {t("registrationNumber")}:
                                    <Typography level="h4" fontSize="md">
                                      {" " +
                                        JSON?.parse(
                                          selectedAssetData?.description
                                        )?.data?.registrationNumber}
                                    </Typography>
                                  </Typography>
                                )}
                              </Grid>

                              <Grid xs={12} sm={12} md={12}>
                                <Typography>
                                  {t("totalShares")}:
                                  <Typography level="h4" fontSize="md">
                                    {" " +
                                      JSON?.parse(
                                        selectedAssetData?.description
                                      )?.data?.totalShares}
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          sx={{
                            flexGrow: 1,
                            flexDirection: "row-reverse",
                          }}
                          slotProps={{
                            action: ({ checked }) => ({
                              sx: (theme) => ({
                                ...(checked && {
                                  inset: -1,
                                  border: "2px solid",
                                  borderColor: theme.vars.palette.primary[500],
                                }),
                              }),
                            }),
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <IconButton
                        color="danger"
                        size="sm"
                        variant="solid"
                        onClick={() => {
                          setSelectedAssetData();
                          setAssetData();
                          handleOnSearchChange("searchKey", "");
                          handleOnChange("_nftUid", "");
                          setShareholders([]);
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </List>
              </RadioGroup>
            </FormControl>
          </>
        ) : (
          <Card
            invertedColors={false}
            orientation="horizontal"
            size="md"
            variant="plain"
            color="neutral"
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formData?._documentType != "" ? (
                <>
                  <Typography level="title-md">
                    You can add asset here.
                  </Typography>
                  <IconButton
                    color="primary"
                    size="lg"
                    variant="plain"
                    onClick={() => {
                      setAssetModal(true);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </>
              ) : (
                <Typography level="title-md">
                  Please select document type.
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography level="h4" sx={{ mt: 2 }}>
          {t("shareholders")}
        </Typography>
      </Grid>
      {selectedAssetData ? (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ mt: 2 }}>
              From
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ mt: 2 }}>
              To
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            {shareholders.length != 0 ? (
              <>
                <Table
                  aria-label="basic table"
                  borderAxis="both"
                  color="neutral"
                  size="md"
                  stickyFooter={false}
                  stickyHeader={false}
                  variant="soft"
                  sx={{ mr: 1 }}
                >
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th style={{ width: "50%" }}>{t("wallet")}</th>
                      {formData?._documentType == "PDNAC" && (
                        <th>{t("shares")}</th>
                      )}
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shareholders.map((row) => (
                      <tr>
                        <td>
                          <Typography level="body-xs">
                            {row?.shareholderFirstname +
                              " " +
                              row?.shareholderLastname}
                          </Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">
                            {row?.shareholderWallet.slice(0, 10) +
                              "..." +
                              row?.shareholderWallet.slice(-10)}
                          </Typography>
                        </td>

                        {formData?._documentType == "PDNAC" && (
                          <td>
                            <Typography level="body-xs">
                              {row?.totalShares}
                            </Typography>
                          </td>
                        )}

                        <td>
                          <IconButton
                            color="danger"
                            size="xs"
                            variant="plain"
                            onClick={async () => {
                              console.log("BURADAYIM");
                              await unlockUser(row);
                              await setShareholders(
                                shareholders.filter(function (item) {
                                  return item !== row;
                                })
                              );
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Card
                  invertedColors={false}
                  orientation="horizontal"
                  size="md"
                  variant="plain"
                  color="neutral"
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography level="title-md">
                      You can add more shareholders here.
                    </Typography>
                    <IconButton
                      color="primary"
                      size="lg"
                      variant="plain"
                      onClick={() => {
                        setShareholderModal(true);
                        setSearchUserType("from");
                        setFromUserData();
                        handleOnSearchChange("searchKey", "");
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </>
            ) : (
              <Card
                invertedColors={false}
                orientation="horizontal"
                size="md"
                variant="plain"
                color="neutral"
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography level="title-md">
                    You can add shareholders here.
                  </Typography>
                  <IconButton
                    color="primary"
                    size="lg"
                    variant="plain"
                    onClick={() => {
                      setShareholderModal(true);
                      setSearchUserType("from");
                      setFromUserData();
                      handleOnSearchChange("searchKey", "");
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            {toShareholders.length != 0 ? (
              <>
                <Table
                  aria-label="basic table"
                  borderAxis="both"
                  color="neutral"
                  size="md"
                  stickyFooter={false}
                  stickyHeader={false}
                  variant="soft"
                  sx={{ ml: 1 }}
                >
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th style={{ width: "50%" }}>{t("wallet")}</th>
                      {formData?._documentType == "PDNAC" && (
                        <th>{t("shares")}</th>
                      )}
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {toShareholders.map((row) => (
                      <tr>
                        <td>
                          <Typography level="body-xs">
                            {row?.shareholderFirstname +
                              " " +
                              row?.shareholderLastname}
                          </Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">
                            {row?.shareholderWallet.slice(0, 10) +
                              "..." +
                              row?.shareholderWallet.slice(-10)}
                          </Typography>
                        </td>
                        {formData?._documentType == "PDNAC" && (
                          <td>
                            <Typography level="body-xs">
                              {row?.totalShares}
                            </Typography>
                          </td>
                        )}
                        <td>
                          <IconButton
                            color="danger"
                            size="xs"
                            variant="plain"
                            onClick={() => {
                              console.log(row?.shareholderWallet);
                              setToShareholders(
                                toShareholders.filter(function (item) {
                                  return item !== row;
                                })
                              );
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Card
                  invertedColors={false}
                  orientation="horizontal"
                  size="md"
                  variant="plain"
                  color="neutral"
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography level="title-md">
                      {t("addShareholder")}
                    </Typography>
                    <IconButton
                      color="primary"
                      size="lg"
                      variant="plain"
                      onClick={() => {
                        setShareholderModal(true);
                        setSearchUserType("to");
                        setFromUserData();
                        handleOnSearchChange("searchKey", "");
                      }}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </>
            ) : (
              <Card
                invertedColors={false}
                orientation="horizontal"
                size="md"
                variant="plain"
                color="neutral"
              >
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography level="title-md">
                    {t("addShareholder")}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="lg"
                    variant="plain"
                    onClick={() => {
                      setShareholderModal(true);
                      setSearchUserType("to");
                      setFromUserData();
                      handleOnSearchChange("searchKey", "");
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </CardContent>
              </Card>
            )}
          </Grid>
        </>
      ) : (
        <Grid xs={12} sm={12} md={12}>
          <Card
            invertedColors={false}
            orientation="horizontal"
            size="md"
            variant="plain"
            color="neutral"
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography level="title-md">
                {t("mustSelectShareholder")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid xs={12} sm={12} md={12}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.files}
        >
          <FormLabel>File</FormLabel>
          <DropZone
            onChange={(files) => {
              handleChangeDropZone(files);
            }}
            error={formDataErrors.files}
          />
          {files?.length > 0 && files.map((item) => <div>{item.path}</div>)}
          {formDataErrors.files && (
            <FormHelperText>
              <InfoOutlined />
              {t("pleaseFill")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Modal open={shareholderModal} onClose={() => setShareholderModal(false)}>
        <ModalDialog
          color="neutral"
          layout="center"
          size="lg"
          variant="outlined"
        >
          <ModalClose />
          <DialogTitle>Add Shareholder</DialogTitle>
          <DialogContent>Search for a user to add shareholder.</DialogContent>
          <FormControl>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <form onSubmit={search}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <Input
                      placeholder="Search"
                      value={
                        currency == "nationalId"
                          ? searchFormData.nationalId
                          : currency == "passportId"
                          ? searchFormData.passportId
                          : searchFormData.civilRegistryDocumentId
                      }
                      onChange={(event) => {
                        handleOnSearchChange(currency, event.target.value);
                      }}
                      endDecorator={
                        <Button
                          variant="soft"
                          color="primary"
                          startDecorator={<SearchIcon />}
                          type="submit"
                        >
                          {t("search")}
                        </Button>
                      }
                      startDecorator={
                        <React.Fragment>
                          <Select
                            variant="plain"
                            value={currency}
                            onChange={(_, value) => {
                              setCurrency(value);
                              handleOnSearchChange(value, "");
                            }}
                            slotProps={{
                              listbox: {
                                variant: "outlined",
                              },
                            }}
                          >
                            <Option value="nationalId">
                              Search by National ID
                            </Option>
                            <Option value="passportId">
                              Search by Passport ID
                            </Option>
                            <Option value="civilRegistryDocumentId">
                              Search by Civil Registry Document ID
                            </Option>
                          </Select>
                          <Divider orientation="vertical" />
                        </React.Fragment>
                      }
                    />
                  </FormControl>
                </form>
              </Grid>
              {fromUserData &&
                (fromUserData == "error" ? (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            No results found.
                          </Typography>
                          <Typography>
                            Click{" "}
                            <Link
                              component="button"
                              underline="none"
                              color="primary"
                              onClick={() => navigate("/users/create")}
                            >
                              here
                            </Link>{" "}
                            to create a user.
                          </Typography>
                        </CardContent>
                      </Card>
                    </FormControl>
                  </Grid>
                ) : formData?._documentType == "PDNAC" ? (
                  <>
                    <Grid xs={12} sm={12} md={12}>
                      <FormControl
                        sx={{ width: "100%", p: 1, pl: 0 }}
                        error={formDataErrors.files}
                      >
                        <Card
                          invertedColors={false}
                          orientation="horizontal"
                          size="md"
                          variant="soft"
                          color="primary"
                        >
                          <CardContent>
                            <Typography level="title-md">
                              {searchResult[0][0]} {searchResult[0][1]}
                            </Typography>
                            <Typography>
                              {searchResult[0][4]} {searchResult[0][5]}
                            </Typography>
                          </CardContent>
                        </Card>
                      </FormControl>
                    </Grid>
                    <Divider />
                    <Grid xs={12} sm={12} md={12}>
                      {fromUserData.map((item) => (
                        <FormControl>
                          <RadioGroup
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={(event) => {
                              handleOnShareholderChange("user", item);

                              if (searchUserType == "from") {
                                setMaxValue(item[2]);
                              }
                              setShareModal(true);
                            }}
                            sx={{ my: 1 }}
                          >
                            <List
                              sx={{
                                "--List-gap": "0.5rem",
                                "--ListItem-paddingY": "1rem",
                                "--ListItem-radius": "8px",
                                "--ListItemDecorator-size": "32px",
                              }}
                            >
                              <Grid container>
                                <Grid xs={12} sm={12} md={12}>
                                  <ListItem
                                    variant="soft"
                                    color="primary"
                                    sx={{ boxShadow: "md", mr: 1 }}
                                  >
                                    <Radio
                                      disabled={
                                        searchUserType == "from" &&
                                        parseInt(item[2]) <= 0
                                          ? true
                                          : false
                                      }
                                      overlay
                                      label={
                                        <Grid container>
                                          <Grid xs={12} sm={12} md={12}>
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {item[0][0][0] +
                                                " " +
                                                item[0][0][1]}
                                            </Typography>
                                          </Grid>

                                          <Grid xs={12} sm={12} md={12}>
                                            <Typography>
                                              Date of Birth:{" "}
                                              <Typography
                                                level="h4"
                                                fontSize="md"
                                              >
                                                {item[0][0][4]}
                                              </Typography>
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12} sm={12} md={12}>
                                            <Typography>
                                              Place of Birth:{" "}
                                              <Typography
                                                level="h4"
                                                fontSize="md"
                                              >
                                                {item[0][0][5]}
                                              </Typography>
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12} sm={12} md={12}>
                                            <Typography>
                                              Total Shares:{" "}
                                              <Typography
                                                level="h4"
                                                fontSize="md"
                                              >
                                                {item[2]}
                                              </Typography>
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12} sm={12} md={12}>
                                            <Typography>
                                              Type:{" "}
                                              <Typography
                                                level="h4"
                                                fontSize="md"
                                              >
                                                {item[4]}
                                              </Typography>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      }
                                      sx={{
                                        flexGrow: 1,
                                        flexDirection: "row-reverse",
                                      }}
                                      slotProps={{
                                        action: ({ checked }) => ({
                                          sx: (theme) => ({
                                            ...(checked && {
                                              inset: -1,
                                              border: "2px solid",
                                              borderColor:
                                                theme.vars.palette.primary[500],
                                            }),
                                          }),
                                        }),
                                      }}
                                    />
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </List>
                          </RadioGroup>
                        </FormControl>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={12} sm={12} md={12}>
                      <FormControl
                        sx={{ width: "100%", p: 1, pl: 0 }}
                        error={formDataErrors.files}
                      >
                        <Card
                          invertedColors={false}
                          orientation="horizontal"
                          size="md"
                          variant="plain"
                          color="neutral"
                        >
                          <CardContent>
                            <Typography level="title-md">
                              {searchResult[0][0]} {searchResult[0][1]}
                            </Typography>
                            <Typography>
                              {searchResult[0][4]} {searchResult[0][5]}
                            </Typography>
                          </CardContent>
                        </Card>
                      </FormControl>
                    </Grid>
                    <Divider />
                    {searchUserType == "from" ? (
                      <Grid xs={12} sm={12} md={12}>
                        {fromUserData.map((item) =>
                          parseInt(item[2]) > 0 ? (
                            <FormControl>
                              <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={(event) => {
                                  handleOnShareholderChange("user", item);
                                }}
                                sx={{ my: 1 }}
                              >
                                <List
                                  sx={{
                                    "--List-gap": "0.5rem",
                                    "--ListItem-paddingY": "1rem",
                                    "--ListItem-radius": "8px",
                                    "--ListItemDecorator-size": "32px",
                                  }}
                                >
                                  <Grid container>
                                    <Grid xs={12} sm={12} md={12}>
                                      <ListItem
                                        variant="soft"
                                        color="neutral"
                                        sx={{ boxShadow: "md", mr: 1 }}
                                      >
                                        <Radio
                                          disabled={
                                            searchUserType == "from" &&
                                            parseInt(item[2]) <= 0
                                              ? true
                                              : false
                                          }
                                          overlay
                                          label={
                                            <Grid container>
                                              <Grid xs={12} sm={12} md={12}>
                                                <Typography
                                                  level="h4"
                                                  fontSize="md"
                                                >
                                                  {item[0][0][0] +
                                                    " " +
                                                    item[0][0][1]}
                                                </Typography>
                                              </Grid>

                                              <Grid xs={12} sm={12} md={12}>
                                                <Typography>
                                                  Date of Birth:{" "}
                                                  <Typography
                                                    level="h4"
                                                    fontSize="md"
                                                  >
                                                    {item[0][0][4]}
                                                  </Typography>
                                                </Typography>
                                              </Grid>
                                              <Grid xs={12} sm={12} md={12}>
                                                <Typography>
                                                  Place of Birth:{" "}
                                                  <Typography
                                                    level="h4"
                                                    fontSize="md"
                                                  >
                                                    {item[0][0][5]}
                                                  </Typography>
                                                </Typography>
                                              </Grid>
                                              <Grid xs={12} sm={12} md={12}>
                                                <Typography>
                                                  Total Shares:{" "}
                                                  <Typography
                                                    level="h4"
                                                    fontSize="md"
                                                  >
                                                    {item[2]}
                                                  </Typography>
                                                </Typography>
                                              </Grid>
                                              <Grid xs={12} sm={12} md={12}>
                                                <Typography>
                                                  Type:{" "}
                                                  <Typography
                                                    level="h4"
                                                    fontSize="md"
                                                  >
                                                    {item[4]}
                                                  </Typography>
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          }
                                          sx={{
                                            flexGrow: 1,
                                            flexDirection: "row-reverse",
                                          }}
                                          slotProps={{
                                            action: ({ checked }) => ({
                                              sx: (theme) => ({
                                                ...(checked && {
                                                  inset: -1,
                                                  border: "2px solid",
                                                  borderColor:
                                                    theme.vars.palette
                                                      .primary[500],
                                                }),
                                              }),
                                            }),
                                          }}
                                        />
                                      </ListItem>
                                    </Grid>
                                  </Grid>
                                </List>
                              </RadioGroup>
                            </FormControl>
                          ) : (
                            <Grid xs={12} sm={12} md={12}>
                              <FormControl
                                sx={{ width: "100%", p: 1, pl: 0 }}
                                error={formDataErrors.files}
                              >
                                <Card
                                  invertedColors={false}
                                  orientation="horizontal"
                                  size="md"
                                  variant="plain"
                                  color="neutral"
                                >
                                  <CardContent>
                                    <Typography level="title-md">
                                      No {item[4]} found.
                                    </Typography>
                                    <Typography>
                                      This person does not have {item[4]} on
                                      selected asset.
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </FormControl>
                            </Grid>
                          )
                        )}
                      </Grid>
                    ) : (
                      <Grid xs={12} sm={12} md={12}>
                        {fromUserData.map((item) => (
                          <FormControl>
                            <RadioGroup
                              name="controlled-radio-buttons-group"
                              value={value}
                              onChange={(event) => {
                                handleOnShareholderChange("user", item);
                              }}
                              sx={{ my: 1 }}
                            >
                              <List
                                sx={{
                                  "--List-gap": "0.5rem",
                                  "--ListItem-paddingY": "1rem",
                                  "--ListItem-radius": "8px",
                                  "--ListItemDecorator-size": "32px",
                                }}
                              >
                                <Grid container>
                                  <Grid xs={12} sm={12} md={12}>
                                    <ListItem
                                      variant="soft"
                                      color="neutral"
                                      sx={{ boxShadow: "md", mr: 1 }}
                                    >
                                      <Radio
                                        disabled={
                                          searchUserType == "from" &&
                                          parseInt(item[2]) <= 0
                                            ? true
                                            : false
                                        }
                                        overlay
                                        label={
                                          <Grid container>
                                            <Grid xs={12} sm={12} md={12}>
                                              <Typography
                                                level="h4"
                                                fontSize="md"
                                              >
                                                {item[0][0][0] +
                                                  " " +
                                                  item[0][0][1]}
                                              </Typography>
                                            </Grid>

                                            <Grid xs={12} sm={12} md={12}>
                                              <Typography>
                                                Date of Birth:{" "}
                                                <Typography
                                                  level="h4"
                                                  fontSize="md"
                                                >
                                                  {item[0][0][4]}
                                                </Typography>
                                              </Typography>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={12}>
                                              <Typography>
                                                Place of Birth:{" "}
                                                <Typography
                                                  level="h4"
                                                  fontSize="md"
                                                >
                                                  {item[0][0][5]}
                                                </Typography>
                                              </Typography>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={12}>
                                              <Typography>
                                                Total Shares:{" "}
                                                <Typography
                                                  level="h4"
                                                  fontSize="md"
                                                >
                                                  {item[2]}
                                                </Typography>
                                              </Typography>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={12}>
                                              <Typography>
                                                Type:{" "}
                                                <Typography
                                                  level="h4"
                                                  fontSize="md"
                                                >
                                                  {item[4]}
                                                </Typography>
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        }
                                        sx={{
                                          flexGrow: 1,
                                          flexDirection: "row-reverse",
                                        }}
                                        slotProps={{
                                          action: ({ checked }) => ({
                                            sx: (theme) => ({
                                              ...(checked && {
                                                inset: -1,
                                                border: "2px solid",
                                                borderColor:
                                                  theme.vars.palette
                                                    .primary[500],
                                              }),
                                            }),
                                          }),
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                </Grid>
                              </List>
                            </RadioGroup>
                          </FormControl>
                        ))}
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </FormControl>
          {formData?._documentType == "EDNAC" && (
            <DialogActions>
              <Button
                color="primary"
                size="sm"
                onClick={() => addShareholder()}
                disabled={Object.keys(shareholderFormData.user).length === 0}
              >
                Add Shareholder
              </Button>
            </DialogActions>
          )}
        </ModalDialog>
      </Modal>
      <Modal open={assetModal} onClose={() => setAssetModal(false)}>
        <ModalDialog color="primary" layout="center" size="lg" variant="soft">
          <ModalClose />
          <DialogTitle>Select Asset</DialogTitle>
          <FormControl>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <form onSubmit={searchAsset}>
                  <FormControl
                    sx={{ width: "100%", p: 1, pl: 0, minWidth: 500 }}
                  >
                    <Input
                      placeholder="Search assets by keyword"
                      value={searchFormData.searchKey}
                      onChange={(event) => {
                        handleOnSearchChange("searchKey", event.target.value);
                      }}
                      endDecorator={
                        <Button
                          variant="soft"
                          color="primary"
                          startDecorator={<SearchIcon />}
                          type="submit"
                        >
                          Search
                        </Button>
                      }
                    />
                  </FormControl>
                </form>
              </Grid>
              {assetData &&
                (assetData == "error" ? (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            No results found.
                          </Typography>
                          <Typography>
                            Click{" "}
                            <Link
                              component="button"
                              underline="none"
                              color="primary"
                              onClick={() => navigate("/assets/create")}
                            >
                              here
                            </Link>{" "}
                            to create an asset.
                          </Typography>
                        </CardContent>
                      </Card>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl>
                      <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        sx={{ my: 1 }}
                      >
                        <List
                          sx={{
                            "--List-gap": "0.5rem",
                            "--ListItem-paddingY": "1rem",
                            "--ListItem-radius": "8px",
                            "--ListItemDecorator-size": "32px",
                          }}
                        >
                          <Grid container>
                            <Grid xs={12} sm={12} md={12}>
                              <ListItem
                                variant="soft"
                                color="neutral"
                                sx={{ boxShadow: "md", mr: 1 }}
                              >
                                <Avatar
                                  size="lg"
                                  src={
                                    "https://" +
                                    assetData?.image?.substring(7, 66) +
                                    ".ipfs.nftstorage.link/" +
                                    assetData?.image?.substring(4).split("/")[3]
                                  }
                                />

                                <Radio
                                  overlay
                                  value={assetData?._nftUid}
                                  label={
                                    <Grid container>
                                      <Grid xs={12} sm={12} md={12}>
                                        {JSON?.parse(assetData?.description)
                                          ?.data?.documentType == "land" ? (
                                          <Typography>
                                            Property Number:
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {" " +
                                                JSON?.parse(
                                                  assetData?.description
                                                )?.data?.propertyNumber}
                                            </Typography>
                                          </Typography>
                                        ) : (
                                          <Typography>
                                            Registration Number:
                                            <Typography
                                              level="h4"
                                              fontSize="md"
                                            >
                                              {" " +
                                                JSON?.parse(
                                                  assetData?.description
                                                )?.data?.registrationNumber}
                                            </Typography>
                                          </Typography>
                                        )}
                                      </Grid>

                                      <Grid xs={12} sm={12} md={12}>
                                        <Typography>
                                          Total Shares:
                                          <Typography level="h4" fontSize="md">
                                            {" " +
                                              JSON?.parse(
                                                assetData?.description
                                              )?.data?.totalShares}
                                          </Typography>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  }
                                  sx={{
                                    flexGrow: 1,
                                    flexDirection: "row-reverse",
                                  }}
                                  slotProps={{
                                    action: ({ checked }) => ({
                                      sx: (theme) => ({
                                        ...(checked && {
                                          inset: -1,
                                          border: "2px solid",
                                          borderColor:
                                            theme.vars.palette.primary[500],
                                        }),
                                      }),
                                    }),
                                  }}
                                />
                              </ListItem>
                            </Grid>
                          </Grid>
                        </List>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ))}
            </Grid>
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                selectAsset();
                setAssetModal(false);
              }}
              disabled={assetData ? false : true}
            >
              Select Asset
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Modal open={shareModal} onClose={() => setShareModal(false)}>
        <ModalDialog color="primary" layout="center" size="lg" variant="soft">
          <ModalClose />
          <DialogTitle>Share Number</DialogTitle>
          <FormControl>
            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl sx={{ width: "100%", p: 1, pl: 0, minWidth: 500 }}>
                  <Typography>Max Shares: {maxValue}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  sx={{ width: "100%", p: 1, pl: 0, minWidth: 500 }}
                  error={
                    parseInt(shareholderFormData?.totalShares) >
                    parseInt(maxValue)
                  }
                >
                  <Input
                    sx={{ maxHeight: 50 }}
                    type="number"
                    size="sm"
                    placeholder="Share"
                    onChange={(event) =>
                      handleOnShareholderChange(
                        "totalShares",
                        event.target.value
                      )
                    }
                  />
                  {parseInt(shareholderFormData?.totalShares) >
                    parseInt(maxValue) && (
                    <FormHelperText>
                      <InfoOutlined />
                      The value cannot be more than the maximum number of
                      shares.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              size="sm"
              onClick={() => addShareholder()}
              disabled={
                shareholderFormData?.totalShares == "" ||
                parseInt(shareholderFormData?.totalShares) > parseInt(maxValue)
              }
            >
              Add Shareholder
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        variant="soft"
        color="warning"
        open={openSnackbarWarning}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarWarning(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarWarning(false)}
            size="sm"
            variant="soft"
            color="warning"
          >
            Dismiss
          </Button>
        }
      >
        User already added.
      </Snackbar>
      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbarSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarSuccess(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        User added.
      </Snackbar>
    </Grid>
  );
};

export default CreateDocumentForm;
