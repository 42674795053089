/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Grid, IconButton } from "@mui/joy";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableContainer } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function AssetTable({
  rows,
  setOpenSnackbar,
  handleOnSearchChange,
  search,
  searchFormData,
  setSearchFormData,
}) {
  const [order, setOrder] = React.useState("desc");
  const target = React.useRef(null);
  const navigate = useNavigate();

  console.log(rows);

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
      </Sheet>

      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <Input
              placeholder="Search assets by keyword"
              value={searchFormData.searchKey}
              onChange={(event) => {
                handleOnSearchChange("searchKey", event.target.value);
              }}
              endDecorator={
                <Button
                  variant="soft"
                  color="primary"
                  startDecorator={<SearchIcon />}
                  onClick={() => search()}
                >
                  Search
                </Button>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Card>
        <TableContainer>
          <Table
            borderAxis="xBetween"
            color="neutral"
            size="md"
            stickyFooter={false}
            stickyHeader={false}
            variant="plain"
            sx={{
              "& tr > *:not(:first-child)": { textAlign: "center" },
              minWidth: 650,
            }}
          >
            <thead>
              <tr>
                <th>Asset ID</th>
                <th>Total Shares</th>
                <th>Unshared Shares</th>
                <th>Transferable</th>
                <th>IPFS Hash</th>
                <th>Shareholders Count</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {stableSort(rows, getComparator(order, "id")).map((row) => (
                <tr key={row.id}>
                  <td>{row?.id}</td>
                  <td>
                    {row?.shareholders?.reduce((acc, curr) => {
                      let thirdElement = parseInt(curr[2]);
                      if (!isNaN(thirdElement)) {
                        return acc + thirdElement;
                      }
                      return acc;
                    }, 0) + parseInt(row?.totalShares)}
                  </td>
                  <td>{row?.totalShares}</td>
                  <td>{row?.status == true ? "Yes" : "No"}</td>

                  <td>
                    <Button
                      color="neutral"
                      size="sm"
                      sx={{ fontWeight: "normal" }}
                      onClick={() => {
                        navigator.clipboard.writeText(row?.ipfs);
                        setOpenSnackbar(true);
                      }}
                      variant="plain"
                      startDecorator={<ContentCopyIcon />}
                    >
                      {row?.ipfs?.slice(0, 2) + "..." + row?.ipfs?.slice(-8)}
                    </Button>
                  </td>
                  <td>{row?.shareholders?.length}</td>
                  <td>
                    <IconButton
                      size="sm"
                      onClick={() =>
                        navigate("/assets/asset/" + row.id, { state: row })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Card>
    </React.Fragment>
  );
}
