/* eslint-disable jsx-a11y/anchor-is-valid */
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
// icons
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Rename</MenuItem>
        <MenuItem>Move</MenuItem>
        <Divider />
        <MenuItem color="danger">Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const rows = [
  {
    id: 1,
    name: "Notary 1",
    hash: "63dad7dd9fda9df1825ab5607552c5bfa09950d39b33ab4a1c288f91b126f3d4",
    ip: "3.76.43.144:30304",
  },
  {
    id: 2,
    name: "Notary 2",
    hash: "6c249b7429c7f001e9fc8accc73000759b7210beee39d7930d8b34be72016d4c",
    ip: "18.184.72.47:30302",
  },
  {
    id: 3,
    name: "Notary 3",
    hash: "a865fb882b4314d77cf544f2b166bd7e671b73916c1f66a3e26f8f1de850bb2b",
    ip: "18.184.72.47:30300",
  },
  {
    id: 4,
    name: "Notary 4",
    hash: "eaff93c698158b64df10baa4181145b25e99fcf02c2dc5f0b2829594e6514b79",
    ip: "3.76.43.144:30305",
  },
  {
    id: 5,
    name: "Notary 5",
    hash: "f6c1dc18560da4f8a002c03cd5a06a5bf4eecc317f17af0b2f48ea70b621b851",
    ip: "3.76.43.144:30303",
  },
];

export default function NotariesTable() {
  const [order, setOrder] = React.useState("desc");
  const navigate = useNavigate();
  console.log(rows);
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          borderAxis="bothBetween"
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 50, padding: "12px 6px" }}>Name</th>
              <th style={{ width: 160, padding: "12px 6px" }}>Node ID</th>
              <th style={{ width: 80, padding: "12px 6px" }}>Remote Address</th>
              <th style={{ width: 40, padding: "12px 6px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row._hash}>
                <td>
                  <Typography level="body-xs">{row.name}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.hash}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.ip}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">Edit</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
