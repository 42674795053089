import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import * as React from "react";

import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import IconButton from "@mui/joy/IconButton";

// Icons import
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import TranslateIcon from "@mui/icons-material/Translate";

import { Typography } from "@mui/joy";
import HeroLeft01 from "./HeroLeft01";
import framesxTheme from "./theme";
import { useTranslation } from "react-i18next";

function LanguageChanger() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  const { i18n } = useTranslation();

  console.log(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="soft"
      color="neutral"
      onClick={(event) => {
        if (i18n.language === "en") {
          changeLanguage("ar");
        } else {
          changeLanguage("en");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "4.5rem",
        borderRadius: "50%",
        boxShadow: "sm",
      }}
    >
      {mode === "light" ? <TranslateIcon /> : <TranslateIcon />}
    </IconButton>
  );
}

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="soft"
      color="neutral"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "1rem",
        borderRadius: "50%",
        boxShadow: "sm",
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

function CompanyLogo() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        gap: 2,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        position: "fixed",
        zIndex: 999,
        top: "1.5rem",
        left: "1rem",
      }}
    >
      <IconButton variant="soft" color="danger" size="sm">
        <AssuredWorkloadIcon />
      </IconButton>
      <Typography level="title-lg">{t("notary")}</Typography>
    </Box>
  );
}

function TubuLogo() {
  return (
    <a
      href={"https://tubu.io"}
      rel="noreferrer"
      target="_blank"
      style={{ textDecoration: "none" }}
    >
      <Box
        sx={{
          gap: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          position: "fixed",
          zIndex: 999,
          bottom: "1.5rem",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <img width={"64px"} src="https://tubu.io/image/logo-blue.svg" alt="" />
      </Box>
    </a>
  );
}

export default function LandingPage() {
  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <CompanyLogo />
      <ColorSchemeToggle />
      <LanguageChanger />

      <Box
        sx={{
          height: "100vh",
          overflowY: "scroll",
          scrollSnapType: "y mandatory",
          "& > div": {
            scrollSnapAlign: "start",
          },
        }}
      >
        <HeroLeft01 />
      </Box>
      <TubuLogo />
    </CssVarsProvider>
  );
}
