import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import HomeIcon from "@mui/icons-material/Home";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ColorSchemeToggle from "../pages/MainLayout/ColorSchemeToggle";
import LanguageChanger from "../pages/MainLayout/LanguageChanger";
import { closeSidebar } from "../utils/util";
import { DocumentScanner, TypeSpecimen } from "@mui/icons-material";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { useTranslation } from "react-i18next";

function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 999,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm">
          <AssuredWorkloadIcon />
        </IconButton>
        <Typography level="title-lg">Notary</Typography>
      </Box>

      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          {[
            t("home"),
            t("documents"),
            t("users"),
            t("assets"),
            t("notaries"),
            t("documentTypes"),
          ].map((text, index) => (
            <ListItem key={text}>
              <ListItemButton
                onClick={(e) =>
                  index === 0
                    ? navigate("/home")
                    : index === 1
                    ? navigate("/documents")
                    : index === 2
                    ? navigate("/users")
                    : index === 3
                    ? navigate("/assets")
                    : index === 4
                    ? navigate("/notaries")
                    : index === 5
                    ? navigate("/document-types")
                    : navigate("/")
                }
                selected={
                  window.location.pathname === "/home" && index === 0
                    ? true
                    : window.location.pathname === "/documents" && index === 1
                    ? true
                    : window.location.pathname === "/users" && index === 2
                    ? true
                    : window.location.pathname === "/assets" && index === 3
                    ? true
                    : window.location.pathname === "/notaries" && index === 4
                    ? true
                    : window.location.pathname === "/document-types" &&
                      index === 5
                    ? true
                    : false
                }
              >
                {index === 0 ? (
                  <HomeIcon />
                ) : index === 1 ? (
                  <DocumentScanner />
                ) : index === 2 ? (
                  <PeopleAltIcon />
                ) : index === 3 ? (
                  <LandscapeIcon />
                ) : index === 4 ? (
                  <AssuredWorkloadIcon />
                ) : index === 5 ? (
                  <TypeSpecimen />
                ) : (
                  <ImageAspectRatioIcon />
                )}

                <ListItemContent>
                  <Typography level="title-sm">{text}</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List
          size="sm"
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          <a
            href={"https://tubu.io"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <ListItem>
              <ListItemButton>
                <SupportRoundedIcon />
                {t("support")}
              </ListItemButton>
            </ListItem>
          </a>
        </List>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ColorSchemeToggle sx={{ width: "100%" }} />
          <LanguageChanger sx={{ width: "100%" }} />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar alt="Admin User" src="/static/images/avatar/2.jpg" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">
            {localStorage.getItem("user")}
          </Typography>
          <Typography level="body-xs">
            {localStorage.getItem("email")}
          </Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("email");

            navigate("/signin");
          }}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
