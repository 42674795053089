import { InfoOutlined } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Snackbar,
  Table,
  Textarea,
  Typography,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropZone from "../DropZone/DropZone";

const CreateLandAssetForm = ({ formData, formDataErrors, handleOnChange }) => {
  const navigate = useNavigate();

  const JWT = process.env.REACT_APP_PINATA_TOKEN;

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const contract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );
  const nftContract = app.contract(process.env.REACT_APP_NFT_CONTRACT_SHORT_ID);

  const [files, setFiles] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [shareholderModal, setShareholderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromUserData, setFromUserData] = React.useState();
  const [currency, setCurrency] = React.useState("nationalId");
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);

  const initialSearchFormData = {
    nationalId: "",
    passportId: "",
    civilRegistryDocumentId: "",
  };

  const initialShareholderFormData = {
    user: {},
    totalShares: "",
  };

  const [shareholderFormData, setShareholderFormData] = useState({
    ...initialShareholderFormData,
  });

  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  useEffect(() => {
    handleOnChange("shareholders", shareholders);
  }, [shareholders]);

  // HANDLE UPDATE NEW IMAGE VIA DROP ZONE
  const handleChangeDropZone = (files) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles((prevState) => [...prevState.concat(files)]);

    handleOnChange("files", files);
  };

  const addShareholder = () => {
    setShareholderModal(false);
    console.log(shareholderFormData);
    if (
      shareholders.find(
        (e) => e.shareholderWallet === shareholderFormData?.user?.userWallet
      )
    ) {
      setOpenSnackbarWarning(true);
    } else {
      setShareholders((oldArray) => [
        ...oldArray,
        {
          shareholderFirstname: shareholderFormData?.user?.firstName,
          shareholderLastname: shareholderFormData?.user?.lastName,
          shareholderWallet: shareholderFormData?.user?.userWallet,
          userHash: shareholderFormData?.user?.userHash,
          totalShares: shareholderFormData?.totalShares,
        },
      ]);
      setOpenSnackbarSuccess(true);
    }
    setShareholderFormData(initialShareholderFormData);
    setFromUserData();
    setSearchFormData(initialSearchFormData);
  };
  const handleOnShareholderChange = (name, value) => {
    console.log(shareholderFormData);

    setShareholderFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const search = (e) => {
    e.preventDefault();
    setFromUserData();
    userContract
      .call("searchUser", [
        searchFormData.nationalId,
        searchFormData.passportId,
        searchFormData.civilRegistryDocumentId,
      ])
      .then((result) => {
        if (
          result.data[1] ==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          setFromUserData("error");
        } else {
          let objs = [result.data].map((item, index) => ({
            id: index,
            firstName: item[0][0],
            lastName: item[0][1],
            fathersName: item[0][2],
            mothersName: item[0][3],
            dateOfBirth: item[0][4],
            placeOfBirth: item[0][5],
            gender: item[0][6],
            nationality: item[0][7],
            registryPlace: item[0][8],
            number: item[0][9],
            files: item[0][10],
            userHash: item[1],
            nationalId: item[2],
            passportId: item[3],
            civilRegistryDocumentId: item[4],
            userWallet: item[5],
          }));
          handleOnShareholderChange("user", objs[0]);
          setFromUserData(objs[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnSearchChange = (name, value) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <Grid container>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.propertyNumber}
        >
          <FormLabel>Property Number</FormLabel>
          <Input
            placeholder="Property Number"
            onChange={(event) =>
              handleOnChange("propertyNumber", event.target.value)
            }
          />
          {formDataErrors.propertyNumber && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={6}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.assetType}
        >
          <FormLabel id="assetType">Asset Type</FormLabel>
          <Select
            onChange={(event, newValue) => {
              handleOnChange("assetType", newValue);
            }}
            placeholder={"Select assetType"}
          >
            <Option value="Land">Land</Option>
            <Option value="House">House</Option>
            <Option value="Apartment">Apartment</Option>
          </Select>
          {formDataErrors.assetType && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.address}
        >
          <FormLabel>Address</FormLabel>
          <Textarea
            placeholder="Address"
            minRows={3}
            onChange={(event) => handleOnChange("address", event.target.value)}
          />
          {formDataErrors.address && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.totalShares}
        >
          <FormLabel>Total Shares</FormLabel>
          <Input
            type="number"
            placeholder="Total Shares"
            onChange={(event) =>
              handleOnChange("totalShares", event.target.value)
            }
          />
          {formDataErrors.totalShares && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Typography level="h4" sx={{ mt: 2 }}>
        Shareholders
      </Typography>
      <Grid xs={12} sm={12} md={12}>
        {shareholders.length != 0 ? (
          <>
            <Table
              aria-label="basic table"
              borderAxis="bothBetween"
              color="neutral"
              size="md"
              stickyFooter={false}
              stickyHeader={false}
              variant="soft"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th style={{ width: "50%" }}>Wallet</th>
                  <th>Shares</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {shareholders.map((row) => (
                  <tr>
                    <td>
                      <Typography level="body-xs">
                        {row?.shareholderFirstname +
                          " " +
                          row?.shareholderLastname}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {row?.shareholderWallet}
                      </Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">
                        {row?.totalShares}
                      </Typography>
                    </td>
                    <td>
                      <IconButton
                        color="danger"
                        size="xs"
                        variant="plain"
                        onClick={() => {
                          console.log(row?.shareholderWallet);
                          setShareholders(
                            shareholders.filter(function (item) {
                              return item !== row;
                            })
                          );
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card
              invertedColors={false}
              orientation="horizontal"
              size="md"
              variant="plain"
              color="neutral"
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography level="title-md">
                  You can add more shareholders here.
                </Typography>
                <IconButton
                  color="primary"
                  size="lg"
                  variant="plain"
                  onClick={() => setShareholderModal(true)}
                >
                  <AddCircleIcon />
                </IconButton>
              </CardContent>
            </Card>
          </>
        ) : (
          <Card
            invertedColors={false}
            orientation="horizontal"
            size="md"
            variant="plain"
            color="neutral"
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography level="title-md">
                You can add shareholders here.
              </Typography>
              <IconButton
                color="primary"
                size="lg"
                variant="plain"
                onClick={() => setShareholderModal(true)}
              >
                <AddCircleIcon />
              </IconButton>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <FormControl
          sx={{ width: "100%", p: 1, pl: 0 }}
          error={formDataErrors.files}
        >
          <FormLabel>File</FormLabel>
          <DropZone
            onChange={(files) => {
              handleChangeDropZone(files);
            }}
            error={formDataErrors.files}
          />
          {files?.length > 0 && files.map((item) => <div>{item.path}</div>)}
          {formDataErrors.files && (
            <FormHelperText>
              <InfoOutlined />
              Please fill out this field.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Modal open={shareholderModal} onClose={() => setShareholderModal(false)}>
        <ModalDialog color="primary" layout="center" size="lg" variant="soft">
          <ModalClose />
          <DialogTitle>Add Shareholder</DialogTitle>
          <DialogContent>Search for a user to add shareholder.</DialogContent>
          <FormControl>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <form onSubmit={search}>
                  <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
                    <Input
                      placeholder="Search"
                      value={
                        currency == "nationalId"
                          ? searchFormData.nationalId
                          : currency == "passportId"
                          ? searchFormData.passportId
                          : searchFormData.civilRegistryDocumentId
                      }
                      onChange={(event) => {
                        handleOnSearchChange(currency, event.target.value);
                      }}
                      endDecorator={
                        <Button
                          variant="soft"
                          color="primary"
                          startDecorator={<SearchIcon />}
                          type="submit"
                        >
                          Search
                        </Button>
                      }
                      startDecorator={
                        <React.Fragment>
                          <Select
                            variant="plain"
                            value={currency}
                            onChange={(_, value) => {
                              setCurrency(value);
                              handleOnSearchChange(value, "");
                            }}
                            slotProps={{
                              listbox: {
                                variant: "outlined",
                              },
                            }}
                          >
                            <Option value="nationalId">
                              Search by National ID
                            </Option>
                            <Option value="passportId">
                              Search by Passport ID
                            </Option>
                            <Option value="civilRegistryDocumentId">
                              Search by Civil Registry Document ID
                            </Option>
                          </Select>
                          <Divider orientation="vertical" />
                        </React.Fragment>
                      }
                    />
                  </FormControl>
                </form>
              </Grid>
              {fromUserData &&
                (fromUserData == "error" ? (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            No results found.
                          </Typography>
                          <Typography>
                            Click{" "}
                            <Link
                              component="button"
                              underline="none"
                              color="primary"
                              onClick={() => navigate("/users/create")}
                            >
                              here
                            </Link>{" "}
                            to create a user.
                          </Typography>
                        </CardContent>
                      </Card>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid xs={12} sm={12} md={12}>
                    <FormControl
                      sx={{ width: "100%", p: 1, pl: 0 }}
                      error={formDataErrors.files}
                    >
                      <Card
                        invertedColors={false}
                        orientation="horizontal"
                        size="md"
                        variant="plain"
                        color="neutral"
                      >
                        <CardContent>
                          <Typography level="title-md">
                            {fromUserData?.firstName +
                              " " +
                              fromUserData?.lastName}
                          </Typography>
                          <Typography>
                            Date of Birth: {fromUserData?.dateOfBirth}
                          </Typography>
                          <Typography>
                            Place of Birth: {fromUserData?.placeOfBirth}
                          </Typography>
                        </CardContent>
                        <Divider orientation="vertical" />
                        <Input
                          type="number"
                          size="sm"
                          placeholder="Share"
                          onChange={(event) =>
                            handleOnShareholderChange(
                              "totalShares",
                              event.target.value
                            )
                          }
                        />
                      </Card>
                    </FormControl>
                  </Grid>
                ))}
            </Grid>
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              size="sm"
              onClick={() => addShareholder()}
              disabled={shareholderFormData?.totalShares == ""}
            >
              Add Shareholder
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        variant="soft"
        color="warning"
        open={openSnackbarWarning}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarWarning(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarWarning(false)}
            size="sm"
            variant="soft"
            color="warning"
          >
            Dismiss
          </Button>
        }
      >
        User already added.
      </Snackbar>

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbarSuccess}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbarSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbarSuccess(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        User added.
      </Snackbar>
    </Grid>
  );
};

export default CreateLandAssetForm;
