import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import React, { useEffect, useState } from "react";
// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import {
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
} from "@mui/joy";
import Tubu from "@tubu/tubuio-sdk-node/lib/Tubu";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AssetTable from "./AssetTable";

export default function UserDetails() {
  const location = useLocation();

  const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );
  const userContract = app.contract(
    process.env.REACT_APP_USER_CONTRACT_SHORT_ID
  );

  const agreementContract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );

  const [data, setData] = useState([]);
  const target = React.useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isApproved, setIsApproved] = useState();

  const getAssetListByUserAddress = () => {
    assetContract
      .call("getAssetListByUserAddress", [location.state.userWallet])
      .then((result) => {
        result.data.map(async (item, index) => {
          getToGrant(item[0]);
          console.log(item[0]);
          await fetch(
            "https://" + item[2] + ".ipfs.nftstorage.link/metadata.json"
          )
            .then((response) => response.json())
            .then((responseJson) => {
              responseJson.id = item[0];
              setData((prev) => [...prev, responseJson]);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const controlUserGrantsAndBalance = () => {
    agreementContract
      .call("controlUserGrantsAndBalance", [location.state.userHash, 2])
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isApprovedForAll = () => {
    assetContract
      .call("isApprovedForAll", [
        location.state.userWallet,
        process.env.REACT_APP_AGREEMENT_CONTRACT_ADDRESS,
      ])
      .then((res) => {
        setIsApproved(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setApprovalForAll = () => {
    assetContract
      .send("setApprovalForAll", {
        args: [process.env.REACT_APP_AGREEMENT_CONTRACT_ADDRESS, true],
        account: location.state.userWallet,
      })
      .then((res) => {
        Swal.fire("Success", "User granted.", "success").then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getToGrant = (id) => {
    userContract
      .send("getToGrant", {
        args: [location.state.userHash, id],
        account: location.state.userWallet,
      })
      .then((res) => {
        console.log("girdi", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    isApprovedForAll();
  }, []);

  useEffect(() => {
    getAssetListByUserAddress();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/home" aria-label="Home">
            <HomeRoundedIcon />
          </Link>

          <Link
            underline="none"
            color="neutral"
            href="/users"
            aria-label="Users"
          >
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Users
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            User Details
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">
          User Details of{" "}
          {location?.state?.firstName + " " + location?.state?.lastName}
        </Typography>
        {isApproved ? (
          <Button color="success" size="md">
            Approved
          </Button>
        ) : (
          <Button color="warning" size="md" onClick={() => setApprovalForAll()}>
            Grant User
          </Button>
        )}
      </Box>
      <Card>
        Personal information of the user.
        <Divider />
        <Grid container>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>First Name</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.firstName}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Last Name</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.lastName}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Father's Name</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.fathersName}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Mother's Name</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.mothersName}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Date of Birth</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.dateOfBirth}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Place of Birth</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.placeOfBirth}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Gender</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.gender}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Nationality</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.nationality}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Registry Place</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.registryPlace}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Number</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.number}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>IPFS Hash</FormLabel>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Link
                  level="h2"
                  fontSize="xl"
                  component={"text"}
                  underline="none"
                  color="black"
                  ref={target}
                  onClick={() => {
                    navigator.clipboard.writeText(location?.state?.files);
                    setOpenSnackbar(true);
                  }}
                >
                  <ContentCopyIcon sx={{ marginRight: 1 }} color="primary" />
                  {location?.state?.files?.slice(0, 5) +
                    "..." +
                    location?.state?.files?.slice(-5)}
                </Link>
              </Box>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>National ID</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.nationalId}
              </Typography>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Passport ID</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.passportId}
              </Typography>
            </FormControl>
          </Grid>

          <Grid xs={12} sm={12} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>User Wallet</FormLabel>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Link
                  level="h2"
                  fontSize="xl"
                  component={"text"}
                  underline="none"
                  color="black"
                  ref={target}
                  onClick={() => {
                    navigator.clipboard.writeText(location?.state?.userWallet);
                    setOpenSnackbar(true);
                  }}
                >
                  <ContentCopyIcon sx={{ marginRight: 1 }} color="primary" />
                  {location?.state?.userWallet?.slice(0, 5) +
                    "..." +
                    location?.state?.userWallet?.slice(-5)}
                </Link>
              </Box>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>User Hash</FormLabel>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Link
                  level="h2"
                  fontSize="xl"
                  component={"text"}
                  underline="none"
                  color="black"
                  ref={target}
                  onClick={() => {
                    navigator.clipboard.writeText(location?.state?.userHash);
                    setOpenSnackbar(true);
                  }}
                >
                  <ContentCopyIcon sx={{ marginRight: 1 }} color="primary" />
                  {location?.state?.userHash?.slice(0, 5) +
                    "..." +
                    location?.state?.userHash?.slice(-5)}
                </Link>
              </Box>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%", p: 1, pl: 0 }}>
              <FormLabel>Civil Registry Document ID</FormLabel>
              <Typography level="h2" fontSize="xl">
                {location?.state?.civilRegistryDocumentId}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Typography level="h4" sx={{ mt: 2 }}>
        Asset list of{" "}
        {location?.state?.firstName + " " + location?.state?.lastName}
      </Typography>
      {location ? (
        <AssetTable rows={data} user={location.state} />
      ) : (
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <CircularProgress determinate={false} size="sm" />
        </Box>
      )}

      <Snackbar
        variant="soft"
        color="success"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        Success! Copied.
      </Snackbar>
    </Box>
  );
}
