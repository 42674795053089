import { CircularProgress } from "@mui/material";
import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import bgpdf from "../../assets/bgpdf.png";
import logo3 from "../../assets/logo.png";
import robotoRegular from "../../assets/fonts/Roboto-Regular.ttf";
import robotBold from "../../assets/fonts/Roboto-Bold.ttf";

import moment from "moment";
import { Box } from "@mui/joy";

const Tubu = require("@tubu/tubuio-sdk-node");
const app = new Tubu(process.env.REACT_APP_TUBU_IO_API_KEY);
const contract = app.contract(
  process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
);

// Create styles
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: robotoRegular,
    },
    {
      src: robotBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  image: {
    width: 150,
    height: 40,
    marginLeft: 270,
    marginTop: "40px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "center",
    marginTop: "-20px",
  },
  desc: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
  },
  desc2: {
    marginTop: "20px",
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "700",
    textAlign: "justify",
  },
  title2: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: 14,
    width: 300,
  },
  title3: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontSize: 14,
    width: 700,
  },
  table: {
    fontFamily: "Roboto",

    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "20px",
  },
  tableRow: {
    fontFamily: "Roboto",

    flexDirection: "row",
  },
  tableCol: {
    fontFamily: "Roboto",

    width: "34%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    fontFamily: "Roboto",
    marginLeft: "5px",
    marginVertical: 20,
    fontSize: 10,
  },
  president: {
    fontFamily: "Roboto",
    fontSize: 14,
  },
  last: {
    fontFamily: "Roboto",
    fontSize: 9.5,
  },
  pageBackground: {
    position: "absolute",
    width: "100%",
    height: "590px",
    padding: "16px",
  },
});

// Create Document Component
const AssetPDF = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let globalUserWallet = searchParams.get("uw");
  let globalUserHash = searchParams.get("uh");

  const [data, setData] = useState();
  const [assetData, setAssetData] = useState();
  const [documentData, setDocumentData] = useState([]);

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);

  const [QRCodeString, setQRCodeString] = useState(null);
  const [QRCodeString2, setQRCodeString2] = useState(null);

  const assetContract = app.contract(
    process.env.REACT_APP_ASSET_CONTRACT_SHORT_ID
  );

  const agreementContract = app.contract(
    process.env.REACT_APP_AGREEMENT_CONTRACT_SHORT_ID
  );

  useEffect(() => {
    if (globalUserHash && globalUserWallet && id) {
      getStatus();
    }
  }, [id, globalUserHash, globalUserWallet]);

  useEffect(() => {
    if (id) {
      getAsset();
    }
  }, [id]);

  useEffect(() => {
    console.log(globalUserHash, id);
    if (globalUserHash && id) {
      setTimeout(() => {
        getDocuments();
      }, 1000);
    }
  }, [globalUserHash, id]);

  const getAsset = () => {
    assetContract.call("getAssetDetailsByAssetId", [id]).then((result) => {
      fetch(
        "https://" + result.data[0][3] + ".ipfs.nftstorage.link/metadata.json"
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson", responseJson);
          setAssetData(JSON.parse(responseJson.description)?.data);
        });
    });
  };

  const getDocuments = () => {
    agreementContract
      .call("myDocuments", [globalUserHash])
      .then((result) => {
        console.log(result);

        if (result.data.filter((item) => item[3] == id)[0]) {
          setDocumentData(result.data.filter((item) => item[3] == id)[0]);
        }
        console.log("girdik");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatus = () => {
    assetContract
      .call("getLockDetail", [id, globalUserWallet])
      .then((result) => {
        agreementContract
          .call("controlUserGrantsAndBalance", [globalUserHash, id])
          .then((res) => {
            setData(res.data.filter((item) => item[4] == "PDNAC")[0]);
            if (result.data == false && parseInt(res.data[0][2]) > 0) {
              setStatus("ACTIVE");
            } else if (result.data == true && parseInt(res.data[0][2]) > 0) {
              setStatus("ACTIVE-PENDING");
            } else {
              setStatus("PASSIVE");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((data) => {
      setQRCodeString(data);
    });
  }, []);

  useEffect(() => {
    if (documentData && documentData.length > 0) {
      console.log("imaj", documentData.length);
      fetch(
        "https://" + documentData[1][3] + ".ipfs.nftstorage.link/metadata.json"
      )
        .then((response) => response.json())
        .then((responseJson) => {
          QRCode.toDataURL(
            "https://" +
              responseJson?.image?.substring(7, 66) +
              ".ipfs.nftstorage.link/"
          ).then((data) => {
            console.log("abo", documentData);

            setQRCodeString2(data);
          });
        });
    } else {
      console.log("imaj", documentData);
      setQRCodeString2("");
    }
  }, [documentData]);

  useEffect(() => {
    console.log("bura", QRCodeString2);
  }, [QRCodeString2]);

  return (
    <>
      {data &&
      assetData &&
      QRCodeString &&
      QRCodeString2 != null &&
      !loading ? (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page
              size="A4"
              style={[
                styles.page,
                status == "ACTIVE"
                  ? { backgroundColor: "green" }
                  : status == "PASSIVE"
                  ? { backgroundColor: "red" }
                  : { backgroundColor: "#F4C430" },
              ]}
              orientation="landscape"
            >
              <Image src={bgpdf} style={styles.pageBackground} />
              <View
                style={{
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  height: "100%",
                }}
              >
                <Image src={logo3} style={styles.image} />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Text style={styles.title2}>Document Type</Text>
                  <Text style={styles.title3}>
                    : Permanent Digital Notary Asset Certificate
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Document Name</Text>
                  <Text style={styles.title3}>
                    : {assetData?.documentType?.toUpperCase()} Asset
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Asset Owner</Text>
                  <Text style={styles.title3}>
                    : {data[0][0][0]} {data[0][0][1]}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Asset Owner ID</Text>
                  <Text style={styles.title3}>: {data[0][2]}</Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>
                    {assetData?.documentType == "land"
                      ? "Land Property Number"
                      : "Car Registration Number"}
                  </Text>
                  <Text style={styles.title3}>
                    :{" "}
                    {assetData?.documentType == "land"
                      ? assetData?.propertyNumber
                      : assetData?.registrationNumber}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.title2}>Status</Text>
                  <Text
                    style={[
                      styles.title3,
                      status == "ACTIVE"
                        ? { color: "green", fontWeight: "bold" }
                        : status == "PASSIVE"
                        ? { color: "red", fontWeight: "bold" }
                        : { color: "#F4C430", fontWeight: "bold" },
                    ]}
                  >
                    : {status}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "10px",
                      marginLeft: "48px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Notary
                  </Text>
                  {QRCodeString2 && (
                    <Text
                      style={{
                        fontSize: "10px",
                        marginRight: "64px",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      IPFS
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={QRCodeString}
                    style={{
                      width: "150px",
                      height: "150px",
                      marginLeft: "-10px",
                    }}
                  />
                  {QRCodeString2 && (
                    <Image
                      src={QRCodeString2}
                      style={{
                        width: "150px",
                        height: "150px",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
export default AssetPDF;
